import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    listProducsApi,
    setProductManagement,
    resetroductManagement,
    deleteProductApi,
    resetDeleteProductApi,
    deleteAllProductApi

} from '../actions/productManagementActions'

import { makeStyles, withStyles } from '@material-ui/core/styles';
import ProductDetailComponent from '../components/ProductDetailComponent';
// MATERIAL UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Pagination from '@material-ui/lab/Pagination';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function StudentProductsScreen(props) {

    const listProductManagement = useSelector(state => state.listProductManagement);
    const { loading: loadingList, products: list, error: errorList, success: successList } = listProductManagement;


    const deleteProductManagement = useSelector(state => state.deleteProductManagement);
    const { loading: loadinDelete, product: productDelete, error: errorDelete, success: successDelete } = deleteProductManagement;


    const [tempName, setTempName] = useState("");
    const [tempId, setTempId] = useState("");


    const [columnFilter, setColumnFilter] = useState("title");
    const [isAscending, setIsAscending] = useState(true);
    const [teacherChecked, setTeacherChecked] = useState([]);



    const [pagePagination, setPagePagination] = useState(1);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [ipp, setIpp] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [operationType, setOperationType] = useState('see');
    const [productInfo, setProductInfo] = useState([]);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [allStudents, setAllStudents] = useState(false);
    const [allPartial, setAllPartial] = useState(false);


    const [firstime, setFirstime] = useState(true);


    const [openDeleteAllDialog, setOpenDeleteAllDialog] = useState(false);



    const dispatch = useDispatch();

    const useStyles = makeStyles((theme) => ({

        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
            backgroundColor: '#f5f5f5',
            minHeight: 'calc(100vh - 64px)',
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'right',
            color: theme.palette.text.secondary,
        },
        paper2: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary,
        },
        list: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,

        },
        margin: {
            margin: theme.spacing(2),

        },
        btnMargin: {
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(3),

        },
        table: {
            minWidth: 700,
        },
        iconbuton: {
            '& > *': {
                margin: theme.spacing(0),
                // padding: "5px",
            },
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        paginationCls: {
            padding: theme.spacing(1),
            textAlign: 'right',
        },
        btnHeader: {
            color: "#ffffff",
            margin: theme.spacing(1),
            // textTransform: 'none'
        },
        disabledPremium: {
            backgroundColor: '#ffcb82 !important'
        }
    }));

    const classes = useStyles();

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);



    useEffect(() => {
        return () => {
            dispatch(resetDeleteProductApi());
        };
    }, []);

    useEffect(() => {

        if (loadinDelete) {
            setShowBackdrop(true);
        } else {
            setShowBackdrop(false);
        }

        if (successDelete) {
            // slñdkflsdkflsdkfñ
            setTeacherChecked([]);
            dispatch(listProducsApi('all', "", pagePagination, ipp));
        }

        if (errorDelete) {
            setOpenDeleteDialog(false);
            alert("Sucedió un error, intenta más tarde");
        }

        return () => {

        };
    }, [deleteProductManagement]);


    useEffect(() => {

        if (list.total > 0) {
            setTotalPages(list.total_pages);
        } else {
            setTotalPages(0);
        }
        if (loadingList) {
            setShowBackdrop(true);
        } else {
            setShowBackdrop(false);
        }

        if (successList) {
            handleSetMarket();
        }

        return () => {

        };
    }, [listProductManagement]);


    // useEffect(() => {

    //     if (!firstime) {
    //         handleSetMarket();
    //     }

    //     setFirstime(false);

    //     return () => {

    //     };
    // }, [teacherChecked]);


    useEffect(() => {
        // dispatch(listProducsApi(pagePagination, ipp));
        dispatch(listProducsApi('all', "", pagePagination, ipp, columnFilter, isAscending, list.items));

        return () => {
        };
    }, [pagePagination, ipp, isAscending, columnFilter]);


    // useEffect(() => {
    //     dispatch(listProducsApi(pagePagination, ipp, columnFilter, isAscending, list.items));
    //     return () => {
    //     };
    // }, [isAscending, columnFilter]);





    const handleOpenDialog = (productId, type) => () => {
        setOperationType(type);

        if (productId) {
            let productData = list.items.find(o => o.id === productId);
            setTempName(productData.title);
            setTempId(productData.id);
            dispatch(setProductManagement(productData));
        }

        switch (type) {
            case 'new':
                // code block
                setOpenDialog(true);
                break;
            case 'see':
                // code block
                setOpenDialog(true);
                break;
            case 'edit':
                // code block
                setOpenDialog(true);
                break;
            case 'delete':
                // code block
                // setTeacherChecked([]);
                setOpenDeleteDialog(true);
                break;
            default:
            // code block
        }


    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleChange = (event) => {
        setIpp(event.target.value);
        setPagePagination(1);
    };

    const handleChangePage2 = (event, value) => {
        setPagePagination(value);
    };

    const onchangeProduct = (data, type) => {
        setOpenDialog(false);
        if (type === 1) {
            dispatch(listProducsApi('all', "", pagePagination, ipp));
        }
    }

    const handleDeleteDialog = () => {
        setOpenDeleteDialog(false);
    }


    const handleDeleteConfirmDialog = () => {
        setOpenDeleteDialog(false);
        dispatch(deleteProductApi({ id: tempId }));
    }


    const handleChangeFilter = (column) => () => {

        let chn = isAscending;

        if (columnFilter === column) {
            setIsAscending(!chn);
        } else {
            setColumnFilter(column);
        }

    };

    const handleSelectTeacher = (id) => () => {
        const currentIndex = teacherChecked.indexOf(id);
        let newChecked = [...teacherChecked]; // Se crea el nuevo array con los datos que y existen
        if (currentIndex === -1) {
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }


        // if (newChecked.length > 0) {
        //     setAllStudents(false);
        //     setAllPartial(true);
        // } else {
        //     setAllStudents(false);
        //     setAllPartial(false);
        // }

        setTeacherChecked(newChecked);

        let originalStudents = newChecked;
        let listado = [...list.items];


        let counter = 0;

        for (let idx in listado) {

            let currentIndex = originalStudents.indexOf(listado[idx].id);

            if (currentIndex === -1) {

            } else {
                counter = counter + 1;
            }

        }

        if (listado.length === counter) {
            setAllPartial(false);
            setAllStudents(true);
        } else if (counter > 0) {
            setAllPartial(true);
            setAllStudents(false);
        } else {
            setAllPartial(false);
            setAllStudents(false);
        }



    };


    const handleSelectAllStudent = () => () => {

        let tmpStatus = !allStudents;

        let originalStudents = [...teacherChecked];
        let newChecked = [...teacherChecked]; // Se crea el nuevo array con los datos que y existen
        let listado = [...list.items];

        if (tmpStatus) {
            // Agregar todos a selección
            for (let idx in listado) {
                let currentIndex = originalStudents.indexOf(listado[idx].id);
                if (currentIndex === -1) {
                    newChecked.push(listado[idx].id);
                }
            }

        } else {

            let productsToProcess = [];

            for (let idx in listado) {
                productsToProcess.push(listado[idx].id);
            }

            newChecked = newChecked.filter(function (el) {
                return productsToProcess.indexOf(el) < 0;
            });

        }

        setAllPartial(false);
        setAllStudents(tmpStatus);
        setTeacherChecked(newChecked);

    }


    const handleSetMarket = () => {


        let originalStudents = [...teacherChecked];
        let listado = [...list.items];


        let counter = 0;

        for (let idx in listado) {

            let currentIndex = originalStudents.indexOf(listado[idx].id);

            if (currentIndex === -1) {

            } else {
                counter = counter + 1;
            }

        }

        if (listado.length === counter) {
            setAllPartial(false);
            setAllStudents(true);
        } else if (counter > 0) {
            setAllPartial(true);
            setAllStudents(false);
        } else {
            setAllPartial(false);
            setAllStudents(false);
        }

        // 
        // 

        // Obtener 
    }

    const handleDeleteAllDialog = () => () => {
        setOpenDeleteAllDialog(true);
    }

    const handleCloseAllDialog = () => {
        setOpenDeleteAllDialog(false);
    }


    const handleDeleteConfirmAllDialog = () => {
        setOpenDeleteAllDialog(false);
        dispatch(deleteAllProductApi({ ids: teacherChecked }));
    }



    return (
        <>
            <div className={classes.root}>
                <h1>Gestión de Productos para la Tienda</h1>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <Paper className={classes.paper}>

                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="flex-start"
                            >

                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleDeleteAllDialog()}
                                    startIcon={<DeleteIcon />}
                                    className={classes.btnMargin}
                                    disabled={teacherChecked.length === 0}
                                >
                                    Eliminar seleccionados
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleOpenDialog(null, 'new')}
                                    startIcon={<AddIcon />}
                                    className={classes.btnMargin}
                                >
                                    Nuevo producto
                                </Button>
                                {/* <Divider /> */}


                            </Grid>


                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="right">#</StyledTableCell>
                                            <StyledTableCell align="right">
                                                <Checkbox
                                                    edge="start"
                                                    onClick={handleSelectAllStudent()}
                                                    checked={allStudents}
                                                    indeterminate={allPartial}
                                                    style={{ color: '#fff' }}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': 'parent-selector' }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Button
                                                    startIcon={columnFilter === 'title' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'title' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                    size="small"
                                                    onClick={handleChangeFilter('title')}
                                                    className={classes.btnHeader}>
                                                    Nombre del producto
                                                </Button>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Button
                                                    startIcon={columnFilter === 'category' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'category' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                    size="small"
                                                    onClick={handleChangeFilter('category')}
                                                    className={classes.btnHeader}>
                                                    Categoría
                                                </Button>
                                            </StyledTableCell>
                                            {/* <StyledTableCell>Especificaciones</StyledTableCell> */}
                                            <StyledTableCell align="right">
                                                <Button
                                                    startIcon={columnFilter === 'stock' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'stock' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                    size="small"
                                                    onClick={handleChangeFilter('stock')}
                                                    className={classes.btnHeader}>
                                                    Cantidad disponible
                                                </Button>
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <Button
                                                    startIcon={columnFilter === 'price' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'price' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                    size="small"
                                                    onClick={handleChangeFilter('price')}
                                                    className={classes.btnHeader}>
                                                    Precio unitario
                                                </Button>
                                            </StyledTableCell>
                                            <StyledTableCell align="right"></StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>

                                        {/* {loadingList ? (
                                            <div>Obteniendo productos...</div>
                                        ) : errorList ? (
                                            <div>Ocurrió un error</div>
                                        ) : (
                                                    <> */}
                                        {list.items && list.items.map(function (row, idx) {
                                            return (

                                                <StyledTableRow key={row.id} className={!row.isEnabled ? classes.disabledPremium : ""}>
                                                    <StyledTableCell align="right">{row.consec}</StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <Checkbox
                                                            onClick={handleSelectTeacher(row.id)}
                                                            edge="start"
                                                            checked={teacherChecked.indexOf(row.id) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': row.id }}
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {row.title} <b>{row.createdBy ? !row.isEnabled ? " - (Por Aprobar)" : " - (Aprobado)" : ""}</b>
                                                    </StyledTableCell>

                                                    <StyledTableCell>{row.categoryTitle}</StyledTableCell>

                                                    {/* <StyledTableCell>{row.specifications}</StyledTableCell> */}
                                                    <StyledTableCell align="right">{row.stock}</StyledTableCell>
                                                    <StyledTableCell align="right"><b>&#x20BF;</b> {parseFloat(row.price).toFixed(2)}</StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <div className={classes.iconbuton}>
                                                            <IconButton color="primary" aria-label="see" onClick={handleOpenDialog(row.id, 'see')}>
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                            <IconButton color="primary" aria-label="edit" onClick={handleOpenDialog(row.id, 'edit')}>
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton color="secondary" aria-label="delete" onClick={handleOpenDialog(row.id, 'delete')}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </div>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })}
                                        {/* </>
                                                )} */}

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid container direction="row" alignItems="center">
                                <Grid item className={classes.paginationCls}>
                                    <Pagination count={totalPages} color="primary" page={pagePagination} onChange={handleChangePage2} showFirstButton showLastButton />
                                </Grid>
                                <Grid item className={classes.paginationCls}>
                                    <span>| &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Elementos por página:</span>
                                </Grid>
                                <Grid item className={classes.paginationCls}>
                                    <FormControl>
                                        <Select
                                            labelId="demo-customized-select-label"
                                            id="demo-customized-select"
                                            value={ipp}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                            <MenuItem value={100}>100</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </Paper>
                    </Grid>
                </Grid>

            </div>
            <Backdrop className={classes.backdrop} open={showBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog fullScreen open={openDialog} onClose={handleCloseDialog} TransitionComponent={Transition}>
                <ProductDetailComponent
                    onchange={(e, t) => { onchangeProduct(e, t) }}
                    type={operationType}
                />
            </Dialog>


            <Dialog
                open={openDeleteDialog}
                onClose={handleDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Borrar producto</DialogTitle>
                <DialogContent>
                    Eliminar el producto <b>{tempName}</b><br></br>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteConfirmDialog} color="secondary" variant="contained" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>



            <Dialog
                open={openDeleteAllDialog}
                onClose={handleCloseAllDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Borrar productos</DialogTitle>
                <DialogContent>
                    Se eleminarán: <b>{teacherChecked.length}  productos</b><br></br>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAllDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteConfirmAllDialog} color="secondary" variant="contained" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
}


export default StudentProductsScreen;
