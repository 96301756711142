import {
    GET_ORDER_USER_RESET,
    GET_ORDER_USER_REQUEST,
    GET_ORDER_USER_SUCCESS,
    GET_ORDER_USER_FAIL,

    ADD_PRODUCT_COMMENT_RESET,
    ADD_PRODUCT_COMMENT_REQUEST,
    ADD_PRODUCT_COMMENT_SUCCESS,
    ADD_PRODUCT_COMMENT_FAIL,

} from "../constants/cartConstants";

import {
    GROUP_ACTIONS_URL
} from '../constants/urlConstants';

const urlBase = GROUP_ACTIONS_URL;

const resetOrdersApi = () => (dispatch) => {
    dispatch({ type: GET_ORDER_USER_RESET });
}

const getOrdersApi = () => async (dispatch, getState) => {

    dispatch({ type: GET_ORDER_USER_REQUEST });
    const { userSignin: { userInfo } } = getState();

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    await fetch(
        urlBase +
        'user/getorders'
        , {
            method: 'POST',
            body: JSON.stringify({
                id: userInfo._id
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(function (response) {
            if (response.ok) {
                ok = true;
            } else if (response.status === 400) {
                err400 = true;
            } else {
                // unknown = true;
            }
            return response.json();
        })
        .then(function (data) {
            // console.log('data = ', data);
            msg = data;
        })
        .catch(function (err) {
            console.error(err);
            // unknown = true;
        });

    if (ok) {
        dispatch({ type: GET_ORDER_USER_SUCCESS, payload: msg });
    } else if (err400) {
        dispatch({ type: GET_ORDER_USER_FAIL, payload: msg.errors });
    } else {
        alert("Ocurrió un error, intenta más tarde");
        dispatch({ type: GET_ORDER_USER_FAIL, payload: "Ocurrió un error, intenta más tarde" });
    }

}

const resetAddCommentApi = () => (dispatch) => {
    dispatch({ type: ADD_PRODUCT_COMMENT_RESET });
}

const addCommentApi = (titleCalification, commentCalification, ratingCalification, orderId, productId) => async (dispatch, getState) => {

    const { userSignin: { userInfo } } = getState();

    dispatch({ type: ADD_PRODUCT_COMMENT_REQUEST, payload: { titleCalification, commentCalification, ratingCalification, orderId, productId } });

    let ok = false;
    let msg = null;
    let err400 = false;

    await fetch(urlBase + 'user/productcomment', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userInfo.token
        },
        body: JSON.stringify({ 
            id: userInfo._id, 
            titleCalification, 
            commentCalification, 
            ratingCalification,
            orderId, 
            productId
        })
    })
        .then(function (response) {

            if (response.ok) {
                ok = true;
            } else if (response.status === 400) {
                err400 = true;
            } else {
                // unknown = true;
            }

            return response.json();
        })
        .then(function (data) {
            // console.log('data = ', data);
            msg = data;
        })
        .catch(function (err) {
            console.error(err);
            // unknown = true;
        });

    if (ok) {
        dispatch({ type: ADD_PRODUCT_COMMENT_SUCCESS, payload: msg });
    } else if (err400) {
        dispatch({ type: ADD_PRODUCT_COMMENT_FAIL, payload: msg.errors });
    } else {
        dispatch({ type: ADD_PRODUCT_COMMENT_FAIL, payload: "Ocurrió un error, intenta más tarde" });
    }

}


export {
    resetOrdersApi,
    getOrdersApi,
    addCommentApi,
    resetAddCommentApi
}