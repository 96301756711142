export const GROUP_LIST_REQUEST = 'GROUP_LIST_REQUEST';
export const GROUP_LIST_SUCCESS = 'GROUP_LIST_SUCCESS';
export const GROUP_LIST_FAIL = 'GROUP_LIST_FAIL';

export const GROUP_DETAILS_REQUEST = 'GROUP_DETAILS_REQUEST';
export const GROUP_DETAILS_SUCCESS = 'GROUP_DETAILS_SUCCESS';
export const GROUP_DETAILS_FAIL = 'GROUP_DETAILS_FAIL';

export const GROUP_SAVE_REQUEST = 'GROUP_SAVE_REQUEST';
export const GROUP_SAVE_SUCCESS = 'GROUP_SAVE_SUCCESS';
export const GROUP_SAVE_FAIL = 'GROUP_SAVE_FAIL';

export const GROUP_DELETE_REQUEST = 'GROUP_DELETE_REQUEST';
export const GROUP_DELETE_SUCCESS = 'GROUP_DELETE_SUCCESS';
export const GROUP_DELETE_FAIL = 'GROUP_DELETE_FAIL';


export const GROUP_STUDENT_UPDATE_RESET = 'GROUP_STUDENT_UPDATE_RESET';
export const GROUP_STUDENT_UPDATE_REQUEST = 'GROUP_STUDENT_UPDATE_REQUEST';
export const GROUP_STUDENT_UPDATE_SUCCESS = 'GROUP_STUDENT_UPDATE_SUCCESS';
export const GROUP_STUDENT_UPDATE_FAIL = 'GROUP_STUDENT_UPDATE_FAIL';

export const GROUP_STUDENT_SAVE_REQUEST = "GROUP_STUDENT_SAVE_REQUEST";
export const GROUP_STUDENT_SAVE_SUCCESS = "GROUP_STUDENT_SAVE_SUCCESS";
export const GROUP_STUDENT_SAVE_FAIL = "GROUP_STUDENT_SAVE_FAIL";

export const SAVE_GROUP_RESET = "SAVE_GROUP_RESET";
export const SAVE_GROUP_REQUEST = "SAVE_GROUP_REQUEST";
export const SAVE_GROUP_SUCCESS = "SAVE_GROUP_SUCCESS";
export const SAVE_GROUP_FAIL = "SAVE_GROUP_FAIL";
