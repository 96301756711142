import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import './index.css';
import App from './App';
import Maintenance from './Maintenance';
import store from './store';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { esES } from '@material-ui/core/locale';

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  esES,
); 

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <App /> */}

      <ThemeProvider theme={theme}>
        <App />
        {/* <Maintenance /> */}
      </ThemeProvider>
    </BrowserRouter>
  </Provider>, document.getElementById('root'));

