import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';


import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';

function AboutScreen(props) {


    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;

    const useStyles = makeStyles((theme) => ({
        root: {
            height: 'calc(100vh + 30px)',
            margin: '0',
            padding: '0'
        },
        image: {
            backgroundImage: 'url("/images/about_01.jpeg")',
            backgroundRepeat: 'no-repeat',
            backgroundColor:
                theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        },
        paper: {
            margin: theme.spacing(8, 4),
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        baseColor: {
            color: '#9fc33b',
        },
        marginText: {
            margin: theme.spacing(5),
        }
    }));

    const classes = useStyles();

    return (
        <>

            <Grid container component="main" className={classes.root} >
                <CssBaseline />

                <Grid item xs={12} sm={12} className={classes.image} />

            </Grid>

        </>
    );
}





export default AboutScreen;
