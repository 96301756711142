import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signin } from '../actions/userActions';

// import Cookie from 'js-cookie';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { deepOrange, green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';

import EmailValidator from 'email-validator';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import BannerSchools from '../components/BannerSchools';
import BannerPartners from '../components/BannerPartners';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {/* <Link color="inherit" href="https://material-ui.com/"> */}
      <Link color="inherit" component={LinkRouter} to={'/'}>
        School Market
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


function SigninScreen(props) {

  const [firstTime, setFirstTime] = useState(true);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const userSignin = useSelector(state => state.userSignin);
  const { loading, userInfo, error } = userSignin;
  const dispatch = useDispatch();
  const redirect = props.location.search ? props.location.search.split("=")[1] : '/';



  let textInput = useRef(null);

  useEffect(() => {

    if (userInfo) {
      props.history.push(redirect);
    }
    return () => {
      //

    };
  }, [userInfo]);



  useEffect(() => {

    if (!firstTime) {
      if (error) {
        setOpen(true);
      }
    }

    setFirstTime(false);

    return () => {
      //
    };
  }, [error]);


  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signin(email, password));
  }

  const checkEmail = (v) => {

    if (/\s/g.test(v) || v.length === 0) {
      setEmailError('Este campo no deb estar vacío');
    } else {
      setEmailError("");
    }

    // if (/\s/g.test(v) || v.length === 0 ) {
    //   setEmailError('Debe proporcionar un correo electrónico');
    // }
    // else if (!EmailValidator.validate(v)) {
    //   setEmailError("Correo electrónico no válido");
    // } else {
    //   setEmailError("");
    // }

    setEmail(v);

  }

  const checkPassword = (v) => {

    if (/\s/g.test(v) || v.length === 0) {
      setPasswordError('La contraseña es requerida');
    } else {
      setPasswordError("");
    }

    setPassword(v);

  }

  const useStyles = makeStyles((theme) => ({
    paper: {
      // marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    square: {
      // padding: theme.spacing(2),
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: "#fafafa",
      width: 80,
      height: 80,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      // paddingTop: theme.spacing(5),  
      // marginTop: theme.spacing(5),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(0),
      position: 'relative',
    },
  }));

  const classes = useStyles();


  const [open, setOpen] = useState(false);


  const handleClose = () => {
    setEmail('');
    setPassword('');
    setOpen(false);

    setTimeout(() => {
      textInput.current.focus();
    }, 100);

  };

  return (

    <div>

      {/* <BannerSchools /> */}

      <div id="cft"  >
        <img style={{ width: 'calc(100%)' }} className="bottom" src="/images/Banner2.jpg" />
        <img style={{ width: 'calc(100%)' }} className="top" src="/images/Banner1.jpg" />
      </div>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>

          <Typography component="h1" variant="h5">
            Ingresar
          </Typography>

          <form className={classes.form} noValidate onSubmit={submitHandler}>
            <TextField
              inputRef={textInput}
              value={email}
              error={emailError.length > 0}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo electrónico / alias"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => checkEmail(e.target.value)}
              disabled={loading}
              helperText={emailError && emailError}
            />
            <TextField
              value={password}
              error={passwordError.length > 0}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => checkPassword(e.target.value)}
              disabled={loading}
              helperText={passwordError && passwordError}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Recordarme"
            /> */}
            <div className={classes.wrapper}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading || !password || !email || emailError.length > 0}
              >
                Aceptar 
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
            <Grid container>
              <Grid item xs>
                <Link component={LinkRouter} to={'/recovery'} variant="body2">
                  Recuperar contraseña
                </Link>
              </Grid>
              {/* <Grid item>
                <Link component={LinkRouter} to={'/register'} variant="body2">
                  {"Solicitud de registro"}
                </Link>
              </Grid> */}
              <Button
                component={LinkRouter} to={'/'}
                fullWidth
                variant="contained"
                // color="secondary"
                className={classes.submit}
                disabled={loading}
              >
                Cancelar
                </Button>
              <Button
                component={LinkRouter} to={'/register'}
                fullWidth
                variant="contained"
                // color="secondary"
                className={classes.submit}
                disabled={loading}
              >
                Solicitud de registro
                </Button>
            </Grid>
          </form>
        </div>

      </Container>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Error!"}</DialogTitle>
        <DialogContent>
          {/* <Typography color="error" component="h2" variant="h5">
              {error}
            </Typography> */}
          <div>
            {error && error.map(function (d, idx) {
              return (
                <>
                  {d.msg}<br></br>
                </>
              )
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>


      {/* <BannerPartners /> */}
      <div id="cfb"  >
        <img style={{ width: 'calc(100%)' }} className="topb" src="/images/Banner1.jpg" />
        <img style={{ width: 'calc(100%)' }} className="bottomb" src="/images/Banner2.jpg" />

      </div>

      {/* <Box mt={8}>
        <Copyright />
      </Box> */}
    </div>
  )





}
export default SigninScreen;