export const GET_SAVINGS_CONF_RESET = 'GET_SAVINGS_CONF_RESET';
export const GET_SAVINGS_CONF_REQUEST = 'GET_SAVINGS_CONF_REQUEST';
export const GET_SAVINGS_CONF_SUCCESS = 'GET_SAVINGS_CONF_SUCCESS';
export const GET_SAVINGS_CONF_FAIL = 'GET_SAVINGS_CONF_FAIL';

export const UPDATE_SAVINGS_CONF_RESET = 'UPDATE_SAVINGS_CONF_RESET';
export const UPDATE_SAVINGS_CONF_REQUEST = 'UPDATE_SAVINGS_CONF_REQUEST';
export const UPDATE_SAVINGS_CONF_SUCCESS = 'UPDATE_SAVINGS_CONF_SUCCESS';
export const UPDATE_SAVINGS_CONF_FAIL = 'UPDATE_SAVINGS_CONF_FAIL';






