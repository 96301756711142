import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    GRID_DEFAULT_LOCALE_TEXT,
} from '../constants/gridLocaleTextApi';

import dateFormat from 'dateformat';


import {
    getInsigniasTeacherApi
} from '../actions/studentWalletBalanceActions'


import NumberFormat from 'react-number-format';


import { makeStyles, withStyles } from '@material-ui/core/styles';
import ProductDetailComponent from '../components/ProductDetailComponent';
// MATERIAL UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Pagination from '@material-ui/lab/Pagination';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DescriptionIcon from '@material-ui/icons/Description';

import { DataGrid } from '@material-ui/data-grid';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function InsigniasTeacherScreen(props) {


    const getStudentWalletBalance = useSelector(state => state.getStudentWalletBalance);
    const { loading: loadingList, data: list, error: errorList, success: successList } = getStudentWalletBalance;

    const [showBackdrop, setShowBackdrop] = useState(false);


    const [studentId, setStudentId] = useState("all");
    const [groupId, setGroupId] = useState("all");

    const [currentUser, setCurrentUser] = useState({});


    const [items, setItems] = useState([]);

    const [groups, setGroups] = useState([]);
    const [studentsName, setStudentsName] = useState([]);

    const dispatch = useDispatch();

    const useStyles = makeStyles((theme) => ({

        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
            backgroundColor: '#f5f5f5',
            minHeight: 'calc(100vh - 64px)',
        },
        paper: {
            padding: theme.spacing(2),
            // textAlign: 'right',
            color: theme.palette.text.secondary,
        },
        paper2: {
            padding: theme.spacing(2),
            // textAlign: 'left',
            color: theme.palette.text.secondary,
        },
        list: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,

        },
        margin: {
            margin: theme.spacing(2),

        },
        btnMargin: {
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(3),

        },
        table: {
            minWidth: 700,
        },
        iconbuton: {
            '& > *': {
                margin: theme.spacing(0),
            },
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        paginationCls: {
            padding: theme.spacing(1),
            textAlign: 'right',
        },
        btnHeader: {
            color: "#ffffff",
            margin: theme.spacing(1),
        },
        alignCenter: {
            textAlign: 'center',
        },
        rightAlng: {
            textAlign: 'left',
        },
        leftSpac: {
            paddingLeft: theme.spacing(2),
        }
    }));

    const classes = useStyles();

    // const StyledTableCell = withStyles((theme) => ({
    //     head: {
    //         backgroundColor: theme.palette.common.black,
    //         color: theme.palette.common.white,
    //     },
    //     body: {
    //         fontSize: 14,
    //     },
    // }))(TableCell);

    // const StyledTableRow = withStyles((theme) => ({
    //     root: {
    //         '&:nth-of-type(odd)': {
    //             backgroundColor: theme.palette.action.hover,
    //         },
    //     },
    // }))(TableRow);

    useEffect(() => {
        // Traer grupos
        dispatch(getInsigniasTeacherApi());
        return () => {
            // dispatch(resetDeleteProductApi());
            // setItems([]);
            // setGroups([]);
            // setStudentsName([]);
        };
    }, []);


    useEffect(() => {
        // Traer grupos

        // const { loading: loadingList, data: list, error: errorList, success: successList } = ;

        if (successList) {
            setItems(list.items);
            setGroups(list.groups);
            setStudentsName(list.studentsName);
        }

        return () => {
            // dispatch(resetDeleteProductApi());
        };
    }, [getStudentWalletBalance]);


    const obtenerFechaConvertida = (fecha) => {
        let utcSeconds = fecha;
        let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds);

        return dateFormat(d, 'd"/"mm"/"yyyy  " "h:MM:ss TT');

        // 'dd"/"mm"/"yyyy'
    }

    const obtenerFechaConvertida2 = (fecha) => {
        console.log("fecha");
        console.log(fecha);
        return "nueva fecha";

        // 'dd"/"mm"/"yyyy'
    }

    const columns = [
        { field: 'teacherName', headerName: 'Profesor', width: 300 },
        { field: 'currentGroup', headerName: 'Grupo', width: 200 },
        { field: 'name', headerName: 'Nombre Alumno', width: 300 },
        { field: 'nickname', headerName: 'Alias alumno', width: 160 },
        { field: 'totalInsignias', headerName: '# Insignias', type: 'number', width: 130 },
        { field: 'reason', headerName: 'Justificación', width: 220 },
        {
            field: 'date',
            headerName: 'Fecha asignación',
            width: 200,
            // sortable: false,
            // filter: false,
            filterable: false,
            valueGetter: (params) =>
                `${obtenerFechaConvertida(params.value)}`,
        },
        // {
        //     field: 'age',
        //     headerName: 'Age',
        //     type: 'number',
        //     // width: 90,
        // },
        // {
        //     field: 'fullName',
        //     headerName: 'Full name',
        //     description: 'Esta columna tiene un valor compuesto y no se puede ordenar',
        //     sortable: false,
        //     // width: 160,
        //     valueGetter: (params) =>
        //         `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
        // },
    ];

    function defaultLabelDisplayedRows({ from, to, count }) { return `${from}-${to} de ${count !== -1 ? count : ` mas que ${to}`}`; }



    const studentHandleChange = (event) => {
        let stdentId = event.target.value;
        setStudentId(stdentId);
        applyCustomFilter(stdentId, groupId);
    };

    const groupHandleChange = (event) => {
        let gropId = event.target.value;
        setGroupId(gropId);
        applyCustomFilter(studentId, gropId);
    };

    const applyCustomFilter = (sId, gId) => {

        let tmpItems = [];
        let allItems = list.items;

        // Reset
        if (sId === "all" && gId === "all") {
            tmpItems = allItems;
        }
        else if (sId !== "all" && gId !== "all") {
            // Existe filtro en ambos
            // Filtramos Alumnos
            tmpItems = allItems.filter(function (item) {
                return item.currentGroupId === gId && item.idCtrl === sId;
            });
        } else if (sId === "all" && gId !== "all") {
            // Grupo tiene la selección
            tmpItems = allItems.filter(function (item) {
                return item.currentGroupId === gId;
            });
        } else {
            // Alumno tiene la selección
            tmpItems = allItems.filter(function (item) {
                return item.idCtrl === sId;
            });
        }

        setItems(tmpItems);
    }

    const selectedRow = async (newSelection) => {
        let id = newSelection.id;

        alert(id);
    };

    const handleRowSelection = (e) => {

        setCurrentUser(e.data);

    };


    return (
        <>
            <div className={classes.root}>

                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <h1>Listado de alumnos con insignia de desempeño</h1>
                </Grid>


                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <Paper className={classes.paper}>

                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                            >

                                <FormControl size="small" className={classes.formControl}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select-students"
                                        variant="outlined"
                                        value={studentId}
                                        onChange={studentHandleChange}
                                    >
                                        <MenuItem value="all">Todos los alumnos</MenuItem>

                                        {studentsName && studentsName.map((value, index) => {
                                            return (
                                                <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                            );
                                        })}

                                    </Select>

                                </FormControl>


                                <FormControl size="small" className={classes.leftSpac}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select-groups"
                                        variant="outlined"
                                        value={groupId}
                                        onChange={groupHandleChange}
                                    >
                                        <MenuItem value="all">Todos los grupos</MenuItem>

                                        {groups && groups.map((value, index) => {
                                            return (
                                                <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                            );
                                        })}

                                    </Select>

                                </FormControl>

                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                            >


                            </Grid>

                            <br></br>

                            <div style={{ height: 650, width: '100%' }}>
                                <DataGrid
                                    // className={classes.table}
                                    // size="small"
                                    // aria-label="customized table"
                                    rows={items}
                                    // onSelectionChange={selectedRow}
                                    // checkboxSelection={true}
                                    onRowSelected={handleRowSelection}
                                    // localeText={GRID_DEFAULT_LOCALE_TEXT}
                                    localeText={{
                                        footerRowSelected: (count) => [
                                            <>
                                                <div>
                                                    Total de Insignias: <b>{currentUser.totalInsignias}</b><br />
                                                    Faltan <b>{currentUser.balanceAchievements}</b> BitCoins para nueva insignia.
                                                </div>
                                            </>
                                        ],
                                        // footerRowSelected: (count) => `ID Selecionado: ${studentId} <br> ddd`,
                                        ...GRID_DEFAULT_LOCALE_TEXT
                                    }}
                                    columns={columns}
                                    loading={loadingList}
                                    // rowsPerPageOptions={[25, 50, 100]}
                                    // paginationMode="client"
                                    // labelDisplayedRows={defaultLabelDisplayedRows}
                                    pageSize={100} />
                            </div>

                        </Paper>
                    </Grid>
                </Grid>

            </div>
            <Backdrop className={classes.backdrop} open={loadingList}>
                {/* <CircularProgress color="inherit" /> */}
            </Backdrop>

        </>
    );
}


export default InsigniasTeacherScreen;
