import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AutoComplete } from 'antd';

import { Carousel } from 'react-responsive-carousel';
import { addToCart, removeFromCart, buyProductReset } from '../actions/cartActions';

import {
    listStoreProducsApi,
    resetlistProducsApi
} from '../actions/productManagementActions'

import {
    IMAGES_URL
} from '../constants/urlConstants';

import Pagination from '@material-ui/lab/Pagination';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ClearIcon from '@material-ui/icons/Clear';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import Divider from '@material-ui/core/Divider';

import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge);


const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
        // margin: theme.spacing(2),

    },
    input: {
        borderRadius: 0,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 0,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon />,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon />,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon />,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon />,
        label: 'Very Satisfied',
    },
};


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}


function StoreScreen(props) {

    const WAIT_INTERVAL = 1000;
    let timerID;

    const imageUrl = IMAGES_URL;

    const [state] = useState({
        vertical: 'bottom',
        horizontal: 'center',
    });

    const { vertical, horizontal } = state;

    const cart = useSelector(state => state.cart);

    const { cartItems } = cart;

    const listProductManagement = useSelector(state => state.listProductManagement);
    const { loading: loadingList, products: list, error: errorList, success: successList } = listProductManagement;


    const buyProductDone = useSelector(state => state.buyProductDone);
    const { success: successDone } = buyProductDone;


    const [pagePagination, setPagePagination] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [showBackdrop, setShowBackdrop] = useState(false);

    const [paginationLegend, setPaginationLegend] = useState("0 resultados");
    const [openDialog, setOpenDialog] = useState(false);

    const [product, setProduct] = useState([]);
    const [cantSelect, setCantSelect] = useState('1');

    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [showProductDone, setShowProductDone] = useState(false);


    const [productSearch, setProductSearch] = useState('');
    const [categoryId, setCategoryId] = useState("all");


    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetlistProducsApi());
            dispatch(buyProductReset());
        };
    }, []);

    useEffect(() => {
        if (list.total > 0) {
            setTotalPages(list.total_pages);
        } else {
            setTotalPages(0);
        }
        if (loadingList) {
            setShowBackdrop(true);
        } else {
            setShowBackdrop(false);
        }

        let start = 0;
        let end = 0;


        let page = roughScale(list.page, 10);
        let total_pages = roughScale(list.total_pages, 10);
        let per_page = roughScale(list.per_page, 10);
        let total = roughScale(list.total, 10);


        if (page === total_pages) {
            start = ((page - 1) * (per_page)) + 1;
            end = total;
        } else {
            start = ((page - 1) * (per_page)) + 1;
            
            end = list.page * list.per_page;


        }

        setPaginationLegend(`Mostrando ${start}-${end} de ${total} productos`);



        return () => {

        };
    }, [listProductManagement]);

    useEffect(() => {
        // dispatch(listProducsApi(pagePagination, ipp));
        dispatch(listStoreProducsApi(categoryId, productSearch, pagePagination));
        // dispatch(listProducsApi(pagePagination));
        return () => {
        };
    }, [pagePagination]);

    useEffect(() => {
        if (successDone) {
            setShowProductDone(true);
        }
        return () => {
        };
    }, [buyProductDone]);

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            // backgroundColor: '#fff'
            // padding: theme.spacing(2),
            // backgroundColor: '#f5f5f5',
            minHeight: 'calc(100vh - 64px)',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '10px',
                paddingRight: '10px',
            },
            [theme.breakpoints.up('md')]: {
                paddingLeft: '6%',
                paddingRight: '6%',
            },
        },
        paper: {
            padding: theme.spacing(0),
            marginBottom: theme.spacing(5),
            // padding: '0',
            // paddingBottom: '30',
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        parent: {
            position: "relative",
            // width: 200,
            height: '200',
        },
        backdrop: {
            position: "absolute",
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: 'red',
            color: '#000',

        },
        btnAlign: {
            justifyContent: 'center'
        },
        cardConf: {

        },
        alignCartBtn: {
            textAlign: 'right',
        },
        cartBtn: {
            '& > *': {
                margin: theme.spacing(0),
            },
        },
        appBar: {
            position: 'relative',
            backgroundColor: '#000',
            color: '#9fc33b',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        modalRoot: {
            flexGrow: 1,
            padding: 20,
            // marginTop: 50,
            paddingTop: 30,
            backgroundColor: '#fff',
        },
        marginSelect: {
            marginRight: theme.spacing(1),
        },
        formControl: {
            minWidth: 160,
        },
        rootSearch: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 400,
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
        maginNotFound: {
            marginTop: theme.spacing(5),
        },
        alignSameLine: {
            display: 'flex',
            // alignItems: 'center',
        },
    }));

    const handleChangePage2 = (event, value) => {
        setPagePagination(value);
    };

    const classes = useStyles();


    const roughScale = (x, base) => {
        let parsed = Number.parseInt(x, base);
        if (Number.isNaN(parsed)) {
            return 0;
        }
        return parsed;
    }


    const handleCloseDialog = () => {
        setOpenDialog(false);
        setProduct([]);
        setCantSelect('1');
    };


    const handleCloseShowProductDone = () => {
        setShowProductDone(false);
    };




    const handleClickOpen = (productView) => () => {
        console.log(productView);
        setProduct(productView);
        setOpenDialog(true);
    };

    const handleChangeSelect = (event) => {
        setCantSelect(event.target.value);
    };


    const handleAddCart = (product) => () => {
        // setProduct(productView);
        // setOpenDialog(true);

        dispatch(addToCart(product, cantSelect));
        setOpenSnackbar(true);
        setOpenDialog(false);

    };

    // Cerrar el snackbar
    const CloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };


    const handleTextFieldChange = (e) => {
        let value = e.target.value;
        setProductSearch(value);

        // console.log("timerID PRINCIPO DE FIN____"); 
        // console.log(timerID); 

        // clearTimeout(timerID);

        // console.log("timerID INICIO"); 
        // console.log(timerID); 


        // timerID = setTimeout(() => {
        //     dispatch(listProducsApi(value, pagePagination));
        // }, WAIT_INTERVAL);

        // console.log("timerID FIN"); 
        // console.log(timerID); 


    };

    const handleDoSearchText = (e) => {
        e.preventDefault();
        dispatch(listStoreProducsApi(categoryId, productSearch, pagePagination));
    };


    const deleteTerm = () => {
        setProductSearch("");
        dispatch(listStoreProducsApi(categoryId, "", pagePagination));
    }

    const handleChange = (event) => {
        let cat = event.target.value;
        setCategoryId(cat);
        dispatch(listStoreProducsApi(cat, "", pagePagination));
    };

    // const obtenerFechaConvertida = (fecha) => {
    //     let utcSeconds = fecha;
    //     let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    //     d.setUTCSeconds(utcSeconds);
    //     return dateFormat(d, 'd"/"mm"/"yyyy  " "h:MM:ss TT');
    // }

    const labels = {
        0.5: 'Useless',
        1: 'Malo',
        1.5: 'Poor',
        2: 'Regular',
        2.5: 'Ok',
        3: 'Bien',
        3.5: 'Good',
        4: 'Muy bien',
        4.5: 'Excellent',
        5: 'Excelente!',
    };


    return (
        <>
            <div className={classes.root}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <h1>Tienda</h1>
                    <IconButton aria-label="cart" component={Link} to={'/cart'}>
                        <StyledBadge badgeContent={cartItems.length} color="primary">
                            <ShoppingCartIcon fontSize="large" />
                        </StyledBadge>
                    </IconButton>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <FormControl size="small" className={classes.formControl}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            variant="outlined"
                            value={categoryId}
                            onChange={handleChange}
                        >
                            <MenuItem value="all">Todos</MenuItem>

                            {list.categories && list.categories.map((value, index) => {
                                return (
                                    <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                );
                            })}

                        </Select>

                    </FormControl>


                    <Paper component="form" onSubmit={handleDoSearchText} className={classes.rootSearch}>
                        <InputBase
                            className={classes.input}
                            value={productSearch}
                            onChange={handleTextFieldChange}
                            placeholder="Buscar producto"
                            inputProps={{ 'aria-label': 'buscar producto' }}
                        />
                        <IconButton type="submit" color="primary" className={classes.iconButton} aria-label="buscar">
                            <SearchIcon />
                        </IconButton>
                        <Divider className={classes.divider} orientation="vertical" />
                        <IconButton color="secondary" disabled={productSearch.length == 0} onClick={deleteTerm} className={classes.iconButton} aria-label="directions">
                            <ClearIcon />
                        </IconButton>
                    </Paper>

                </Grid>
                <br></br>


                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-start"
                >

                    {list.items && list.items.length > 0 ? (
                        <b>{paginationLegend}</b>
                    ) : (
                            <></>
                        )}

                </Grid>

                <div className={classes.parent}>
                    <Backdrop className={classes.backdrop} open={showBackdrop}>
                        <CircularProgress />
                    </Backdrop>
                </div>

                <Grid container spacing={2}>
                    {list.items && list.items.map(function (row, idx) {
                        let imgTmp = row.images.length > 0 ? imageUrl + row.images[0].url : "https://aeasa.com.mx/wp-content/uploads/2020/02/SIN-IMAGEN.jpg";
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
                                <Paper className={classes.paper} square={true} elevation={0}>
                                    <Card className={classes.cardConf} square={true} elevation={0}>
                                        <CardActionArea onClick={handleClickOpen(row)}>
                                            <CardMedia
                                                component="img"
                                                alt={row.description}
                                                height="200"
                                                image={imgTmp}
                                                loading="lazy"
                                                title={row.description}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="subtitle1" component="h6">
                                                    {row.title}
                                                </Typography>
                                                <Typography variant="h6" component="h6">
                                                    <b>&#x20BF; {parseFloat(row.price).toFixed(2)}</b>
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions className={classes.btnAlign}>
                                            {row && row.stock > 0 ? (
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    color="primary"
                                                    fullWidth
                                                    onClick={handleAddCart(row)}
                                                    style={{
                                                        borderRadius: 0,
                                                    }}
                                                >
                                                    Agregar al carrito
                                                </Button>
                                            ) : (
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        disabled
                                                        color="primary"
                                                        fullWidth
                                                        style={{
                                                            borderRadius: 0,
                                                        }}
                                                    >
                                                        Agotado
                                                    </Button>
                                                )}

                                        </CardActions>
                                    </Card>
                                </Paper>
                            </Grid>
                        )
                    })}


                    <Grid direction="row"
                        justify="center"
                        alignItems="flex-start"
                        container
                        className={classes.red}
                    >
                        {list.items && list.items.length > 0 ? (
                            <Pagination
                                count={10}
                                variant="outlined"
                                shape="rounded"
                                count={totalPages}
                                page={pagePagination}
                                onChange={handleChangePage2}
                            />
                        ) : (
                                <></>
                            )}
                    </Grid>



                    {list.items && list.items.length == 0 ? (
                        <Grid direction="row"
                            justify="center"
                            alignItems="flex-start"
                            container
                            className={classes.maginNotFound}
                        >
                            <h2>0 Productos encontrados</h2>
                        </Grid>
                    ) : (
                            <></>
                        )}


                    <br></br>
                    <br></br>




                </Grid>


                <div id="cfb"  >
                    <img style={{ width: 'calc(100%)' }} className="topb" src="/images/Banner1.jpg" />
                    <img style={{ width: 'calc(100%)' }} className="bottomb" src="/images/Banner2.jpg" />
                </div>
            </div>



            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={CloseSnackbar}
                message="Se han realizado los cambios solicitados"
                key={vertical + horizontal}>
                <Alert onClose={CloseSnackbar} severity="success">
                    Producto agregado al carrito
                </Alert>
            </Snackbar>

            <Dialog fullScreen open={openDialog} onClose={handleCloseDialog} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Detalles del producto
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleCloseDialog}>
                            Cerrar
                        </Button>
                    </Toolbar>
                </AppBar>

                <div className={classes.modalRoot}>
                    <Grid direction="row"
                        justify="center"
                        alignItems="flex-start"
                        container
                    >
                        <Grid item xs={12} sm={12} md={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Carousel showArrows={true} >
                                        {product.images && product.images.map(function (row, idx) {
                                            let img = imageUrl + row.url;
                                            return (
                                                <div key={idx}>
                                                    <img src={img} />
                                                </div>
                                            )
                                        })}
                                    </Carousel>
                                </Grid>

                                <Grid item xs={12} sm={6} >
                                    <br></br>
                                    <br></br>
                                    <Typography variant="h6" component="h6">
                                        {product.title}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="h6" component="h6">
                                        <b>&#x20BF; {parseFloat(product.price).toFixed(2)}</b>
                                    </Typography>
                                    <br></br>


                                    {product.rating && product.rating > 0 && product.rating <= 5 ? (
                                        <Rating
                                            name="simple-controlled"
                                            defaultValue={product.rating}
                                            readOnly
                                            getLabelText={(value) => customIcons[value].label}
                                            IconContainerComponent={IconContainer}
                                        />
                                    ) : (
                                            <Rating
                                                name="simple-controlled"
                                                defaultValue={product.rating}
                                                readOnly
                                                IconContainerComponent={IconContainer}
                                            />
                                        )}


                                    <br></br>
                                    <br></br>
                                    <Typography variant="subtitle1" component="h3">
                                        {product.description}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="subtitle1" component="h3">
                                        {product.specifications}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="subtitle1" component="h3">

                                        {product.stock > 0 ? `(${product.stock} disponibles)` : 'NO DISPONIBLE'}

                                    </Typography>
                                    <br></br>

                                    {product && product.stock > 0 && (
                                        <>
                                            <Grid
                                                container
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="center"
                                            >
                                                <FormControl className={classes.marginSelect}>
                                                    <NativeSelect
                                                        id="demo-customized-select-native"
                                                        value={cantSelect}
                                                        onChange={handleChangeSelect}
                                                        input={<BootstrapInput />}
                                                    >
                                                        <option value={1}>1</option>
                                                        <option value={2}>2</option>
                                                        <option value={3}>3</option>
                                                        <option value={4}>4</option>
                                                        <option value={5}>5</option>
                                                        <option value={6}>6</option>
                                                        <option value={7}>7</option>
                                                        <option value={8}>8</option>
                                                        <option value={9}>9</option>
                                                        <option value={10}>10</option>
                                                    </NativeSelect>
                                                </FormControl>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleAddCart(product)}
                                                    style={{
                                                        borderRadius: 0,
                                                    }}
                                                >
                                                    Agregar al carrito
                                            </Button>
                                            </Grid>
                                        </>
                                    )}

                                </Grid>

                                <Grid item xs={12}>
                                    {product.reviews && product.reviews.length > 0 ? (
                                        <h2>Calificaciones del producto</h2>
                                    ) : (
                                            <h2>El producto aún no tiene calificaciones</h2>
                                        )}
                                </Grid>

                                <Grid item xs={12}>
                                    {product.reviews && product.reviews.map(function (row, idx) {
                                        return (
                                            <div key={idx}>
                                                <Rating
                                                    name={'customized-icons-set-view' + idx}
                                                    defaultValue={row.rating}
                                                    readOnly
                                                    getLabelText={(value) => customIcons[value].label}
                                                    IconContainerComponent={IconContainer}
                                                />
                                                <br></br>
                                                <b>"{row.titleComment}" </b>{row.comment}
                                                <br></br>
                                                <br></br>
                                            </div>
                                        )
                                    })}


                                </Grid>



                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>

            <Dialog
                open={showProductDone}
                onClose={handleCloseShowProductDone}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Compra exitosa</DialogTitle>
                <DialogContent>
                    <h3>Tu saldo ya fue actualizado, también puedes ver tus compras.</h3>
                    <br></br>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={handleCloseShowProductDone}
                    >
                        Cerrar aviso
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        component={Link} to={'/orders'}
                        style={{
                            borderRadius: 0,
                        }}
                    >
                        ver Mis compras
                    </Button>

                </DialogActions>
            </Dialog>


        </>
    );
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default StoreScreen;
