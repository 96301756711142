import {
    GET_TEACHER_GROUP_LIST_RESET,
    GET_TEACHER_GROUP_LIST_REQUEST,
    GET_TEACHER_GROUP_LIST_SUCCESS,
    GET_TEACHER_GROUP_LIST_FAIL,
    UPDATE_TEACHER_GROUP_LIST_RESET,
    UPDATE_TEACHER_GROUP_LIST_REQUEST,
    UPDATE_TEACHER_GROUP_LIST_SUCCESS,
    UPDATE_TEACHER_GROUP_LIST_FAIL
} from '../constants/teacherGroupConstants';

import {
    GROUP_ACTIONS_URL
} from '../constants/urlConstants';

const urlBase = GROUP_ACTIONS_URL;

const getTeacherGroupListApi = (
    category = '',
    searchKeyword = '',
    sortOrder = ''
) => async (dispatch, getState) => {

    dispatch({ type: GET_TEACHER_GROUP_LIST_REQUEST });
    const { userSignin: { userInfo } } = getState();

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    await fetch(
        urlBase +
        'user/teachergroup?category=' +
        category +
        '&searchKeyword=' +
        searchKeyword +
        '&sortOrder=' +
        sortOrder
        , {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(function (response) {
            if (response.ok) {
                ok = true;
            } else if (response.status === 400) {
                err400 = true;
            } else {
                // unknown = true;
            }
            return response.json();
        })
        .then(function (data) {
            // console.log('data = ', data);
            msg = data;
        })
        .catch(function (err) {
            console.error(err);
            // unknown = true;
        });

    if (ok) {
        dispatch({ type: GET_TEACHER_GROUP_LIST_SUCCESS, payload: msg });
    } else if (err400) {
        dispatch({ type: GET_TEACHER_GROUP_LIST_FAIL, payload: msg.errors });
    } else {
        dispatch({ type: GET_TEACHER_GROUP_LIST_FAIL, payload: "Ocurrió un error, intenta más tarde" });
    }

};

const resetTeacherGroupListApi = () => (dispatch) => {
    dispatch({ type: GET_TEACHER_GROUP_LIST_RESET });
}

const updateTeacherGroupListApi = (teachers, groups) => async (dispatch, getState) => {

    dispatch({ type: UPDATE_TEACHER_GROUP_LIST_REQUEST });
    const { userSignin: { userInfo } } = getState();

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    await fetch(
        urlBase +
        'user/teachergroup'
        , {
            method: 'PUT',
            body: JSON.stringify({ teachers, groups }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(function (response) {
            if (response.ok) {
                ok = true;
            } else if (response.status === 400) {
                err400 = true;
            } else {
                // unknown = true;
            }
            return response.json();
        })
        .then(function (data) {
            // console.log('data = ', data);
            msg = data;
        })
        .catch(function (err) {
            console.error(err);
            // unknown = true;
        });

    if (ok) {
        dispatch({ type: UPDATE_TEACHER_GROUP_LIST_SUCCESS, payload: msg });
    } else if (err400) {
        alert("Ocurrió un error, intenta más tarde"); 
        dispatch({ type: UPDATE_TEACHER_GROUP_LIST_FAIL, payload: msg.errors });
    } else {
        alert("Ocurrió un error, intenta más tarde"); 
        dispatch({ type: UPDATE_TEACHER_GROUP_LIST_FAIL, payload: "Ocurrió un error, intenta más tarde" });
    }

}


const resetTeacherGroupUpdateApi = () => (dispatch) => {
    dispatch({ type: UPDATE_TEACHER_GROUP_LIST_RESET });
}


export {
    getTeacherGroupListApi,
    resetTeacherGroupListApi,
    updateTeacherGroupListApi,
    resetTeacherGroupUpdateApi
};
