import Axios from "axios";
// import http from "./http.service";
import Cookie from 'js-cookie';
import {
  USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_REGISTER_RESET,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOGOUT,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,

  USER_RECOVERY_RESET,
  USER_RECOVERY_REQUEST,
  USER_RECOVERY_SUCCESS,
  USER_RECOVERY_FAIL,

} from "../constants/userConstants";

import {
  AUTH_URL
} from '../constants/urlConstants';

const urlBase = AUTH_URL;

const update = ({ userId, name, email, password }) => async (dispatch, getState) => {
  const { userSignin: { userInfo } } = getState();
  dispatch({ type: USER_UPDATE_REQUEST, payload: { userId, name, email, password } });
  try {
    const { data } = await Axios.put("http://localhost:5001/simulador-qa/us-central1/api/users/" + userId,
      { name, email, password }, {
      headers: {
        Authorization: 'Bearer ' + userInfo.token
      }
    });
    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
    Cookie.set('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({ type: USER_UPDATE_FAIL, payload: error.message });
  }
}

const signin = (email, password) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });

  // ::::. PARTE 3 :::::.
  let ok = false;
  let msg = null;
  let err400 = false;

  await fetch(urlBase + 'auth/token', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, password })
  })
    .then(function (response) {

      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        // unknown = true;
      }

      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

  if (ok) {
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: msg });
    Cookie.set('userInfo', JSON.stringify(msg));
  } else if (err400) {

    let msgerr = "";

    msg.errors.forEach(
      function myFunction(item, index) {

        msgerr += item.msg + ' \n ';

      });

    dispatch({ type: USER_SIGNIN_FAIL, payload: msg.errors });
  } else {
    dispatch({ type: USER_SIGNIN_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

}

const register = (name, email, nickname, password, isCompany, userPlan, rePassword) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_REQUEST, payload: { name, email, nickname, password, userPlan, rePassword } });

  let ok = false;
  let msg = null;
  let err400 = false;
  let unknown = false;

  await fetch(urlBase + 'auth/signup', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ name, email, nickname, password, isCompany, userPlan, rePassword })
  })
    .then(function (response) {

      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        unknown = true;
      }

      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      unknown = true;
    });

  if (ok) {

    dispatch({ type: USER_REGISTER_SUCCESS, payload: msg });
    if (isCompany && msg.email != "") {
      sendEmail(msg);
    }

  } else if (err400) {

    // console.log("msg");
    // console.log(msg.errors);

    let msgerr = "";
    msg.errors.forEach(
      function myFunction(item, index) {

        msgerr += "- " + item.msg + '\n';

      });

    dispatch({ type: USER_REGISTER_FAIL, payload: msg.errors });
  } else {
    dispatch({ type: USER_REGISTER_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

}

const logout = () => (dispatch) => {
  Cookie.remove("userInfo");
  dispatch({ type: USER_LOGOUT })
}

const registerReset = () => (dispatch) => {
  dispatch({ type: USER_REGISTER_RESET });
}

function sendEmail(data) {
  // console.log("user");
  // console.log(data);

  fetch(
    'https://mailingapp.azurewebsites.net/simulador/comments/notification'
    , {
      method: 'POST',
      body: JSON.stringify({
        name: data.name,
        email: data.email,
        nickname: data.nickname,
        send: data.send
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      // console.log('email = ', data);
    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

}

const recoveryResetApi = () => (dispatch) => {
  dispatch({ type: USER_RECOVERY_RESET });
}

const recoveryApi = (email) => async (dispatch) => {
  dispatch({ type: USER_RECOVERY_REQUEST, payload: { email } });

  let ok = false;
  let msg = null;
  let err400 = false;

  await fetch(urlBase + 'auth/recovery', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email })
  })
    .then(function (response) {

      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        // unknown = true;
      }

      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

  if (ok) {
    dispatch({ type: USER_RECOVERY_SUCCESS, payload: msg });
  } else if (err400) {

    let msgerr = "";
    msg.errors.forEach(
      function myFunction(item, index) {
        msgerr += item.msg + ' \n ';
      });
    dispatch({ type: USER_RECOVERY_FAIL, payload: msg.errors });
  } else {
    dispatch({ type: USER_RECOVERY_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

}


export {
  signin,
  register,
  logout,
  update,
  registerReset,
  recoveryResetApi,
  recoveryApi
};