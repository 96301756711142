import {
  SAVE_STUDENT_RESET,
  SAVE_STUDENT_REQUEST,
  SAVE_STUDENT_SUCCESS,
  SAVE_STUDENT_FAIL,
  DELETE_STUDENT_RESET,
  DELETE_STUDENT_REQUEST,
  DELETE_STUDENT_SUCCESS,
  DELETE_STUDENT_FAIL,
} from '../constants/studentConstants';

function studentSaveReducer(state = { student: {} }, action) {
  switch (action.type) {
    case SAVE_STUDENT_RESET:
      return { loading: false, success: false };
    case SAVE_STUDENT_REQUEST:
      return { loading: true };
    case SAVE_STUDENT_SUCCESS:
      return { loading: false, success: true, student: action.payload };
    case SAVE_STUDENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function studentDeleteReducer(state = { student: {} }, action) {
  switch (action.type) {
    case DELETE_STUDENT_REQUEST:
      return { loading: true };
    case DELETE_STUDENT_SUCCESS:
      return { loading: false, student: action.payload, success: true };
    case DELETE_STUDENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export {
  studentSaveReducer,
  studentDeleteReducer,
};
