import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AddStudentComponent from '../components/AddStudentComponent';
import AddGroupComponent from '../components/AddGroupComponent';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiAlert from '@material-ui/lab/Alert';

import {
    listGroups,
    updateStudentGroup,
    resetGroupStudenUpdate
} from '../actions/groupActions';

function StudentsGroupScreen(props) {

    const [state] = useState({
        vertical: 'bottom',
        horizontal: 'center',
    });

    const { vertical, horizontal } = state;

    // Estudiante
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [nickname, setNickname] = useState('');
    const [email, setEmail] = useState('');
    const [parentName, setParentName] = useState('');
    const [parentEmail, setParentEmail] = useState('');
    const [grade, setGrade] = useState('');

    // Grupo

    const [groupId, setGroupId] = useState('');
    const [groupName, setGroupName] = useState('');
    const [groupDescription, setGroupDescription] = useState('');
    const [openGroupModal, setOpenGroupModal] = useState(false);
    const [typeGroupOperation, setTypeGroupOperation] = useState(0);




    const [checked, setChecked] = useState([0]);
    const [checkedGroup, setCheckedGroup] = useState([0]);
    const [disabledSelect, setDisabledSelect] = useState(true);
    const [openFormModal, setOpenFormModal] = useState(false);

    const [doUserChange, setDoUserChange] = useState(false);


    const [showBackdrop, setShowBackdrop] = useState(false);
    const [type, setType] = useState(0);


    const [updateMode, setUpdateMode] = useState(false);
    const groupList = useSelector(state => state.groupList);
    const { loading, groups, error: errorGroups } = groupList;

    // ESTE ES EL BUENO
    const groupStudentUpdateList = useSelector(state => state.groupStudentUpdateList);
    const { loading: loadinStudentUpdateList, error: errorGroupStudentUpdateList, completed } = groupStudentUpdateList;

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const dispatch = useDispatch();

    const handleUpdateGroup = (e) => {
        if (doUserChange) {
            e.preventDefault();
            dispatch(updateStudentGroup({ checked, checkedGroup }))
        }
    }

    useEffect(() => {
        dispatch(listGroups());
        return () => {
            //
            dispatch(resetGroupStudenUpdate());
        };
    }, []);

    useEffect(() => {
        if (completed) {
            setChecked([0]);
            setCheckedGroup([0]);
            dispatch(listGroups());
            setOpenSnackbar(true);
            setDisabledSelect(true);
        }
        return () => {
            //
        };
    }, [groupStudentUpdateList]);

    // Configuración de estilos
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
            backgroundColor: '#f5f5f5',
            minHeight: 'calc(100vh - 64px)',
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'right',
            color: theme.palette.text.secondary,
        },
        list: {
            width: '100%',
            // maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 400,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        noSelected: {
            color: 'red',
        },
        selected: {
            color: 'green'
        },
        margin: {
            margin: theme.spacing(2),
        },
        cancel: {
            margin: theme.spacing(2),
            textAlign: 'left',
        },
        rightAlng: {
            textAlign: 'left',
        }
    }));

    const classes = useStyles();

    const handleToggle = (id) => () => {

        setDoUserChange(false);

        const currentIndex = checked.indexOf(id);
        let newChecked = [...checked]; // Se crea el nuevo array con los datos que y existen

        // 1. Identificar el tipo de elemento: SÍ Tiene grupo vs NO tiene grupo
        let haveGroup = elementHaveGroup(id);
        let haveMultiple = haveAnyGroups(newChecked);

        if (currentIndex === -1) {
            if (haveGroup) {
                newChecked = [0];
            } else {
                if (haveMultiple) {
                    newChecked = [0];
                }
            }
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        // Hasta aquí ya tendríamos los datos correspondientes.
        setChecked(newChecked);

        setCheckedGroup([0]);
        iterateStudentsArry(newChecked);
        toogleButtonAsign(newChecked);
    };

    const elementHaveGroup = (id) => {
        let obj = groups.students.find(o => o.id === id);
        if (obj.groups.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    const haveAnyGroups = (arry) => {
        let devolver = false;
        for (var student of arry) {
            if (student !== 0) {
                // Ya tenemos el ID ahora buscamos la propiedad que contenga los grupos
                let obj = groups.students.find(o => o.id === student);
                if (obj.hasOwnProperty('groups')) {
                    if (obj.groups.length > 0) {
                        devolver = true;
                        break;
                    }
                }
            }
        }
        return devolver;
    }

    const toogleButtonAsign = (newChecked) => {

        if (newChecked.length > 0) {
            if (newChecked.length === 1) {
                let index = newChecked.indexOf(0);
                if (index > -1) {
                    setDisabledSelect(true);
                } else {
                    setDisabledSelect(false);
                }
            } else {
                setDisabledSelect(false);
            }
        }
    }


    const iterateStudentsArry = (newChecked) => {
        let newGroups = [];
        for (var student of newChecked) {
            if (student !== 0) {
                // Ya tenemos el ID ahora buscamos la propiedad que contenga los grupos
                let obj = groups.students.find(o => o.id === student);
                if (obj.hasOwnProperty('groups')) {
                    if (obj.groups.length > 0) {
                        for (var group of obj.groups) {
                            if (!newGroups.includes(group)) {
                                newGroups.push(group);
                            }
                        }
                    }
                } else {
                    // alert("no definido");
                }
            }
        }
        setCheckedGroup(newGroups);
    }

    const handleGroupToogle = (value) => () => {


        setDoUserChange(true);

        const currentIndex = checkedGroup.indexOf(value);
        const newChecked = [...checkedGroup];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setCheckedGroup(newChecked);
    };


    // Cerrar el snackbar
    const CloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };



    const handleCloseModal = () => {
        setOpenFormModal(false);
    };

    const closeGroupModal = () => {
        setOpenGroupModal(false);
    };

    const handleOpenGroupModal = () => {
        setTypeGroupOperation(0);
        passUserDataToGroupProps();
        setUpdateMode(false);
        setOpenGroupModal(true);
    };

    const handleClickOpenModal = () => {
        setType(0);
        passUserDataToProps();
        setUpdateMode(false);
        setOpenFormModal(true);
    };

    // E S T U D I A N T E S
    // EDITAR
    const handleEditStudent = (studentId, type) => () => {
        setType(type);
        const nuevaEdicion = groups.students.find(o => o.id === studentId);
        passUserDataToProps(nuevaEdicion);
        setName(nuevaEdicion.name);
        setUpdateMode(true);
        setOpenFormModal(true);
    };



    const handleEditGroup = (groupId, type) => () => {
        setTypeGroupOperation(type);
        const nuevaEdicion = groups.groups.find(o => o.id === groupId);
        passUserDataToGroupProps(nuevaEdicion);
        setGroupName(nuevaEdicion.name);
        setUpdateMode(true);
        setOpenGroupModal(true);
    };


    const passUserDataToGroupProps = (data) => {
        if (data) {
            setGroupId(data.id);
            setGroupName(data.name);
            setGroupDescription(data.description);
        } else {
            setGroupId('');
            setGroupName('');
            setGroupDescription('');
        }
    };

    const passUserDataToProps = (data) => {
        if (data) {
            setId(data.id);
            setName(data.name);
            setNickname(data.nickname);
            setEmail(data.email)
            setParentName(data.parentName);
            setParentEmail(data.parentEmail);
            setGrade(data.grade);
        } else {
            setId('');
            setName('');
            setNickname('');
            setEmail('')
            setParentName('');
            setParentEmail('');
            setGrade('');
        }
    };

    const onchange = (data, type) => {
        handleCloseModal();
        if (type === 2) {
            dispatch(listGroups());
            setOpenSnackbar(true);
        }
    }


    const onchangeGroupComponent = (data, typeOp) => {
        closeGroupModal();
        if (typeOp === 2) {
            dispatch(listGroups());
            setOpenSnackbar(true);
        }
    }


    return (
        <>
            <div className={classes.root}>
                <h1>Asignación de Alumno(s) a Grupo(s)</h1>
                <div className={classes.rootd}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={5}>

                            <Paper className={classes.paper}>

                                {loading ? (
                                    <div>Obteniendo alumnos...</div>
                                ) : errorGroups ? (
                                    <div>Ocurrió un error</div>
                                ) : (
                                            <>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={handleClickOpenModal}
                                                    startIcon={<PersonAddIcon />}
                                                    className={classes.margin}>
                                                    Agregar estudiante
                                                </Button>
                                                <Divider />
                                                < List className={classes.list}>
                                                    {groups.students.map((value, index) => {
                                                        const labelId = `checkbox-list-label-${value.id}`;

                                                        return (

                                                            <ListItem key={value.id} role={undefined} dense button onClick={handleToggle(value.id)}>
                                                                <ListItemIcon>
                                                                    <Checkbox
                                                                        edge="start"
                                                                        checked={checked.indexOf(value.id) !== -1}
                                                                        tabIndex={-1}
                                                                        disableRipple
                                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText className={value.groups.length > 0 ? classes.selected : classes.noSelected} id={labelId} primary={`${value.name} - (${value.groups.length} grupo${value.groups.length > 1 ? 's' : ''})`} />
                                                                <ListItemSecondaryAction >
                                                                    <IconButton edge="end" onClick={handleEditStudent(value.id, 0)} aria-label="comments">
                                                                        <CreateIcon />
                                                                    </IconButton>
                                                                    <IconButton edge="end" onClick={handleEditStudent(value.id, 1)} aria-label="comments">
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                                <Divider />
                                                <h4 className={classes.rightAlng}>Total: {groups.students.length} Alumnos</h4>
                                            </>
                                        )}
                            </Paper>
                        </Grid>
                        <Grid item sm={12} md={2}>
                            <Paper className={classes.paper}>

                                <Grid
                                    container
                                    spacing={0}
                                    alignItems="center"
                                    justify="center"
                                    style={{ minHeight: "220px" }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={disabledSelect}
                                        className={classes.button}
                                        // startIcon={<Icon>arrow_back</Icon>}
                                        endIcon={<Icon>arrow_forward</Icon>}
                                        onClick={handleUpdateGroup}
                                    >
                                        Agregar Grupo / Asignatura
                                    </Button>
                                </Grid>

                            </Paper>
                        </Grid>
                        <Grid item sm={12} md={5}>
                            <Paper className={classes.paper}>


                                {loading ? (
                                    <div>Obteniendo Grupos...</div>
                                ) : errorGroups ? (
                                    <div>Ocurrió un error</div>
                                ) : (
                                            <>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={handleOpenGroupModal}
                                                    startIcon={<GroupWorkIcon />}
                                                    className={classes.margin}>
                                                    Agregar grupo
                                    </Button>
                                                <Divider />
                                                < List className={classes.list}>
                                                    {groups.groups.map((value, index) => {
                                                        const labelId = `checkbox-list-label-${value.id}`;

                                                        return (
                                                            // <ListItem key={value.id} role={undefined} dense button onClick={handleGroupToogle(value.id)}>
                                                            <ListItem key={value.id} disabled={disabledSelect} role={undefined} dense button onClick={handleGroupToogle(value.id)}>
                                                                <ListItemIcon>
                                                                    <Checkbox
                                                                        edge="start"
                                                                        checked={checkedGroup.indexOf(value.id) !== -1}
                                                                        tabIndex={-1}
                                                                        disableRipple

                                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText id={labelId} primary={`"${value.name}"${value.teachers}`} />
                                                                <ListItemSecondaryAction>
                                                                    <IconButton edge="end" onClick={handleEditGroup(value.id, 0)} aria-label="comments">
                                                                        <CreateIcon />
                                                                    </IconButton>
                                                                    <IconButton edge="end" onClick={handleEditGroup(value.id, 1)} aria-label="comments">
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                                <Divider />
                                                <h4 className={classes.rightAlng}>Total: {groups.groups.length} Grupos</h4>

                                            </>
                                        )}
                            </Paper>
                        </Grid>
                    </Grid>
                </div>


                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={CloseSnackbar}
                    // message="Se han realizado los cambios solicitados"
                    key={vertical + horizontal}>

                    <Alert onClose={CloseSnackbar} severity="success">
                        Se han realizado los cambios solicitados
                    </Alert>
                </Snackbar>


                <Backdrop className={classes.backdrop} open={loadinStudentUpdateList || loading || showBackdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>


                <Dialog
                    open={openFormModal}
                    onClose={handleCloseModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{type === 1 ? 'Borrar' : updateMode ? 'Actualizar ' + name : 'Nuevo estudiante'}</DialogTitle>
                    <DialogContent>
                        <AddStudentComponent
                            type={type}
                            onchange={(e, t) => { onchange(e, t) }}
                            id={id}
                            name={name}
                            nickname={nickname}
                            email={email}
                            parentName={parentName}
                            parentEmail={parentEmail}
                            grade={grade}
                        />
                    </DialogContent>
                </Dialog>


                <Dialog
                    open={openGroupModal}
                    onClose={closeGroupModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{typeGroupOperation === 1 ? 'Borrar' : updateMode ? 'Actualizar ' + groupName : 'Nuevo grupo'}</DialogTitle>
                    <DialogContent>
                        <AddGroupComponent
                            type={typeGroupOperation}
                            onchange={(e, t) => { onchangeGroupComponent(e, t) }}
                            id={groupId}
                            name={groupName}
                            description={groupDescription}
                        />
                    </DialogContent>
                </Dialog>

            </div >
        </>
    );
}


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default StudentsGroupScreen;