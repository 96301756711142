import {
    DATA_CONTACT_GET_REQUEST,
    DATA_CONTACT_GET_SUCCESS,
    DATA_CONTACT_GET_FAIL,
    DATA_CONTACT_SAVE_REQUEST,
    DATA_CONTACT_SAVE_SUCCESS,
    DATA_CONTACT_SAVE_FAIL,
    DATA_CONTACT_RESET_SUCCESS,

    DATA_CONTACT_POST_REQUEST,
    DATA_CONTACT_POST_SUCCESS,
    DATA_CONTACT_POST_FAIL,
    DATA_CONTACT_POST_RESET,

} from '../constants/dataContactConstants';

import {
    GROUP_ACTIONS_URL
} from '../constants/urlConstants';

const urlBase = GROUP_ACTIONS_URL;


const getDataContactApi = () => async (dispatch, getState) => {

    dispatch({ type: DATA_CONTACT_GET_REQUEST });
    // const { userSignin: { userInfo } } = getState();

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    await fetch(
        urlBase +
        'user/contact/'
        , {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(function (response) {
            if (response.ok) {
                ok = true;
            } else if (response.status === 400) {
                err400 = true;
            } else {
                // unknown = true;
            }
            return response.json();
        })
        .then(function (data) {
            // console.log('data = ', data);
            msg = data;
        })
        .catch(function (err) {
            console.error(err);
            // unknown = true;
        });

    if (ok) {
        dispatch({ type: DATA_CONTACT_GET_SUCCESS, payload: msg });
    } else if (err400) {
        dispatch({ type: DATA_CONTACT_GET_FAIL, payload: msg.errors });
    } else {
        dispatch({ type: DATA_CONTACT_GET_FAIL, payload: "Ocurrió un error, intenta más tarde" });
    }

};


const updateDataContactApi = (user) => async (dispatch, getState) => {

    dispatch({ type: DATA_CONTACT_SAVE_REQUEST });
    const { userSignin: { userInfo } } = getState();

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    await fetch(
        urlBase +
        'user/contact/'
        , {
            method: 'PUT',
            body: JSON.stringify(user),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(function (response) {
            if (response.ok) {
                ok = true;
            } else if (response.status === 400) {
                err400 = true;
            } else {
                // unknown = true;
            }
            return response.json();
        })
        .then(function (data) {
            // console.log('data = ', data);
            msg = data;
        })
        .catch(function (err) {
            console.error(err);
            // unknown = true;
        });

    if (ok) {
        dispatch({ type: DATA_CONTACT_SAVE_SUCCESS, payload: msg });
    } else if (err400) {
        dispatch({ type: DATA_CONTACT_SAVE_FAIL, payload: msg.errors });
    } else {
        dispatch({ type: DATA_CONTACT_SAVE_FAIL, payload: "Ocurrió un error, intenta más tarde" });
    }
}


const postCommentsApi = (message) => async (dispatch, getState) => {

    dispatch({ type: DATA_CONTACT_POST_REQUEST });

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    await fetch(
        // urlBase +
        'https://mailingapp.azurewebsites.net/simulador/comments/'
        , {
            method: 'POST',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then(function (response) {
            if (response.ok) {
                ok = true;
            } else if (response.status === 400) {
                err400 = true;
            } else {
                // unknown = true;
            }
            return response.json();
        })
        .then(function (data) {
            // console.log('data = ', data);
            msg = data;
        })
        .catch(function (err) {
            console.error(err);
            // unknown = true;
        });

    if (ok) {
        dispatch({ type: DATA_CONTACT_POST_SUCCESS, payload: msg });
    } else if (err400) {
        dispatch({ type: DATA_CONTACT_POST_FAIL, payload: msg.errors });
    } else {
        alert("Sucedión un error, intenta más tarde");
        dispatch({ type: DATA_CONTACT_POST_FAIL, payload: "Ocurrió un error, intenta más tarde" });
    }
}


const resetDataContactApi = () => (dispatch) => {
    dispatch({ type: DATA_CONTACT_RESET_SUCCESS });
}

const resetPostCommentsApi = () => (dispatch) => {
    dispatch({ type: DATA_CONTACT_POST_RESET });
}



const postContactusApi = (message) => async (dispatch, getState) => {

    dispatch({ type: DATA_CONTACT_POST_REQUEST });

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    await fetch(
        urlBase +
        'user/contactus/'
        , {
            method: 'POST',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then(function (response) {
            if (response.ok) {
                ok = true;
            } else if (response.status === 400) {
                err400 = true;
            } else {
                // unknown = true;
            }
            return response.json();
        })
        .then(function (data) {
            // console.log('data = ', data);
            msg = data;
        })
        .catch(function (err) {
            console.error(err);
            // unknown = true;
        });

    if (ok) {
        dispatch({ type: DATA_CONTACT_POST_SUCCESS, payload: msg });
    } else if (err400) {
        dispatch({ type: DATA_CONTACT_POST_FAIL, payload: msg.errors });
    } else {
        alert("Sucedión un error, intenta más tarde");
        dispatch({ type: DATA_CONTACT_POST_FAIL, payload: "Ocurrió un error, intenta más tarde" });
    }
}


/** CONTACT US SECTION */



export {
    getDataContactApi,
    updateDataContactApi,
    resetDataContactApi,
    postCommentsApi,
    resetPostCommentsApi,

    postContactusApi,
};
