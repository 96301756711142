import {
    ENABLE_USERS_LIST_REQUEST,
    ENABLE_USERS_LIST_SUCCESS,
    ENABLE_USERS_LIST_FAIL,

    ENABLE_USER_DETAL_REQUEST,
    ENABLE_USER_DETAL_SUCCESS,
    ENABLE_USER_DETAL_FAIL,

    ENABLE_USERS_UPDATE_REQUEST,
    ENABLE_USERS_UPDATE_SUCCESS,
    ENABLE_USERS_UPDATE_FAIL,
} from '../constants/enableUsersConstants';

function enableUsersListReducer(state = { users: [] }, action) {
    switch (action.type) {
        case ENABLE_USERS_LIST_REQUEST:
            return { loading: true, users: [] };
        case ENABLE_USERS_LIST_SUCCESS:
            return { loading: false, users: action.payload };
        case ENABLE_USERS_LIST_FAIL:
            return { loading: false, users: action.payload };
        default:
            return state;
    }
}


function enableUserDetailReducer(state = { user: [] }, action) {
    switch (action.type) {
        case ENABLE_USER_DETAL_REQUEST:
            return { loading: true, user: [] };
        case ENABLE_USER_DETAL_SUCCESS:
            return { loading: false, user: action.payload };
        case ENABLE_USER_DETAL_FAIL:
            return { loading: false, user: action.payload };
        default:
            return state;
    }
}

function enableUserUpdateReducer(state = { user: [] }, action) {
    switch (action.type) {
        case ENABLE_USERS_UPDATE_REQUEST:
            return { loading: true, user: [] };
        case ENABLE_USERS_UPDATE_SUCCESS:
            return { loading: false, user: action.payload, success: true };
        case ENABLE_USERS_UPDATE_FAIL:
            return { loading: false, user: action.payload };
        default:
            return state;
    }
}


export {
    enableUsersListReducer,
    enableUserDetailReducer,
    enableUserUpdateReducer
};
