import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

function SigninScreen(props) {

  const redirect = props.location.search ? props.location.search.split("=")[1] : '/';


  const useStyles = makeStyles((theme) => ({
    // contenido: {
    //   alignItems: 'center',
    // },

    root: {
      flexGrow: 1,
    },
    paper: {
      textAlign: 'center',
    },
  }));

  const classes = useStyles();


  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    Cookie.remove("userSuccess");
  });

  return (

    <>

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >

        <Grid item xs={12} className={classes.paper} >
          <br></br>
          <br></br>
          <h2>¡Tu Registro ha sido exitoso! </h2>
        </Grid>
        <Grid item xs={12} className={classes.paper} >
          <h3>Sin embargo el moderador necesita autorizar tu cuenta para que puedas usar la aplicación.</h3>
        </Grid>
        <Grid item xs={12} className={classes.paper} >
          <h3> Pronto nos pondremos en contacto contigo.</h3>
        </Grid>
        <Grid item xs={12} className={classes.paper} >
          <Link to={redirect === "/" ? "/" : "/?redirect=" + redirect} className="button secondary text-center" >Ir al inicio</Link>
        </Grid>
       
      </Grid>

    </>
  )
}
export default SigninScreen;