import React, { useEffect, useState, useRef } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    recoveryApi,
    recoveryResetApi
} from '../actions/userActions';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { deepOrange, green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import BannerSchools from '../components/BannerSchools';
import BannerPartners from '../components/BannerPartners';


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {/* <Link color="inherit" href="https://material-ui.com/"> */}
            <Link color="inherit" component={LinkRouter} to={'/'}>
                School Market
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


function RecoveryScreen(props) {

    const [firstTime, setFirstTime] = useState(true);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const userRecovery = useSelector(state => state.userRecovery);
    const { loading, recoveryData, error, success } = userRecovery;

    const userSignin = useSelector(state => state.userSignin);
    const { userInfo } = userSignin;

    const dispatch = useDispatch();
    const redirect = props.location.search ? props.location.search.split("=")[1] : '/';

    const redirectReset = props.location.search ? props.location.search.split("=")[1] : '/reset';


    let textInput = useRef(null);

    useEffect(() => {
        return () => {
            //
            dispatch(recoveryResetApi(email));
        };
    }, []);


    useEffect(() => {
        if (userInfo) {
            props.history.push(redirect);
        }
        return () => {
            //
        };
    }, [userInfo]);


    useEffect(() => {

        if (success) {
            props.history.push(redirectReset);
        }

        if (error) {
            setOpen(true);
        }

        return () => {
            //
        };

    }, [userRecovery]);


    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(recoveryApi(email));
    }

    const checkEmail = (v) => {

        if (/\s/g.test(v) || v.length === 0) {
            setEmailError('Este campo no deb estar vacío');
        } else {
            setEmailError("");
        }

        setEmail(v);

    }

    const useStyles = makeStyles((theme) => ({
        paper: {
            // marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        square: {
            // padding: theme.spacing(2),
            color: theme.palette.getContrastText(deepOrange[500]),
            backgroundColor: "#fafafa",
            width: 80,
            height: 80,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        wrapper: {
            margin: theme.spacing(0),
            position: 'relative',
        },
    }));

    const classes = useStyles();


    const [open, setOpen] = useState(false);


    const handleClose = () => {
        setEmail('');
        setOpen(false);

        setTimeout(() => {
            textInput.current.focus();
        }, 100);

    };

    return (

        <div>

            {/* <BannerSchools /> */}
            <div id="cft"  >
                <img style={{ width: 'calc(100%)' }} className="bottom" src="/images/Banner2.jpg" />
                <img style={{ width: 'calc(100%)' }} className="top" src="/images/Banner1.jpg" />
            </div>

            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    {/* <img src="/images/logo.png" alt="logo" /> */}
                    <Typography component="h1" variant="h5">
                        Recuperar Contraseña
          </Typography>

                    <form className={classes.form} noValidate onSubmit={submitHandler}>
                        <TextField
                            inputRef={textInput}
                            value={email}
                            error={emailError.length > 0}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Correo electrónico / alias"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={(e) => checkEmail(e.target.value)}
                            disabled={loading}
                            helperText={emailError && emailError}
                        />

                        <div className={classes.wrapper}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={loading || !email || emailError.length > 0}
                            >
                                Enviar
                </Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                        <Grid container>

                            <Button
                                component={LinkRouter} to={'/'}
                                fullWidth
                                variant="contained"
                                className={classes.submit}
                                disabled={loading}
                            >
                                Cancelar
                </Button>
                        </Grid>
                    </form>
                </div>

            </Container>


            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Error!"}</DialogTitle>
                <DialogContent>
                    <div>
                        {error && error.map(function (d, idx) {
                            return (
                                <>
                                    {d.msg}<br></br>
                                </>
                            )
                        })}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        color="secondary"
                        autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>



            <div id="cfb"  >
                <img style={{ width: 'calc(100%)' }} className="topb" src="/images/Banner1.jpg" />
                <img style={{ width: 'calc(100%)' }} className="bottomb" src="/images/Banner2.jpg" />

            </div>
            {/* <BannerPartners />

            <Box mt={8}>
                <Copyright />
            </Box> */}
        </div>
    )





}
export default RecoveryScreen;