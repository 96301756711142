import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios'

import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import dateFormat from 'dateformat';

import {
    resetGetShippingApi,
    getShippingApi
} from '../actions/shippingActions'

import {
    STUDENT_ACTIONS_URL,
    SCHOOL_DEPLOY
} from '../constants/urlConstants';


import NumberFormat from 'react-number-format';


import { makeStyles, withStyles } from '@material-ui/core/styles';
import ProductDetailComponent from '../components/ProductDetailComponent';
// MATERIAL UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
// import SchoolIcon from '@material-ui/core/School';
// import WorkIcon from '@material-ui/core/Work';
import InputBase from '@material-ui/core/InputBase';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Pagination from '@material-ui/lab/Pagination';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DescriptionIcon from '@material-ui/icons/Description';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ShippingScreen(props) {

    const getStudentsGroupPurchases = useSelector(state => state.getStudentsGroupPurchases);
    const { loading: loadingList, data: list, error: errorList, success: successList } = getStudentsGroupPurchases;

    const getGroupPurchases = useSelector(state => state.getGroupPurchases);
    const { loading: loadingGroups, error: errorGroups, success: successGroups, groups } = getGroupPurchases;


    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;

    const urlBase = STUDENT_ACTIONS_URL;
    const schooldeploy = SCHOOL_DEPLOY;

    /**
        * shippingStatus: Estados de la entrega:
        * deliveryPerson: Persona que entregó el producto,
        * userStatusUpdate: Usuario que actualizó estado,
        * dateShippingUpdate: Fecha de actualización,
        * shippingHistorical: Historial de actualizaciones
        */

    const [deliveryPerson, setDeliveryPerson] = useState("");
    const [userStatusUpdate, setUserStatusUpdate] = useState("");
    const [commentsDelivery, setCommentsDelivery] = useState("");
    const [ticketId, setTicketId] = useState("");
    const [productId, setProductId] = useState("");

    const [state] = useState({
        vertical: 'bottom',
        horizontal: 'center',
    });

    const { vertical, horizontal } = state;
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [productSearch, setProductSearch] = useState('');
    const [shippingHistorical, setShippingHistorical] = useState("");

    const [open, setOpen] = useState(false);

    const [editable, setEditable] = useState(false);

    const [estadoEntrega, setEstadoEntrega] = useState("other");

    const [productModal, setProductModal] = useState({})

    const [dataTable, setDataTable] = useState([]);

    const [columnFilter, setColumnFilter] = useState("date");
    const [isAscending, setIsAscending] = useState(false);
    const [teacherChecked, setTeacherChecked] = useState([]);

    const [showBackdrop, setShowBackdrop] = useState(false);

    const [pagePagination, setPagePagination] = useState(1);
    const [ipp, setIpp] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    const [groupId, setGroupId] = useState('10');
    const [groupName, setGroupName] = useState('Sin nombre');
    const [teacherName, setTeacherName] = useState('No asignado');

    const dispatch = useDispatch();

    const useStyles = makeStyles((theme) => ({

        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
            backgroundColor: '#f5f5f5',
            minHeight: 'calc(100vh - 64px)',
        },
        paper: {
            padding: theme.spacing(2),
            // textAlign: 'right',
            color: theme.palette.text.secondary,
        },
        paper2: {
            padding: theme.spacing(2),
            // textAlign: 'left',
            color: theme.palette.text.secondary,
        },
        list: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,

        },
        margin: {
            margin: theme.spacing(2),

        },
        btnMargin: {
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(3),

        },
        table: {
            minWidth: 700,
        },
        iconbuton: {
            '& > *': {
                margin: theme.spacing(0),
            },
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        paginationCls: {
            padding: theme.spacing(1),
            textAlign: 'right',
        },
        btnHeader: {
            color: "#ffffff",
            margin: theme.spacing(1),
        },
        alignCenter: {
            textAlign: 'center',
        },
        rightAlng: {
            textAlign: 'left',
        },
        maginZero: {
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(0),
            backgroundColor: '#000000',
            color: 'white',
            paddingLeft: '4px'

        },
        arrowUp: {
            fontSize: 10
        },
        rootSearch: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 400,
        },
        formControl: {
            minWidth: 160,
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        conButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
    }));

    const classes = useStyles();

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    useEffect(() => {
        // Traer grupos
        dispatch(getShippingApi());
        return () => {
            // dispatch(resetDeleteProductApi());
        };
    }, []);


    useEffect(() => {


        if (successList) {
            // handleSetMarket();

            if (list.teachers.length > 0) {

                let teacherNamesTmp = "";

                for (let idx in list.teachers) {
                    teacherNamesTmp = teacherNamesTmp + list.teachers[idx].name + ', ';
                }

                teacherNamesTmp = teacherNamesTmp.slice(0, -2);

                setTeacherName(teacherNamesTmp);

            } else {
                setTeacherName("No asignado");
            }

            if (list.items.length > 0) {
                setUpPaging(list.items);
            }

        }

        return () => {

        };
    }, [getStudentsGroupPurchases]);


    const setUpPaging = (items) => {

        var page = pagePagination,
            per_page = ipp,
            offset = (page - 1) * per_page,

            paginatedItems = items.slice(offset).slice(0, per_page),

            total_pages = Math.ceil(items.length / per_page);

        // Calcular la enumaracion
        let initialCount = ((page - 1) * per_page);

        for (let idx in paginatedItems) {
            initialCount++;
            let studentDataTmp = list.students.find(o => o.id === paginatedItems[idx].user_id);
            // console.log("groups"); 
            // console.log(list);
            paginatedItems[idx].consec = initialCount;
            paginatedItems[idx].studentName = studentDataTmp ? studentDataTmp.name : 'Error';
            paginatedItems[idx].nickname = studentDataTmp ? studentDataTmp.nickname : 'Error';
        }

        let arryProducts = {
            page: page,
            per_page: per_page,
            pre_page: page - 1 ? page - 1 : null,
            next_page: (total_pages > page) ? page + 1 : null,
            total: items.length,
            total_pages: total_pages,
            items: paginatedItems
        };

        setTotalPages(total_pages);

        setDataTable(paginatedItems);

        console.log("arryProducts");
        console.log(arryProducts);

        // alert("creando paginación");

    }


    // *** SIRVE
    useEffect(() => {

        if (loadingGroups || loadingList) {
            setShowBackdrop(true);
        } else {
            setShowBackdrop(false);
        }

        if (successGroups) {
            // alert("Todo ok");
            console.log("groups-todo ok");
            console.log(groups);

            setDataTable(groups.products);

        }

        return () => {
        };
    }, [getGroupPurchases, getStudentsGroupPurchases]);


    // const handleGroupChange = (event) => {

    //     setDataTable([]);
    //     setPagePagination(1);
    //     setIsAscending(false);
    //     setIpp(10);
    //     setColumnFilter("date");

    //     let group = event.target.value;
    //     setGroupId(group);
    //     dispatch(getStudentsGroupPurchasesApi(group));
    //     let obj = groups.groups.find(o => o.id === group);
    //     setGroupName(obj ? obj.name : 'No encontrado');
    // };


    const handleChangeFilter = (column) => () => {

        let chn = isAscending;

        if (columnFilter === column) {
            setIsAscending(!chn);
        } else {
            setColumnFilter(column);
        }


        let datableTemp = dataTable;

        datableTemp.reverse();

        setDataTable(datableTemp);

    };

    const obtenerFechaConvertida = (fecha) => {
        let utcSeconds = fecha;
        let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds);

        return dateFormat(d, 'd"/"mm"/"yyyy  " "h:MM:ss TT');

        // 'dd"/"mm"/"yyyy'
    }

    const obtenerLeyendaStatus = (estado) => {



        // * shippingStatus: Estados de la entrega:
        // *      10 Por entregar
        // *      20 Entregado
        // *      30 Rechazado
        // *      40 Todos

        let statusLegend = "";

        switch (estado) {
            case '10':
                statusLegend = "Por entregar";
                break;
            case '20':
                statusLegend = "Entregado";
                break;
            case '30':
                statusLegend = "Rechazado";
                break;
            default:
                statusLegend = "desconocido";
        }

        return statusLegend;

    }


    const handleChangePage2 = (event, value) => {
        setPagePagination(value);
    };

    const handleChange = (event) => {
        setIpp(event.target.value);
        setPagePagination(1);
    };


    // useEffect(() => {
    //     // // dispatch(listProducsApi(pagePagination, ipp));
    //     // dispatch(listProducsApi('all', "", pagePagination, ipp, columnFilter, isAscending, list.items));
    //     if (dataTable.length > 0) {


    //         // list.items

    //         // alert(columnFilter);
    //         // alert(isAscending);

    //         switch (columnFilter) {
    //             case 'date':

    //                 let itemsArry = list.items;

    //                 if (isAscending) {
    //                     itemsArry.reverse();
    //                 } else {
    //                     itemsArry.reverse();
    //                 }

    //                 setUpPaging(itemsArry);

    //                 break;

    //             default:
    //                 console.log('Lo lamentamos, por el momento no disponemos de ' + '.');
    //         }



    //     }

    //     return () => {
    //     };
    // }, [pagePagination, ipp, isAscending, columnFilter]);

    const handleOpenDialog = (productId, type, item) => () => {

        setShowBackdrop(true);
        let userId = userInfo._id;

        axios.post(urlBase + 'user/getuserstatusupdate', {
            ticketId: item.id,
            productId: item.productId
        }, {
            headers: {
                userid: userId,
                schoolcoode: schooldeploy
            }
        })
            .then(res => {
                console.log("devolviendo");
                console.log(res.data);

                let itemServer = res.data;

                // let count = item.shippingHistorical.length;

                // for (let idx in item.shippingHistorical) {
                //     item.shippingHistorical[idx].consec = count;
                //     count--;
                // }
                itemServer.timestamp = item.timestamp;

                console.log("itemServer");
                console.log(itemServer);

                setProductModal(itemServer);
                setTicketId(item.id);
                setProductId(item.productId);

                if (type === "edit") {
                    setEditable(true);
                } else {
                    setEditable(false);
                }

                setOpen(true);

                // Trear todos los cambios del ticket 
                // Aquí debería aparecer un loader
                // console.log("item");
                // console.log(item);

                setShowBackdrop(false);

            })
            .catch(err => setShowBackdrop(false))

    };


    const handleClose = () => {

        setEstadoEntrega("other");
        setProductModal({});
        setDeliveryPerson("");
        setCommentsDelivery("");
        setTicketId("");
        setProductId("");

        setOpen(false);
    };


    const saveShippingProduct = () => {

        // alert("guardando");

        setShowBackdrop(true);

        // setEstadoEntrega("other");
        // setProductModal([]);

        // setOpen(false);


        let userId = userInfo._id;

        if (estadoEntrega != "other") {

            setOpen(false);
            axios.post(urlBase + 'user/updateshipping', {
                shippingStatus: estadoEntrega,
                deliveryPerson: deliveryPerson,
                userStatusUpdate: userStatusUpdate,
                commentsDelivery: commentsDelivery,
                ticketId: ticketId,
                productId: productId
            }, { headers: { userid: userId, schoolcoode: schooldeploy } })
                .then(res => {
                    // console.log("Se actualizó correctamente -> res");
                    // console.log(res);
                    setShowBackdrop(false);
                    setOpenSnackbar(true);

                    updateLocalArray(res.data);

                })
                .catch(err => {
                    setShowBackdrop(false);
                    console.error(err);
                })
        }

    };


    const handleGroupChange = (event) => {



        let group = event.target.value;
        setGroupId(group);

        setShowBackdrop(true);
        let userId = userInfo._id;

        axios.post(urlBase + 'user/filtershipping', {
            shippingStatus: group,
            id: userInfo._id,
            productSearch: productSearch
        }, { headers: { userid: userId, schoolcoode: schooldeploy } })
            .then(res => {
                console.log("nueva datatable");
                console.log(res.data)
                setDataTable(res.data.products);
                setShowBackdrop(false);
            })
            .catch(err => {
                setShowBackdrop(false);
                console.error(err);
            })

    };


    const estadoEntregaChange = (event) => {


        let estEntrega = event.target.value;
        setEstadoEntrega(estEntrega);


        // setDataTable([]);
        // setPagePagination(1);
        // setIsAscending(false);
        // setIpp(10);
        // setColumnFilter("date");

        // setGroupId(group);
        // dispatch(getStudentsGroupPurchasesApi(group));
        // let obj = groups.groups.find(o => o.id === group);
        // setGroupName(obj ? obj.name : 'No encontrado');
    };

    const checkDeliveryPerson = (v) => {
        setDeliveryPerson(v);
    }

    const checkCommentsDelivery = (v) => {
        setCommentsDelivery(v);
    }

    // Cerrar el snackbar
    const CloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };



    const updateLocalArray = (objToUpdate) => {

        /** objToUpdate */

        // productId: "23LQ3fOC23EGeBaeIObi"
        // shippingStatus: "20"
        // ticketId: "82c97620-bef8-11eb-9fa8-2fad811effdf"

        /** dataTable */

        // id: "9ff996f0-d152-11eb-b0d1-ddc93b295f5f"
        // name: "Cámara de seguridad inalámbrica"
        // price: 28
        // productId: "UXsoP9caRS3jLal517eH"
        // qty: 1
        // shippingHistorical: [{ … }]
        // shippingStatus: "20"
        // timestamp: { _seconds: 1624143837, _nanoseconds: 659000000 }
        // userName: "Osmar QA"
        // userNickname: "osmarqa"
        // user_id: "hLUckLaipYcDhlAB2w1H3lovlgA2"

        let datableTemp = dataTable;

        // Encontré el índice
        var foundIndex = datableTemp.findIndex(x => x.id == objToUpdate.ticketId && x.productId == objToUpdate.productId);

        if (foundIndex > -1) {
            // Tenemos 2 opciones 
            if (groupId == objToUpdate.shippingStatus || groupId == "40") {
                // Si el shippingStatus es igual al actual seleccionado modificarlo
                let founfObject = datableTemp[foundIndex];
                founfObject.shippingStatus = objToUpdate.shippingStatus;
                datableTemp[foundIndex] = founfObject;
            } else {
                // Quitarlo del arreglo principal
                datableTemp.splice(foundIndex, 1);
            }
        }

        setDataTable(datableTemp);

        // Hacer el request nuevamente
        // handleGroupChange(groupId); 

    }

    const handleDoSearchText = (e) => {
        e.preventDefault();

        // setHasNextPage(false);
        // setEndMessage("");
        // setItems([]);
        // dispatch(listStoreProducsRandomApi(categoryId, productSearch, []));
        // // // dispatch(listStoreProducsRandomApi(categoryId, productSearch, pagePagination));
        setShowBackdrop(true);
        let userId = userInfo._id;

        axios.post(urlBase + 'user/filtershipping', {
            shippingStatus: groupId,
            id: userInfo._id,
            productSearch: productSearch
        }, { headers: { userid: userId, schoolcoode: schooldeploy } })
            .then(res => {
                console.log("nueva datatable");
                console.log(res.data)
                setDataTable(res.data.products);
                setShowBackdrop(false);
            })
            .catch(err => {
                setShowBackdrop(false);
                console.error(err);
            })


    };

    const deleteTerm = () => {
        setProductSearch("");
        // // // // dispatch(listStoreProducsRandomApi(categoryId, "", pagePagination));

        // if (categoryId === 'all') {
        //     // Es como estar haciendo un reset
        //     setHasNextPage(true);
        //     setItemsId([]);
        //     setEndMessage("");
        //     setItems([]);
        //     dispatch(getRandomConfiguration());
        // } else {
        //     setHasNextPage(false);
        //     setEndMessage("");
        //     setItems([]);
        //     dispatch(listStoreProducsRandomApi(categoryId, "", []));
        // }


        setShowBackdrop(true);
        let userId = userInfo._id;

        axios.post(urlBase + 'user/filtershipping', {
            shippingStatus: groupId,
            id: userInfo._id,
            productSearch: null
        }, { headers: { userid: userId, schoolcoode: schooldeploy } })
            .then(res => {
                console.log("nueva datatable");
                console.log(res.data)
                setDataTable(res.data.products);
                setShowBackdrop(false);
            })
            .catch(err => {
                setShowBackdrop(false);
                console.error(err);
            })

    }

    const handleTextFieldChange = (e) => {
        let value = e.target.value;
        setProductSearch(value);
        // setShowBackdrop(true);
        // let userId = userInfo._id;

        // axios.post(urlBase + 'user/filtershipping', {
        //     shippingStatus: groupId,
        //     id: userInfo._id,
        //     productSearch: value
        // }, { headers: { userid: userId, schoolcoode: schooldeploy } })
        //     .then(res => {
        //         console.log("nueva datatable");
        //         console.log(res.data)
        //         setDataTable(res.data.products);
        //         setShowBackdrop(false);
        //     })
        //     .catch(err => {
        //         setShowBackdrop(false);
        //         console.error(err);
        //     })
    };

    return (
        <>
            <div className={classes.root}>
                <h1>Entrega de productos v2</h1>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                        <Paper className={classes.paper}>

                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="flex-start"
                            >

                                <FormControl
                                    // size="small"
                                    className={classes.formControl}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        variant="outlined"
                                        value={groupId}
                                        onChange={handleGroupChange}
                                    >
                                        <MenuItem value="40">Todos los estados</MenuItem>
                                        {groups.groups && groups.groups.map((value, index) => {
                                            return (
                                                <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>

                                <Paper component="form" onSubmit={handleDoSearchText} className={classes.rootSearch}>
                                    <InputBase
                                        className={classes.input}
                                        value={productSearch}
                                        onChange={handleTextFieldChange}
                                        placeholder="Buscar alumno"
                                        inputProps={{ 'aria-label': 'buscar alumno' }}
                                    />
                                    <IconButton type="submit" color="primary" className={classes.iconButton} aria-label="buscar">
                                        <SearchIcon />
                                    </IconButton>
                                    <Divider className={classes.divider} orientation="vertical" />
                                    <IconButton color="secondary" disabled={productSearch.length == 0} onClick={deleteTerm} className={classes.iconButton} aria-label="directions">
                                        <ClearIcon />
                                    </IconButton>
                                </Paper>


                            </Grid>

                            <br></br>

                            {dataTable && dataTable.length > 0 ? (
                                <>

                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} size="small" aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="right">#</StyledTableCell>
                                                    {/* <StyledTableCell>
                                                        <Button
                                                            startIcon={columnFilter === 'title' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'title' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            // onClick={handleChangeFilter('group')}
                                                            className={classes.btnHeader}>
                                                            Grupo
                                                                </Button>
                                                    </StyledTableCell> */}
                                                    <StyledTableCell >
                                                        <Button
                                                            startIcon={columnFilter === 'category' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'category' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            // onClick={handleChangeFilter('teacher')}
                                                            className={classes.btnHeader}>
                                                            Ticket
                                                        </Button>
                                                    </StyledTableCell>


                                                    <StyledTableCell >
                                                        <Button
                                                            startIcon={columnFilter === 'stock' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'stock' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            // onClick={handleChangeFilter('name')}
                                                            className={classes.btnHeader}>
                                                            Nombre Alumno
                                                        </Button>
                                                    </StyledTableCell>
                                                    <StyledTableCell >
                                                        <Button
                                                            startIcon={columnFilter === 'price' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'price' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            // onClick={handleChangeFilter('nickname')}
                                                            className={classes.btnHeader}>
                                                            Alias alumno
                                                        </Button>
                                                    </StyledTableCell>
                                                    <StyledTableCell >
                                                        <Button
                                                            startIcon={columnFilter === 'price' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'price' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            // onClick={handleChangeFilter('product')}
                                                            className={classes.btnHeader}>
                                                            Nombre Producto
                                                        </Button>
                                                    </StyledTableCell>
                                                    <StyledTableCell >
                                                        <Button
                                                            startIcon={columnFilter === 'price' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'price' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            // onClick={handleChangeFilter('product')}
                                                            className={classes.btnHeader}>
                                                            Cantidad
                                                        </Button>
                                                    </StyledTableCell>
                                                    {/* <StyledTableCell align="right">
                                                        <Button
                                                            startIcon={columnFilter === 'price' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'price' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            // onClick={handleChangeFilter('price')}
                                                            className={classes.btnHeader}>
                                                            Precio Unitario
                                                        </Button>
                                                    </StyledTableCell> */}
                                                    <StyledTableCell align="right">
                                                        <Button
                                                            startIcon={columnFilter === 'price' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'price' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            // onClick={handleChangeFilter('price')}
                                                            className={classes.btnHeader}>
                                                            Total
                                                        </Button>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <Button
                                                            startIcon={columnFilter === 'date' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'date' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                            size="small"
                                                            onClick={handleChangeFilter('date')}
                                                            className={classes.btnHeader}>
                                                            Fecha Compra
                                                        </Button>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <Button
                                                            size="small"
                                                            // onClick={handleChangeFilter('date')}
                                                            className={classes.btnHeader}>
                                                            Estado
                                                        </Button>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {/* <Button
                                                            size="small"
                                                            // onClick={handleChangeFilter('date')}
                                                            className={classes.btnHeader}>
                                                            Detalle
                                                        </Button> */}
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>

                                                {dataTable && dataTable.map(function (row, idx) {
                                                    let consect = idx + 1;
                                                    return (
                                                        <StyledTableRow key={row.id + "-" + consect}>
                                                            <StyledTableCell align="right">{consect}</StyledTableCell>
                                                            {/* <StyledTableCell >
                                                                {groupName}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{teacherName}</StyledTableCell> */}
                                                            <StyledTableCell>{row.id}</StyledTableCell>
                                                            <StyledTableCell>{row.userName}</StyledTableCell>
                                                            <StyledTableCell>{row.userNickname}</StyledTableCell>
                                                            <StyledTableCell>{row.name}</StyledTableCell>
                                                            <StyledTableCell align="right">{row.qty}</StyledTableCell>
                                                            {/* <StyledTableCell align="right"><b>&#x20BF;</b> {parseFloat(row.price).toFixed(2)}</StyledTableCell> */}
                                                            <StyledTableCell align="right"><b>&#x20BF;</b> {parseFloat(row.price * row.qty).toFixed(2)}</StyledTableCell>
                                                            <StyledTableCell >{obtenerFechaConvertida(row.timestamp._seconds)}</StyledTableCell>
                                                            <StyledTableCell> {row.shippingStatus ? obtenerLeyendaStatus(row.shippingStatus) : "Por entregar"} </StyledTableCell>
                                                            <StyledTableCell align="right">
                                                                <div className={classes.iconbuton}>
                                                                    <IconButton color="primary" aria-label="edit" onClick={handleOpenDialog(row.id, 'edit', row)}>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                    <IconButton color="primary" aria-label="see" onClick={handleOpenDialog(row.id, 'see', row)}>
                                                                        <VisibilityIcon />
                                                                    </IconButton>
                                                                </div>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>



                                    {/* <Grid container direction="row" alignItems="center">
                                        <Grid item className={classes.paginationCls}>
                                            <Pagination count={totalPages} color="primary" page={pagePagination} onChange={handleChangePage2} showFirstButton showLastButton />
                                        </Grid>
                                        <Grid item className={classes.paginationCls}>
                                            <span>| &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Elementos por página:</span>
                                        </Grid>
                                        <Grid item className={classes.paginationCls}>
                                            <FormControl>
                                                <Select
                                                    labelId="demo-customized-select-label"
                                                    id="demo-customized-select"
                                                    value={ipp}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={5}>5</MenuItem>
                                                    <MenuItem value={10}>10</MenuItem>
                                                    <MenuItem value={25}>25</MenuItem>
                                                    <MenuItem value={50}>50</MenuItem>
                                                    <MenuItem value={100}>100</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid> */}

                                </>
                            ) : (<>
                                <Grid item sm={12} md={12}>
                                    <Paper className={classes.paper}>
                                        <div className={classes.alignCenter}>
                                            <h2>{loadingGroups || loadingList ? 'Obteniendo compras' : 'No existen compras'}</h2>
                                        </div>
                                    </Paper>
                                </Grid>
                            </>)}

                        </Paper>
                    </Grid>
                </Grid>

            </div>
            <Backdrop className={classes.backdrop} open={showBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>


            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={CloseSnackbar}
                message="Se han realizado los cambios solicitados"
                key={vertical + horizontal}>
                <Alert onClose={CloseSnackbar} severity="success">
                    Se han realizado los cambios solicitados
                </Alert>
            </Snackbar>


            <Dialog
                open={open}
                // minWidth={'md'}
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">{editable ? "Modificar" : "Ver"} </DialogTitle> */}
                <DialogTitle id="alert-dialog-title">{productModal.title} ({productModal.timestamp ? obtenerFechaConvertida(productModal.timestamp._seconds) : ""})</DialogTitle>

                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item sm={12}>
                            {/* <Paper className={classes.paper}> */}

                            {/* <h3>{ productModal.timestamp ? obtenerFechaConvertida(productModal.timestamp._seconds) : "" }</h3> */}

                            {editable && editable ? (
                                <>

                                    <Grid item xs={12} >
                                        <h4 className={classes.maginZero}>Actualizar estado</h4>
                                    </Grid>
                                    <br></br>

                                    <form className={classes.form} noValidate >
                                        {/* <h3>Actualiza estado</h3> */}
                                        <FormControl size="small" className={classes.formControl}>
                                            <Select
                                                labelId="demo-simple-select-label-select"
                                                id="demo-simple-select-select"
                                                variant="outlined"
                                                value={estadoEntrega}
                                                onChange={estadoEntregaChange}
                                            >
                                                <MenuItem value="other">Selecciona un estado</MenuItem>
                                                {groups.groups && groups.groups.map((value, index) => {
                                                    return (
                                                        <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>

                                        {estadoEntrega && estadoEntrega == "20" ? (
                                            <>
                                                <TextField
                                                    autoFocus
                                                    value={deliveryPerson}
                                                    // error={nameError.length > 0}
                                                    variant="outlined"
                                                    margin="normal"
                                                    // required
                                                    fullWidth
                                                    id="personaentrego"
                                                    label="Persona que recibe"
                                                    name="personaentrego"
                                                    autoComplete="personaentrego"
                                                    onChange={(e) => checkDeliveryPerson(e.target.value)}
                                                // helperText={nameError && nameError}
                                                />
                                            </>
                                        ) : (<>

                                        </>)}

                                        <TextField
                                            value={commentsDelivery}
                                            // error={emailError.length > 0}
                                            variant="outlined"
                                            margin="normal"
                                            // required
                                            fullWidth
                                            id="comentarios"
                                            label="Comentarios"
                                            name="comentarios"
                                            autoComplete="comentarios"
                                            onChange={(e) => checkCommentsDelivery(e.target.value)}
                                            multiline
                                            rows={3}
                                        // helperText={emailError && emailError}
                                        /><br></br>

                                    </form>

                                </>
                            ) : (<>

                            </>)}

                            <br></br>
                            <Grid item xs={12} >
                                <h4 className={classes.maginZero}>Historial de cambios</h4>
                            </Grid>

                            {/* {productModal.shippingHistorical && productModal.shippingHistorical.map((value, index) => {
                                return (
                                    <>
                                        <Divider />

                                        <List dense={true} className={classes.demo}>
                                            <ListItem>
                                                <ListItemText
                                                    primary={
                                                        <Typography type="body2" style={
                                                            { color: '#000000' }
                                                        }><b>{value.consec + ". " + obtenerLeyendaStatus(value.shippingStatus)}</b></Typography>
                                                    }
                                                    secondary={
                                                        <Typography style={
                                                            { fontSize: 12 }
                                                        }>

                                                            {value.shippingStatus && value.shippingStatus == "20" ? (
                                                                <>
                                                                    <b>Recibió producto: </b>{value.deliveryPerson} <br></br>
                                                                </>
                                                            ) : (<>

                                                            </>)}

                                                            <b>Comentarios: </b>{value.commentsDelivery} <br></br>
                                                            <b>Fecha actualización: </b>{obtenerFechaConvertida(value.dateShippingUpdate._seconds)} <br></br>
                                                            <b>Actualizó estado: </b>{value.userStatusUpdate} <br></br>

                                                        </Typography>}
                                                />
                                            </ListItem>
                                        </List>

                                    </>
                                )
                            })} */}



                            <Timeline lineColor={'#9fc33b '}>

                                {productModal.shippingHistorical && productModal.shippingHistorical.map((value, index) => {

                                    let mainTitle = obtenerFechaConvertida(value.dateShippingUpdate._seconds);

                                    switch (value.shippingStatus) {
                                        case '10':
                                            return <>
                                                <TimelineItem
                                                    key={value.consec}
                                                    dateText={value.consec + ". " + "Por entregar"}
                                                    dateInnerStyle={{ background: '#61b8ff', color: '#000' }}
                                                    bodyContainerStyle={{
                                                        background: '#ddd',
                                                        padding: '20px',
                                                        borderRadius: '8px',
                                                        boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                                    }}
                                                >
                                                    <h3 style={{ color: '#61b8ff' }}>{mainTitle}</h3>
                                                    <h4 style={{ color: '#61b8ff' }}>Actualizó: {value.userStatusUpdateName}</h4>
                                                    <p>
                                                        <b>Comentarios: </b>{value.commentsDelivery}
                                                    </p>

                                                </TimelineItem>
                                            </>

                                        case '20':
                                            return <>
                                                <TimelineItem
                                                    key={value.consec}
                                                    dateText={value.consec + ". " + "Entregado"}
                                                    dateInnerStyle={{ background: '#76bb7f' }}
                                                >
                                                    <h3>{mainTitle}</h3>
                                                    <h4>Actualizó: {value.userStatusUpdateName}</h4>
                                                    <p>
                                                        <b>Recibió producto: </b>{value.deliveryPerson}
                                                    </p>
                                                    <p>
                                                        <b>Comentarios: </b>{value.commentsDelivery}
                                                    </p>

                                                </TimelineItem>

                                            </>

                                        case '30':
                                            return <>
                                                <TimelineItem
                                                    key={value.consec}
                                                    dateText={value.consec + ". " + "Rechazado"}
                                                    style={{ color: '#e86971' }}
                                                >
                                                    <h3>{mainTitle}</h3>
                                                    <h4>Actualizó: {value.userStatusUpdateName}</h4>
                                                    <p>
                                                        <b>Comentarios: </b>{value.commentsDelivery}
                                                    </p>

                                                </TimelineItem>

                                            </>
                                    }

                                })}

                            </Timeline>




                            {/* </Paper> */}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        {/* Cerrar */}
                        {editable ? "Cancelar" : "Cerrar"}
                    </Button>

                    {editable && editable ? (
                        <>

                            <Button onClick={saveShippingProduct} variant="contained" color="primary" autoFocus>
                                Actualizar
                            </Button>
                        </>
                    ) : (<>

                    </>)}



                </DialogActions>
            </Dialog>



        </>
    );
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default ShippingScreen;
