import {
    GET_GROUP_PURCHASES_RESET,
    GET_GROUP_PURCHASES_REQUEST,
    GET_GROUP_PURCHASES_SUCCESS,
    GET_GROUP_PURCHASES_FAIL,

    GET_STUDENT_GROUP_PURCHASES_RESET,
    GET_STUDENT_GROUP_PURCHASES_REQUEST,
    GET_STUDENT_GROUP_PURCHASES_SUCCESS,
    GET_STUDENT_GROUP_PURCHASES_FAIL,

} from "../constants/groupPurchasesConstants";

import {
    GROUP_ACTIONS_URL
} from '../constants/urlConstants';

const urlBase = GROUP_ACTIONS_URL;

const resetGetGroupPurchasesApi = () => (dispatch) => {
    dispatch({ type: GET_GROUP_PURCHASES_RESET });
}

const getGroupPurchasesApi = () => async (dispatch, getState) => {

    dispatch({ type: GET_GROUP_PURCHASES_REQUEST });
    const { userSignin: { userInfo } } = getState();

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    await fetch(
        urlBase +
        'user/groupPurchases'
        , {
            method: 'POST',
            body: JSON.stringify({
                id: userInfo._id
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(function (response) {
            if (response.ok) {
                ok = true;
            } else if (response.status === 400) {
                err400 = true;
            } else {
                // unknown = true;
            }
            return response.json();
        })
        .then(function (data) {
            // console.log('data = ', data);
            msg = data;
        })
        .catch(function (err) {
            console.error(err);
            // unknown = true;
        });

    if (ok) {
        dispatch({ type: GET_GROUP_PURCHASES_SUCCESS, payload: msg });
    } else if (err400) {
        dispatch({ type: GET_GROUP_PURCHASES_FAIL, payload: msg.errors });
    } else {
        alert("Ocurrió un error, intenta más tarde");
        dispatch({ type: GET_GROUP_PURCHASES_FAIL, payload: "Ocurrió un error, intenta más tarde" });
    }

}

const getStudentsGroupPurchasesApi = (
    group = 'all',
    iems = [{ total: 0, items: [] }]
) => async (dispatch, getState) => {

    dispatch({ type: GET_STUDENT_GROUP_PURCHASES_REQUEST, payload: iems });
    const { userSignin: { userInfo } } = getState();

    let ok = false;
    let msg = null;
    let err400 = false;
    let unknown = false;

    await fetch(
        urlBase +
        'user/studentsGroupPurchases/all?' +
        '&group=' + group 
        // + (userInfo.roles.student && userInfo.plans === 2 ? '&id=' + userInfo._id : '')
        , {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(function (response) {

            if (response.ok) {
                ok = true;
            } else if (response.status === 400) {
                err400 = true;
            } else {
                unknown = true;
            }

            return response.json();
        })
        .then(function (data) {
            // console.log('data = ', data);
            msg = data;
        })
        .catch(function (err) {
            console.error(err);
            unknown = true;
        });

    if (ok) {

        // if (msg.items.length > 0) {

        //     // Calcular la enumaracion
        //     let initialCount = ((msg.page - 1) * msg.per_page);

        //     msg.items.map(function (item, idx) {
        //         initialCount++;
        //         let obj = msg.categories.find(o => o.id === msg.items[idx].category);
        //         msg.items[idx].consec = initialCount;
        //         msg.items[idx].categoryTitle = obj ? obj.name : 'No definido';
        //     })

        // }

        dispatch({ type: GET_STUDENT_GROUP_PURCHASES_SUCCESS, payload: msg });

    } else if (err400) {
        dispatch({ type: GET_STUDENT_GROUP_PURCHASES_FAIL, payload: msg.errors });
    } else {
        dispatch({ type: GET_STUDENT_GROUP_PURCHASES_FAIL, payload: "Ocurrió un error, intenta más tarde" });
    }

};

export {
    resetGetGroupPurchasesApi,
    getGroupPurchasesApi,
    getStudentsGroupPurchasesApi
}