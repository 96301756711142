import {
    GET_STUDENT_WALLET_BALANCE_RESET,
    GET_STUDENT_WALLET_BALANCE_REQUEST,
    GET_STUDENT_WALLET_BALANCE_SUCCESS,
    GET_STUDENT_WALLET_BALANCE_FAIL,

    /* assignmentsByArea */
    GET_ASSIGNMENT_BY_AREA_RESET,
    GET_ASSIGNMENT_BY_AREA_REQUEST,
    GET_ASSIGNMENT_BY_AREA_SUCCESS,
    GET_ASSIGNMENT_BY_AREA_FAIL,

} from "../constants/groupPurchasesConstants";

import {
    GROUP_ACTIONS_URL
} from '../constants/urlConstants';

const urlBase = GROUP_ACTIONS_URL;

const resetGetStudentWalletBalanceApi = () => (dispatch) => {
    dispatch({ type: GET_STUDENT_WALLET_BALANCE_RESET });
}

const getStudentWalletBalanceApi = () => async (dispatch, getState) => {

    dispatch({ type: GET_STUDENT_WALLET_BALANCE_REQUEST });
    const { userSignin: { userInfo } } = getState();

    let id = null;
    if (userInfo.roles.teacher) {
        id = userInfo._id;
    }

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    await fetch(
        urlBase +
        'user/studentwalletbalance?' +
        '&id=' +
        id
        , {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(function (response) {
            if (response.ok) {
                ok = true;
            } else if (response.status === 400) {
                err400 = true;
            } else {
                // unknown = true;
            }
            return response.json();
        })
        .then(function (data) {
            // console.log('data = ', data);
            msg = data;
        })
        .catch(function (err) {
            console.error(err);
            // unknown = true;
        });

    if (ok) {
        dispatch({ type: GET_STUDENT_WALLET_BALANCE_SUCCESS, payload: msg });
    } else if (err400) {
        dispatch({ type: GET_STUDENT_WALLET_BALANCE_FAIL, payload: msg.errors });
    } else {
        alert("Ocurrió un error, intenta más tarde");
        dispatch({ type: GET_STUDENT_WALLET_BALANCE_FAIL, payload: "Ocurrió un error, intenta más tarde" });
    }

}


const getInsigniasTeacherApi = () => async (dispatch, getState) => {

    dispatch({ type: GET_STUDENT_WALLET_BALANCE_REQUEST });
    const { userSignin: { userInfo } } = getState();

    let id = null;
    if (userInfo.roles) {
        id = userInfo._id;
    }

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    await fetch(
        urlBase +
        'user/insigniasTeacherApi?' +
        '&id=' +
        id
        , {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(function (response) {
            if (response.ok) {
                ok = true;
            } else if (response.status === 400) {
                err400 = true;
            } else {
                // unknown = true;
            }
            return response.json();
        })
        .then(function (data) {
            // console.log('data = ', data);
            msg = data;
        })
        .catch(function (err) {
            console.error(err);
            // unknown = true;
        });

    if (ok) {
        dispatch({ type: GET_STUDENT_WALLET_BALANCE_SUCCESS, payload: msg });
    } else if (err400) {
        dispatch({ type: GET_STUDENT_WALLET_BALANCE_FAIL, payload: msg.errors });
    } else {
        alert("Ocurrió un error, intenta más tarde");
        dispatch({ type: GET_STUDENT_WALLET_BALANCE_FAIL, payload: "Ocurrió un error, intenta más tarde" });
    }

}

const getAssignmentsByAreaApi = () => async (dispatch, getState) => {

    dispatch({ type: GET_ASSIGNMENT_BY_AREA_REQUEST });
    const { userSignin: { userInfo } } = getState();

    let id = null;
    if (userInfo.roles) {
        id = userInfo._id;
    }

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    await fetch(
        urlBase +
        'user/assignmentsByArea?' +
        '&id=' +
        id
        , {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(function (response) {
            if (response.ok) {
                ok = true;
            } else if (response.status === 400) {
                err400 = true;
            } else {
                // unknown = true;
            }
            return response.json();
        })
        .then(function (data) {
            // console.log('data = ', data);
            msg = data;
        })
        .catch(function (err) {
            console.error(err);
            // unknown = true;
        });
    if (ok) {
        dispatch({ type: GET_ASSIGNMENT_BY_AREA_SUCCESS, payload: msg });
    } else if (err400) {
        dispatch({ type: GET_ASSIGNMENT_BY_AREA_FAIL, payload: msg.errors });
    } else {
        alert("Ocurrió un error, intenta más tarde");
        dispatch({ type: GET_ASSIGNMENT_BY_AREA_FAIL, payload: "Ocurrió un error, intenta más tarde" });
    }
}

export {
    resetGetStudentWalletBalanceApi,
    getStudentWalletBalanceApi,
    getInsigniasTeacherApi,
    getAssignmentsByAreaApi
}