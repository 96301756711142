import React from 'react';
import Carousel from "react-material-ui-carousel"
import autoBind from "auto-bind"
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import '../style//BannerSchools.scss';

import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Grid,
} from '@material-ui/core';

function Banner(props) {
    if (props.newProp) console.log(props.newProp)
    const contentPosition = props.contentPosition ? props.contentPosition : "left"
    const totalItems = props.length ? props.length : 4;
    const mediaLength = totalItems;

    const useStyles = makeStyles({
        root: {
            maxWidth: 345,
        },
        mediaComp: {
            height: 110,
        },
    });

    const classes = useStyles();

    let items = [];
    const content = (
        <Grid item xs={12 / totalItems} key="content" className={classes.mediaComp}>
            <CardContent className="Content">
                <Typography className="Title">
                    {props.item.Name}
                </Typography>

                <Typography className="Caption">
                    {props.item.Caption}
                </Typography>

                {/* <Button variant="outlined" className="ViewButton">
                    View Now
                </Button> */}
            </CardContent>
        </Grid>
    )


    for (let i = 0; i < mediaLength; i++) {
        const item = props.item.Items[i];

        const media = (
            <Grid item xs={12 / totalItems} key={item.Name}>
                <Link href="https://www.google.com.mx" target="_blank"  >
                    <CardMedia
                        style={{ display: 'flex', justifyContent: 'center' }}

                        // image={item.Image}
                        title={item.Name}
                    >
                        <img src={item.Image} height="120" />

                        {/* <Typography className="MediaCaption">
                        {item.Name}
                    </Typography> */}
                    </CardMedia>
                </Link>
            </Grid>
        )

        items.push(media);
    }

    // if (contentPosition === "left") {
    //     items.unshift(content);
    // } else if (contentPosition === "right") {
    //     items.push(content);
    // } else if (contentPosition === "middle") {
    //     items.splice(items.length / 2, 0, content);
    // }

    return (
        <Card raised className="Banner">
            <Grid container spacing={0} className="BannerGrid">
                {items}
            </Grid>
        </Card>
    )
}

const items = [
    {
        Name: "Escuelas",
        Caption: "",
        contentPosition: "left",
        Items: [
            {
                Name: "e1",
                Image: "https://osmarperez.com/imag/01.jpg"
            },
            {
                Name: "e2",
                Image: "https://osmarperez.com/imag/15.jpg"
            },
            {
                Name: "e3",
                Image: "https://osmarperez.com/imag/02.jpg"
            },
            {
                Name: "e4",
                Image: "https://osmarperez.com/imag/04.jpg"
            }
        ]
    },
    {
        Name: "Escuelas",
        Caption: "",
        contentPosition: "middle",
        Items: [
            {
                Name: "e5",
                Image: "https://osmarperez.com/imag/05.jpg"
            },
            {
                Name: "e6",
                Image: "https://osmarperez.com/imag/06.jpg"
            },
            {
                Name: "e7",
                Image: "https://osmarperez.com/imag/07.jpg"
            },
            {
                Name: "e8",
                Image: "https://osmarperez.com/imag/08.jpg"
            }
        ]
    },
    {
        Name: "Escuelas",
        Caption: "",
        contentPosition: "right",
        Items: [
            {
                Name: "e9",
                Image: "https://osmarperez.com/imag/09.jpg"
            },
            {
                Name: "e10",
                Image: "https://osmarperez.com/imag/10.jpg"
            },
            {
                Name: "e11",
                Image: "https://osmarperez.com/imag/11.jpg"
            },
            {
                Name: "e12",
                Image: "https://osmarperez.com/imag/12.jpg"
            }
        ]
    }
]

class BannerExample extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            autoPlay: true,
            timer: 5000,
            animation: "fade",
            indicators: false,
            timeout: 500,
            navButtonsAlwaysVisible: true,
            navButtonsAlwaysInvisible: false
        }

        autoBind(this);
    }

    toggleAutoPlay() {
        this.setState({
            autoPlay: !this.state.autoPlay
        })
    }

    toggleIndicators() {
        this.setState({
            indicators: !this.state.indicators
        })
    }

    toggleNavButtonsAlwaysVisible() {
        this.setState({
            navButtonsAlwaysVisible: !this.state.navButtonsAlwaysVisible
        })
    }

    toggleNavButtonsAlwaysInvisible() {
        this.setState({
            navButtonsAlwaysInvisible: !this.state.navButtonsAlwaysInvisible
        })
    }

    changeAnimation(event) {
        this.setState({
            animation: event.target.value
        })
    }

    changeTimeout(event, value) {
        this.setState({
            timeout: value
        })
    }

    render() {
        return (
            <div style={{ marginTop: "30px", marginBottom: "30px", color: "#494949" }}>
                {/* <h2>Example: eBay&trade; style</h2> */}

                <Carousel
                    className="Example"
                    autoPlay={this.state.autoPlay}
                    timer={this.state.timer}
                    animation={this.state.animation}
                    indicators={this.state.indicators}
                    timeout={this.state.timeout}
                    navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}
                    navButtonsAlwaysInvisible={this.state.navButtonsAlwaysInvisible}
                >
                    {
                        items.map((item, index) => {
                            return <Banner item={item} key={index} contentPosition={item.contentPosition} />
                        })
                    }
                </Carousel>


                {/* <FormLabel component="legend">Options</FormLabel>
                <FormControlLabel
                    control={
                        <Checkbox onChange={this.toggleAutoPlay} checked={this.state.autoPlay} value="autoplay"
                            color="primary" />
                    }
                    label="Auto-play"
                />
                <FormControlLabel
                    control={
                        <Checkbox onChange={this.toggleIndicators} checked={this.state.indicators} value="indicators"
                            color="primary" />
                    }
                    label="Indicators"
                />
                <FormControlLabel
                    control={
                        <Checkbox onChange={this.toggleNavButtonsAlwaysVisible} checked={this.state.navButtonsAlwaysVisible} value="indicators" color="primary" />
                    }
                    label="NavButtonsAlwaysVisible"
                />

                <FormControlLabel
                    control={
                        <Checkbox onChange={this.toggleNavButtonsAlwaysInvisible} checked={this.state.toggleNavButtonsAlwaysInvisible} value="indicators" color="primary" />
                    }
                    label="NavButtonsAlwaysInvisible"
                />

                <FormControlLabel
                    control={
                        <RadioGroup name="animation" value={this.state.animation} onChange={this.changeAnimation} row
                            style={{ marginLeft: "10px" }}>
                            <FormControlLabel value="fade" control={<Radio color="primary" />} label="Fade" />
                            <FormControlLabel value="slide" control={<Radio color="primary" />} label="Slide" />
                        </RadioGroup>
                    }
                />

                <FormControlLabel
                    control={
                        <div style={{ width: 300 }}>
                            <Typography id="discrete-slider" gutterBottom>
                                Animation Duration (Timeout) in ms
                            </Typography>
                            <Slider
                                defaultValue={500}
                                getAriaValueText={() => `${this.state.timeout}ms`}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={100}
                                marks
                                min={100}
                                max={2000}
                                onChange={this.changeTimeout}
                            />
                        </div>
                    }
                /> */}

            </div>

        )
    }
}

export default BannerExample;