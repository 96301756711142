import {
  ENABLE_USERS_LIST_REQUEST,
  ENABLE_USERS_LIST_SUCCESS,
  ENABLE_USERS_LIST_FAIL,

  ENABLE_USERS_UPDATE_REQUEST,
  ENABLE_USERS_UPDATE_SUCCESS,
  ENABLE_USERS_UPDATE_FAIL,

  ENABLE_USER_DETAL_SUCCESS,
} from '../constants/enableUsersConstants';

import {
  GROUP_ACTIONS_URL,
  MAILING_URL
} from '../constants/urlConstants';

const urlBase = GROUP_ACTIONS_URL;
const emailUrl = MAILING_URL;

const listEnableUsers = (
  category = '',
  searchKeyword = '',
  sortOrder = ''
) => async (dispatch, getState) => {

  dispatch({ type: ENABLE_USERS_LIST_REQUEST });
  const { userSignin: { userInfo } } = getState();

  let ok = false;
  let msg = null;
  let err400 = false;
  // let unknown = false;

  await fetch(
    urlBase +
    'user/enable/all/'
    , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {
      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        // unknown = true;
      }
      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

  if (ok) {
    dispatch({ type: ENABLE_USERS_LIST_SUCCESS, payload: msg });
  } else if (err400) {
    dispatch({ type: ENABLE_USERS_LIST_FAIL, payload: msg.errors });
  } else {
    dispatch({ type: ENABLE_USERS_LIST_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

};




const updateEnableUsers = (checked) => async (dispatch, getState) => {

  dispatch({ type: ENABLE_USERS_UPDATE_REQUEST });
  const { userSignin: { userInfo } } = getState();

  let ok = false;
  let msg = null;
  let err400 = false;
  // let unknown = false;

  await fetch(
    urlBase +
    'user/enable/all/'
    , {
      method: 'PUT',
      body: JSON.stringify(checked),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {
      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        // unknown = true;
      }
      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;


    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

  if (ok) {
    dispatch({ type: ENABLE_USERS_UPDATE_SUCCESS, payload: msg });
  } else if (err400) {
    dispatch({ type: ENABLE_USERS_UPDATE_FAIL, payload: msg.errors });
  } else {
    dispatch({ type: ENABLE_USERS_UPDATE_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

}


const showUserInfo = (user) => (dispatch) => {
  dispatch({ type: ENABLE_USER_DETAL_SUCCESS, payload: user });
};


export {
  listEnableUsers,
  updateEnableUsers,
  showUserInfo
};
