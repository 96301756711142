import React, { useEffect, useState, useRef } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { register, registerReset } from '../actions/userActions';

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { deepOrange, green } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import BannerSchools from '../components/BannerSchools';
import BannerPartners from '../components/BannerPartners';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {/* <Link color="inherit" href="https://material-ui.com/"> */}
      <Link color="inherit" component={LinkRouter} to={'/'}>
        School Market
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function RegisterScreen(props) {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [nickname, setNickname] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [isCompany, setIsCompany] = useState(false);
  const [userPlan, setUserPlan] = useState("1");


  // Start Set errors
  const [nameError, setNameError] = useState('');
  const [nicknameError, setNicknameError] = useState('');
  const [rePasswordError, setRePasswordError] = useState('');



  // End set error

  const [firstTime, setFirstTime] = useState(true);


  const [emailError, setEmailError] = useState('');

  const userRegister = useSelector(state => state.userRegister);
  const { loading, success: userSuccess, error } = userRegister;
  const dispatch = useDispatch();

  const handleIsCompanyClick = () => setIsCompany(!isCompany);
  // const handleUserTypeClick = () => setUserType()

  let textInput = useRef(null);

  const redirect = props.location.search ? props.location.search.split("=")[1] : '/success';


  useEffect(() => {
    return () => {
      //
      dispatch(registerReset());
    };
  }, []);


  useEffect(() => {
    if (userSuccess) {
      props.history.push(redirect);
    }
    return () => {
      //
    };
  }, [userRegister]);


  useEffect(() => {

    if (!firstTime) {
      if (error) {
        setOpen(true);
      }
    }

    setFirstTime(false);

    return () => {
      //
    };
  }, [error]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(register(name, email, nickname, password, isCompany, userPlan, rePassword));
  }

  const useStyles = makeStyles((theme) => ({
    paper: {
      // marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    square: {
      // padding: theme.spacing(2),
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: "#fafafa",
      width: 80,
      height: 80,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(0),
      position: 'relative',
    },
  }));

  const classes = useStyles();

  const checkName = (v) => {

    if (!v || v.trim().length == 0) {
      setNameError('El nombre no debe estar vacío');
    } else {
      setNameError("");
    }

    setName(v)
  }

  const checkEmail = (v) => {

    if (/\s/g.test(v) || v.length == 0) {
      setEmailError('Este campo no deb estar vacío');
    } else {
      setEmailError("");
    }

    // if (/\s/g.test(v) || v.length == 0 ) {
    //   setEmailError('Debe proporcionar un correo electrónico');
    // }
    // else if (!EmailValidator.validate(v)) {
    //   setEmailError("Correo electrónico no válido");
    // } else {
    //   setEmailError("");
    // }

    setEmail(v);

  }

  const checkNickname = (v) => {

    if (/\s/g.test(v) || v.length == 0) {
      setNicknameError('El Alias no deb estar vacío');
    } else {
      setNicknameError("");
    }

    setNickname(v)

  }

  const checkPassword = (v) => {
    if (/\s/g.test(v) || v.length == 0) {
      setPasswordError('La contraseña es requerida');
    } else {
      setPasswordError("");
    }
    setPassword(v);
  }

  const checkRePassword = (v) => {
    if (/\s/g.test(v) || v.length == 0) {
      setRePasswordError('La confirmación de contraseña es requerida');
    } else {
      setRePasswordError("");
    }
    setRePassword(v);
  }


  const handleChange = (event) => {
    setUserPlan(event.target.value);
  };


  const [open, setOpen] = useState(false);


  const handleClose = () => {
    // setEmail('');
    setPassword('');
    setRePassword('');
    setOpen(false);

    setTimeout(() => {
      textInput.current.focus();
    }, 100);

  };


  return (

    <div>

      {/* <BannerSchools /> */}
      <div id="cft"  >
        <img style={{ width: 'calc(100%)' }} className="bottom" src="/images/Banner2.jpg" />
        <img style={{ width: 'calc(100%)' }} className="top" src="/images/Banner1.jpg" />
      </div>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Registro
          </Typography>

          
          <form className={classes.form} noValidate onSubmit={submitHandler}>
            <TextField
              inputRef={textInput}
              value={name}
              error={nameError.length > 0}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nombre completo"
              name="name"
              autoComplete="name"
              autoFocus
              onChange={(e) => checkName(e.target.value)}
              disabled={loading}
              helperText={nameError && nameError}
            />
            <TextField
              value={email}
              error={emailError.length > 0}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo electrónico"
              name="email"
              autoComplete="email"
              onChange={(e) => checkEmail(e.target.value)}
              disabled={loading}
              helperText={emailError && emailError}
            />
            <TextField
              value={nickname}
              error={nicknameError.length > 0}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="nickname"
              label="Alias"
              name="nickname"
              autoComplete="nickname"
              onChange={(e) => checkNickname(e.target.value)}
              disabled={loading}
              helperText={nicknameError && nicknameError}
            />
            <TextField
              value={password}
              error={passwordError.length > 0}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => checkPassword(e.target.value)}
              disabled={loading}
              helperText={passwordError && passwordError}
            />


            <TextField
              value={rePassword}
              error={rePasswordError.length > 0}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="rePassword"
              label="Confirmar contraseña"
              type="password"
              id="rePassword"
              autoComplete="re-password"
              onChange={(e) => checkRePassword(e.target.value)}
              disabled={loading}
              helperText={rePasswordError && rePasswordError}
            />

            <FormControl component="fieldset">
              <FormLabel component="legend">Tipo de usuario</FormLabel>
              <RadioGroup aria-label="gender" name="gender1" value={userPlan} onChange={handleChange} >
                <FormControlLabel value="1" control={<Radio />} label="Básico" disabled={isCompany} />
                <FormControlLabel value="2" control={<Radio />} label="Premium" disabled={isCompany} />
              </RadioGroup>
            </FormControl>
            <FormControlLabel
              control={<Checkbox checked={isCompany} onChange={handleIsCompanyClick} name="isCompany" />}
              label="Soy Empresa, requiero información"
            />

            
            <div className={classes.wrapper}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading || !password || !email || emailError.length > 0}
              >
                Aceptar
                </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
            <Grid container>
              <Grid item xs>
                <Link component={LinkRouter} to={'/recovery'} variant="body2">
                  Recuperar contraseña
                </Link>
              </Grid>
               
              <Button
                component={LinkRouter} to={'/'}
                fullWidth
                variant="contained"
                className={classes.submit}
                disabled={loading}
              >
                Cancelar
                </Button>
            </Grid>
          </form>
        </div>

      </Container>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Error!"}</DialogTitle>
        <DialogContent>
           
          <div>
            {error && error.map(function (d, idx) {
              return (<li>{d.msg}</li>)
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>


      {/* <BannerPartners /> */}
      <div id="cfb"  >
        <img style={{ width: 'calc(100%)' }} className="topb" src="/images/Banner1.jpg" />
        <img style={{ width: 'calc(100%)' }} className="bottomb" src="/images/Banner2.jpg" />
      </div>

      {/* <Box mt={8}>
        <Copyright />
      </Box> */}

    </div>)
}
export default RegisterScreen;