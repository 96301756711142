import fetchIntercept from 'fetch-intercept';
import Cookie from 'js-cookie';
import moment from 'moment'
import {
    SCHOOL_DEPLOY
} from '../constants/urlConstants';


const unregister = fetchIntercept.register({


    request: function (url, config) {

        // Demo Escolar = "7shgOc2d14IihxlF3yrC"
        // S. Pedro = "Lgu4tw2HzlmcRQJDS7ZK"
        config.headers.schoolcoode = SCHOOL_DEPLOY;

        // Obtener los datos del local Storage
        // const userInfo = Cookie.getJSON('userInfo') || null;
        // console.log("userInfo desde interceptor")
        // console.log(userInfo)

        // console.log("url"); 
        // console.log(url); 
        // console.log("headers"); 
        
        // console.log(config.headers); 

        // let unixTimestamp = moment('2012.08.10', 'YYYY.MM.DD').unix();

        // console.log("moment().unix()");
        // console.log(moment().unix());

        // if (userInfo) {
        //     if (userInfo.exp > (moment().unix() + 600)) {
        //         // Todo Ok
        //     } else {
        //         // Actualizar el token
        //         //  fetch(
        //         //     'https://securetoken.googleapis.com/v1/token?key=AIzaSyDugMM5kABP24Ut1nuwx5tUA-eRlGcFIxM'
        //         //     , {
        //         //         method: 'POST',
        //         //         body: JSON.stringify({ grant_type: "refresh_token", refresh_token: userInfo.refresh_token }),
        //         //         headers: {
        //         //             'Accept': 'application/json',
        //         //             'Content-Type': 'application/x-www-form-urlencoded'
        //         //         }
        //         //     })
        //         //     .then(function (response) {
        //         //         if (response.ok) {
        //         //             // ok = true;
        //         //         } else if (response.status === 400) {
        //         //             // err400 = true;
        //         //         } else {
        //         //             // unknown = true;
        //         //         }
        //         //         return response.json();
        //         //     })
        //         //     .then(function (data) {
        //         //         console.log('token data = ', data);
        //         //     })
        //         //     .catch(function (err) {
        //         //         console.error(err);
        //         //         // unknown = true;
        //         //     });
        //     }
        // }


        // console.log("Haciendo la petición:");
        // Modify the url or config here

        // console.log("url");
        // console.log(url);

        // console.log("config");
        // console.log(config);


        return [url, config];
    },

    requestError: function (error) {
        // console.log("Petición erronea:");

        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },

    response: function (response) {
        // console.log("Obteniendo la respuesta:");
        // console.log(response);

        // Modify the reponse object


        if (response.status === 401) {
            // Actualizar el token.

        }

        // console.log("------------------------------------------------");

        return response;
    },

    responseError: function (error) {
        // console.log("Error?");

        // Handle an fetch error
        return Promise.reject(error);
    }
});

export default unregister;