export const GET_BUY_CREDIT_LIST_RESET = 'GET_BUY_CREDIT_LIST_RESET';
export const GET_BUY_CREDIT_LIST_REQUEST = 'GET_BUY_CREDIT_LIST_REQUEST';
export const GET_BUY_CREDIT_LIST_SUCCESS = 'GET_BUY_CREDIT_LIST_SUCCESS';
export const GET_BUY_CREDIT_LIST_FAIL = 'GET_BUY_CREDIT_LIST_FAIL';
export const UPDATE_BUY_CREDIT_RESET = 'UPDATE_BUY_CREDIT_RESET';
export const UPDATE_BUY_CREDIT_REQUEST = 'UPDATE_BUY_CREDIT_REQUEST';
export const UPDATE_BUY_CREDIT_SUCCESS = 'UPDATE_BUY_CREDIT_SUCCESS';
export const UPDATE_BUY_CREDIT_FAIL = 'UPDATE_BUY_CREDIT_FAIL';
export const GET_JUSTIFICATIONS_LIST_RESET = 'GET_JUSTIFICATIONS_LIST_RESET';
export const GET_JUSTIFICATIONS_LIST_REQUEST = 'GET_JUSTIFICATIONS_LIST_REQUEST';
export const GET_JUSTIFICATIONS_LIST_SUCCESS = 'GET_JUSTIFICATIONS_LIST_SUCCESS';
export const GET_JUSTIFICATIONS_LIST_FAIL = 'GET_JUSTIFICATIONS_LIST_FAIL';


export const SAVE_JUSTIFICATION_RESET = 'SAVE_JUSTIFICATION_RESET';
export const SAVE_JUSTIFICATION_REQUEST = 'SAVE_JUSTIFICATION_REQUEST';
export const SAVE_JUSTIFICATION_SUCCESS = 'SAVE_JUSTIFICATION_SUCCESS';
export const SAVE_JUSTIFICATION_FAIL = 'SAVE_JUSTIFICATION_FAIL';


