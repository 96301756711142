import React, { useEffect, useState } from 'react';

import dateFormat from 'dateformat';

import {
    addToCart,
    removeFromCart,
    addToCartFrom,
    checkBalanceApi,
    resetCheckBalanceApi,
    removeAllProductsFromCart,
    buyProductDone,
    buyProductReset
} from '../actions/cartActions';


import {
    getOrdersApi,
    resetOrdersApi,
    addCommentApi,
    resetAddCommentApi
} from '../actions/userOrdersActions'

import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { red } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Divider from '@material-ui/core/Divider';
import Rating from '@material-ui/lab/Rating';
import TextField from '@material-ui/core/TextField';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import Box from '@material-ui/core/Box';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon />,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon />,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon />,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon />,
        label: 'Very Satisfied',
    },
};




function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

function OrdersScreen(props) {

    const themess = useTheme();
    const mediumSize = useMediaQuery(themess.breakpoints.up('md'));

    const [showBackdrop, setShowBackdrop] = useState(false);

    const userOrders = useSelector(state => state.userOrders);
    const { loading, error, success, items: items2 } = userOrders;

    const [productModal, setProductModal] = useState({});
    const [open, setOpen] = useState(false);

    const addProductComment = useSelector(state => state.addProductComment);
    const { loading: loadingAdd, error: errorAdd, success: successAdd } = addProductComment;

    const [items, setItems] = useState([]);
    const [savingsCategory, setSavingsCategory] = useState("");


    const [showConfirmationModal, setShowConfirmationModal] = useState(false);


    const [titleCalification, setTitleCalification] = useState('');
    const [titleCalificationError, setTitleCalificationError] = useState('');

    const [commentCalification, setCommentCalification] = useState('');
    const [commentCalificationError, setCommentCalificationError] = useState('');

    const [ratingCalification, setRatingCalification] = useState(-1);
    const [ratingCalificationError, setRatingCalificationError] = useState('');


    const [orderId, setOrderId] = useState('');
    const [productId, setProductId] = useState('');


    const [hover, setHover] = useState(-1);

    const useStyles = makeStyles((theme) => ({

        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
            backgroundColor: '#f5f5f5',
            height: 'calc(100vh - 64px)',
        },
        paddingLine: {
            paddingTop: theme.spacing(1),
        },
        paper: {
            padding: theme.spacing(2),
            // textAlign: 'right',
            color: theme.palette.text.secondary,
        },
        listing: {
            width: '100%',
            justifyContent: 'center',
            maxWidth: 350,
            backgroundColor: theme.palette.background.paper,
        },
        bgTotalColor: {
            backgroundColor: '#f5f5f5',

        },
        backdrop: {

            zIndex: 1500,
            // zIndex: theme.zIndex. + 1,
            color: '#fff',
        },
        alignCenter: {
            textAlign: 'center',
        },
        cardRoot: {
            // maxWidth: 345,
        },
        avatar: {
            backgroundColor: red[500],
        },
        media: {
            // height: 0,
            // paddingTop: '56.25%', // 16:9
        },
        alignRigth: {
            textAlign: 'right',
        },
        listing: {
            width: '100%',
            justifyContent: 'center',
            maxWidth: 350,
            boxShadow: "none"
            // backgroundColor: theme.palette.background.paper,
        },
        bgTotalColor: {
            // backgroundColor: '#f5f5f5',
            boxShadow: "none",
        },
        paBtn: {
            borderColor: 'white'
        },
        alignSameLine: {
            display: 'flex',
            // alignItems: 'center',
        },
        errorColor: {
            color: '#f44336',
            fontSize: '0.75rem'
        },
        maginZero: {
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(0),
            backgroundColor: '#000000',
            color: 'white',
            paddingLeft: '4px'

        },
    }));

    const classes = useStyles();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOrdersApi());
        return () => {
            dispatch(resetOrdersApi());
            dispatch(resetAddCommentApi());
        };
    }, []);


    useEffect(() => {
        //
        if (success) {
            setItems(items2.orders);
            setSavingsCategory(items2.savingsCategory);
            //    
        }

        // const { loading, error, success, items: items2 } = userOrders;

        return () => {
            //    
        };
    }, [userOrders]);

    useEffect(() => {
        //
        if (successAdd) {
            setShowBackdrop(false);
            setShowConfirmationModal(false);
            dispatch(getOrdersApi());
        }

        if (errorAdd) {
            setShowBackdrop(false);
            errorAdd.forEach(
                function myFunction(item, index) {
                    switch (item.param) {
                        case "titleCalification":
                            setTitleCalificationError(item.msg);
                            break;
                        case "commentCalification":
                            setCommentCalificationError(item.msg);
                            break;
                        case 'ratingCalification':
                            setRatingCalificationError(item.msg);
                            break;
                        default:
                        // code block
                    }
                });
        }

        return () => {
            //    
        };
    }, [addProductComment]);


    const handleCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
        setTitleCalificationError('');
        setCommentCalificationError('');
        setRatingCalificationError('');
    };

    const obtenerFechaConvertida = (fecha) => {
        let utcSeconds = fecha;
        let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds);
        return dateFormat(d, 'd"/"mm"/"yyyy  " "h:MM:ss TT');
    }


    const handleAddComment = (orderId, product) => () => {
        setOrderId(orderId);
        setProductId(product.id);
        setTitleCalification(product.titleComments);
        setCommentCalification(product.comments);
        setRatingCalification(product.rating);
        setShowConfirmationModal(true);
    };


    const setCalificationHandler = () => {

        setShowBackdrop(true);
        let isValid = false;

        let titleCal = titleCalification;
        let commentCal = commentCalification;
        let calif = ratingCalification;

        if (!titleCal || titleCal.length === 0) {
            setTitleCalificationError('Este campo no debe estar vacío');
        }

        if (commentCal.length === 0) {
            setCommentCalificationError('Este campo no debe estar vacío');
        }

        if (calif < 1) {
            setRatingCalificationError('Debe asignar una calificación')
        }

        if (!titleCal || titleCal.length === 0
            || commentCal.length === 0
            || calif < 1) {
            isValid = false;
        } else {
            isValid = true;
        }

        if (isValid) {
            dispatch(addCommentApi(titleCalification, commentCalification, ratingCalification, orderId, productId));
        } else {
            setShowBackdrop(false);
        }

    }


    const handleOpenDialog = (item) => () => {

        let type = "read";


        if (item.shippingHistorical) {

            item.shippingHistorical.reverse();
            let consec = item.shippingHistorical.length;

            for (let idx in item.shippingHistorical) {
                item.shippingHistorical[idx].consec = consec; 
                consec--; 
            }

            setProductModal(item);
        }


        setOpen(true);

    };


    const titleCalificationHandler = (v) => {
        if (v.length === 0) {
            setTitleCalificationError('Este campo no deb estar vacío');
        } else {
            setTitleCalificationError("");
        }
        setTitleCalification(v);
    }

    const commentCalificationHandler = (v) => {
        if (v.length === 0) {
            setCommentCalificationError('Este campo no deb estar vacío');
        } else {
            setCommentCalificationError("");
        }
        setCommentCalification(v);
    }


    const labels = {
        0.5: 'Useless',
        1: 'Malo',
        1.5: 'Poor',
        2: 'Regular',
        2.5: 'Ok',
        3: 'Bien',
        3.5: 'Good',
        4: 'Muy bien',
        4.5: 'Excellent',
        5: 'Excelente!',
    };


    const handleClose = () => {

        // setEstadoEntrega("other");
        // setProductModal({});
        // setDeliveryPerson("");
        // setCommentsDelivery("");
        // setTicketId("");
        // setProductId("");
        setProductModal({});

        setOpen(false);
    };

    const obtenerLeyendaStatus = (estado) => {
        // * shippingStatus: Estados de la entrega:
        // *      10 Por entregar
        // *      20 Entregado
        // *      30 Rechazado
        // *      40 Todos

        let statusLegend = "";

        switch (estado) {
            case '10':
                statusLegend = "Por entregar";
                break;
            case '20':
                statusLegend = "Entregado";
                break;
            case '30':
                statusLegend = "Rechazado";
                break;
            default:
                statusLegend = "desconocido";
        }

        return statusLegend;

    }

    return (
        <>
            <div className={classes.root}>

                <h1>Mis Compras</h1>

                <Grid container spacing={2}>


                    {items && items.length > 0 ? (
                        <></>
                    ) : (<>
                        <Grid item sm={12} md={12}>
                            <Paper className={classes.paper}>
                                <div className={classes.alignCenter}>
                                    <h2>No existen compras</h2>
                                </div>
                            </Paper>
                        </Grid>
                    </>)}

                    {items ? items.map(function (row, idx) {
                        return (
                            <>
                                <Grid item sm={12} md={12}>
                                    <Card className={classes.cardRoot} key={idx}>
                                        <CardHeader
                                            // title=""

                                            subheader={<b>{obtenerFechaConvertida(row.timestamp._seconds)}</b>}
                                        />
                                        <CardMedia
                                            className={classes.media}
                                            image="/static/images/cards/paella.jpg"
                                            title="Paella dish"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">

                                            </Typography>
                                            <Grid container alignItems="center" spacing={2}>

                                                {row ? row.items.map(function (product, idxProduct) {
                                                    return (
                                                        <>
                                                            <Grid className={classes.paddingLine} alignItems="center" sm={12}>
                                                            </Grid>
                                                            <Grid item sm={12} md={2} key={idxProduct}>
                                                                <img height="50" src={product.image} alt={"ddd"} />
                                                            </Grid>
                                                            <Grid item sm={12} md={4}>

                                                                {savingsCategory && product.category == savingsCategory ? (
                                                                    <>
                                                                        {product.title}
                                                                    </>
                                                                ) : (<>
                                                                    {product.title}
                                                                    <b>

                                                                        {/* <Button
                                                                            startIcon={columnFilter === 'category' && isAscending ? <ArrowUpwardIcon /> : columnFilter === 'category' && !isAscending ? <ArrowDownwardIcon /> : null}
                                                                            size="small"
                                                                            // onClick={handleChangeFilter('teacher')}
                                                                            className={classes.btnHeader}>
                                                                            Ticket
                                                                        </Button> */}

                                                                        <Button
                                                                            size="small"
                                                                            onClick={handleOpenDialog(product)}
                                                                            className={classes.btnHeader}>
                                                                            <b>({product.shippingStatus ? obtenerLeyendaStatus(product.shippingStatus) : "Por entregar"})</b>
                                                                        </Button>

                                                                    </b>
                                                                </>)}

                                                            </Grid>
                                                            <Grid item sm={12} md={2}>
                                                                <NumberFormat value={parseFloat(product.price).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'\u20BF '} renderText={value => <div>{value}</div>} />
                                                            </Grid>
                                                            <Grid item sm={12} md={2}>
                                                                X {product.qty}
                                                            </Grid>
                                                            <Grid item sm={12} md={2}>
                                                                <NumberFormat value={parseFloat(product.price * product.qty).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'\u20BF '} renderText={value => <div>{value}</div>} />
                                                            </Grid>

                                                            <Grid item sm={12} md={6}>
                                                                <b>{product.titleComments}</b>{product.comments === '' ? '(Sin Comentarios) ' : ': ' + product.comments}
                                                            </Grid>
                                                            <Grid item sm={12} md={3}>
                                                                {/* <Rating
                                                                    disabled
                                                                    name="simple-controlled"
                                                                    // value={5}
                                                                    value={product.rating}
                                                                /> */}

                                                                <Rating
                                                                    disabled
                                                                    name="customized-icons"
                                                                    defaultValue={product.rating}
                                                                    getLabelText={(value) => customIcons[value].label}
                                                                    IconContainerComponent={IconContainer}
                                                                />


                                                            </Grid>
                                                            <Grid item sm={12} md={3}>
                                                                <Button
                                                                    variant="contained"
                                                                    size="small"
                                                                    color="primary"
                                                                    onClick={handleAddComment(row.id, product)}
                                                                    style={{
                                                                        borderRadius: 0,
                                                                        // borderBottom: 5,
                                                                        // borderBottomColor: 'solid red'
                                                                    }}
                                                                >
                                                                    Comentar/Calificar
                                                                </Button>
                                                            </Grid>
                                                            <Grid sm={12}>
                                                                <Divider />
                                                            </Grid>
                                                        </>
                                                    )
                                                }
                                                ) : (<></>)}


                                            </Grid>
                                            {/* <div className={classes.alignRigth}>
                                                <h3>Total $ {row.total}</h3>
                                            </div> */}

                                        </CardContent>

                                        <Grid
                                            container
                                            direction="row"
                                            justify={mediumSize ? 'flex-end' : 'flex-start'}   //"flex-end"
                                            alignItems="flex-start"

                                        >

                                            <TableContainer component={Paper} className={classes.listing}>
                                                <br></br>
                                                <Table size="small" className={classes.bgTotalColor} aria-label="simple table">
                                                    <TableBody >
                                                        <TableRow  >
                                                            <TableCell className={classes.paBtn}><b>Total</b></TableCell>
                                                            <TableCell className={classes.paBtn}><b><NumberFormat value={parseFloat(row.total).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'\u20BF '} renderText={value => <div>{value}</div>} /></b></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                                <br></br>

                                            </TableContainer>
                                        </Grid>

                                        {/* <CardActions disableSpacing className={classes.alignRigth}>
                                            <h3>Total &#8383; {row.total}</h3>
                                        </CardActions> */}

                                    </Card>
                                </Grid>
                            </>
                        )
                    }) : (
                        <>
                        </>
                    )}



                </Grid>


                <div id="cfb"  >
                    <img style={{ width: 'calc(100%)' }} className="topb" src="/images/Banner1.jpg" />
                    <img style={{ width: 'calc(100%)' }} className="bottomb" src="/images/Banner2.jpg" />
                </div>

            </div>

            <Backdrop className={classes.backdrop} open={showBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog
                // disableBackdropClick
                disableEscapeKeyDown
                open={showConfirmationModal}
                onClose={handleCloseConfirmationModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Comentario y Calificación</DialogTitle>
                <DialogContent>

                    <TextField
                        value={titleCalification}
                        error={titleCalificationError.length > 0}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="titulo"
                        label="Título"
                        type="text"
                        id="titulo"
                        // autoComplete="email"
                        onChange={(e) => titleCalificationHandler(e.target.value)}
                        helperText={titleCalificationError && titleCalificationError}
                    />

                    <TextField
                        value={commentCalification}
                        error={commentCalificationError.length > 0}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="comentario"
                        label="Comentario"
                        type="text"
                        id="comentario"
                        multiline
                        rows={4}
                        // autoComplete="email"
                        onChange={(e) => commentCalificationHandler(e.target.value)}
                        helperText={commentCalificationError && commentCalificationError}
                    />
                    <h4>Calificación</h4>

                    <div className={classes.alignSameLine}>
                        <Rating
                            size="large"
                            name="customized-icons-set"
                            defaultValue={ratingCalification}
                            getLabelText={(value) => customIcons[value].label}
                            IconContainerComponent={IconContainer}
                            onChange={(event, newValue) => {

                                if (!newValue) {
                                    setRatingCalification(-1);
                                    setRatingCalificationError('Debe asignar una calificación');

                                } else {
                                    setRatingCalification(newValue);
                                    setRatingCalificationError('');
                                }

                            }}
                            onChangeActive={(event, newHover) => {
                                setHover(newHover);
                            }}
                        />
                        {ratingCalification !== null && <Box ml={2}>{labels[hover !== -1 ? hover : ratingCalification]}</Box>}
                    </div>
                    <div className={classes.errorColor}>{ratingCalificationError}</div>
                    <br></br>
                    <br></br>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={setCalificationHandler}
                        color="primary"
                        autoFocus
                        variant="contained"
                        style={{
                            borderRadius: 0,
                        }}
                    >
                        Enviar
                    </Button>
                </DialogActions>

            </Dialog>



            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{productModal.title}</DialogTitle>

                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item sm={12}>
                            <br></br>
                            <Grid item xs={12} >
                                <h4 className={classes.maginZero}>Historial de cambios</h4>
                            </Grid>

                            <Timeline lineColor={'#9fc33b '}>

                                {productModal.shippingHistorical && productModal.shippingHistorical.map((value, index) => {

                                    let mainTitle = obtenerFechaConvertida(value.dateShippingUpdate._seconds);

                                    switch (value.shippingStatus) {
                                        case '10':
                                            return <>
                                                <TimelineItem
                                                    key={value.consec}
                                                    dateText={value.consec + ". " + "Por entregar"}
                                                    dateInnerStyle={{ background: '#61b8ff', color: '#000' }}
                                                    bodyContainerStyle={{
                                                        background: '#ddd',
                                                        padding: '20px',
                                                        borderRadius: '8px',
                                                        boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                                    }}
                                                >
                                                    <h3 style={{ color: '#61b8ff' }}>{mainTitle}</h3>
                                                    {/* <h4 style={{ color: '#61b8ff' }}>Actualizó: {value.userStatusUpdateName}</h4> */}
                                                    <p>
                                                        <b>Comentarios: </b>{value.commentsDelivery}
                                                    </p>

                                                </TimelineItem>
                                            </>

                                        case '20':
                                            return <>
                                                <TimelineItem
                                                    key={value.consec}
                                                    dateText={value.consec + ". " + "Entregado"}
                                                    dateInnerStyle={{ background: '#76bb7f' }}
                                                >
                                                    <h3>{mainTitle}</h3>
                                                    {/* <h4>Actualizó: {value.userStatusUpdateName}</h4> */}
                                                    <p>
                                                        <b>Recibió producto: </b>{value.deliveryPerson}
                                                    </p>
                                                    <p>
                                                        <b>Comentarios: </b>{value.commentsDelivery}
                                                    </p>

                                                </TimelineItem>

                                            </>

                                        case '30':
                                            return <>
                                                <TimelineItem
                                                    key={value.consec}
                                                    dateText={value.consec + ". " + "Rechazado"}
                                                    style={{ color: '#e86971' }}
                                                >
                                                    <h3>{mainTitle}</h3>
                                                    {/* <h4>Actualizó: {value.userStatusUpdateName}</h4> */}
                                                    <p>
                                                        <b>Comentarios: </b>{value.commentsDelivery}
                                                    </p>

                                                </TimelineItem>

                                            </>
                                    }

                                })}

                            </Timeline>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
}

export default OrdersScreen;