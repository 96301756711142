import {
  GROUP_LIST_REQUEST,
  GROUP_LIST_SUCCESS,
  GROUP_LIST_FAIL,
  GROUP_DETAILS_REQUEST,
  GROUP_DETAILS_SUCCESS,
  GROUP_DETAILS_FAIL,
  GROUP_SAVE_REQUEST,
  GROUP_SAVE_SUCCESS,
  GROUP_SAVE_FAIL,
  GROUP_DELETE_REQUEST,
  GROUP_DELETE_SUCCESS,
  GROUP_DELETE_FAIL,
  GROUP_STUDENT_UPDATE_REQUEST,
  GROUP_STUDENT_UPDATE_SUCCESS,
  GROUP_STUDENT_UPDATE_FAIL,
  GROUP_STUDENT_SAVE_REQUEST,
  GROUP_STUDENT_SAVE_SUCCESS,
  GROUP_STUDENT_SAVE_FAIL,
  GROUP_STUDENT_UPDATE_RESET,

  SAVE_GROUP_RESET,
  SAVE_GROUP_REQUEST,
  SAVE_GROUP_SUCCESS,
  SAVE_GROUP_FAIL,
} from '../constants/groupConstants';

function groupStudentUpdateListReducer(state = { groups: { checked: [], checkedGroup: [] } }, action) {
  switch (action.type) {
    case GROUP_STUDENT_UPDATE_RESET:
      return { loading: false, completed: false };
    case GROUP_STUDENT_UPDATE_REQUEST:
      return { loading: true, groups: [], completed: false };
    case GROUP_STUDENT_UPDATE_SUCCESS:
      return { loading: false, groups: action.payload, completed: true };
    case GROUP_STUDENT_UPDATE_FAIL:
      return { loading: false, error: action.payload, completed: false };
    default:
      return state;
  }
}

function groupListReducer(state = { groups: { groups: [], students: [] } }, action) {
  switch (action.type) {
    case GROUP_LIST_REQUEST:
      return { loading: true, groups: [] };
    case GROUP_LIST_SUCCESS:
      return { loading: false, groups: action.payload };
    case GROUP_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function groupDetailsReducer(state = { group: { reviews: [] } }, action) {
  switch (action.type) {
    case GROUP_DETAILS_REQUEST:
      return { loading: true };
    case GROUP_DETAILS_SUCCESS:
      return { loading: false, group: action.payload };
    case GROUP_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function groupDeleteReducer(state = { group: {} }, action) {
  switch (action.type) {
    case GROUP_DELETE_REQUEST:
      return { loading: true };
    case GROUP_DELETE_SUCCESS:
      return { loading: false, group: action.payload, success: true };
    case GROUP_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}


function groupStudentSaveReducer(state = { student: {} }, action) {
  switch (action.type) {
    case GROUP_STUDENT_SAVE_REQUEST:
      return { loading: true };
    case GROUP_STUDENT_SAVE_SUCCESS:
      return { loading: false, success: true, student: action.payload };
    case GROUP_STUDENT_SAVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

function groupSaveReducer(state = { group: {} }, action) {
  switch (action.type) {
    case SAVE_GROUP_RESET:
      return { loading: false, success: false };
    case SAVE_GROUP_REQUEST:
      return { loading: true };
    case SAVE_GROUP_SUCCESS:
      return { loading: false, success: true, group: action.payload };
    case SAVE_GROUP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}


export {
  groupStudentUpdateListReducer,
  groupListReducer,
  groupDetailsReducer,
  groupSaveReducer,
  groupDeleteReducer,
  groupStudentSaveReducer
};
