import {
    GET_BALANCE_HISTORY_RESET,
    GET_BALANCE_HISTORY_REQUEST,
    GET_BALANCE_HISTORY_SUCCESS,
    GET_BALANCE_HISTORY_FAIL,
} from '../constants/balanceHistoryConstants';

function getBalanceHistoryReducer(state = { student: {} }, action) {
    switch (action.type) {
        case GET_BALANCE_HISTORY_RESET:
            return { loading: false, success: false, student: {} };
        case GET_BALANCE_HISTORY_REQUEST:
            return { loading: true };
        case GET_BALANCE_HISTORY_SUCCESS:
            return { loading: false, success: true, student: action.payload };
        case GET_BALANCE_HISTORY_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export {
    getBalanceHistoryReducer
};
