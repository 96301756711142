import {
    GET_BALANCE_HISTORY_RESET,
    GET_BALANCE_HISTORY_REQUEST,
    GET_BALANCE_HISTORY_SUCCESS,
    GET_BALANCE_HISTORY_FAIL,
} from '../constants/balanceHistoryConstants';

import {
    GROUP_ACTIONS_URL
} from '../constants/urlConstants';

const urlBase = GROUP_ACTIONS_URL;

const resetBalanceHistoryApi = () => (dispatch) => {
    dispatch({ type: GET_BALANCE_HISTORY_RESET });
}

const getBalanceHistoryApi = (product) => async (dispatch, getState) => {

    dispatch({ type: GET_BALANCE_HISTORY_REQUEST });
    const { userSignin: { userInfo } } = getState();

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    await fetch(
        urlBase +
        'user/balancehistory'
        , {
            method: 'POST',
            body: JSON.stringify({ id: userInfo._id }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(function (response) {
            if (response.ok) {
                ok = true;
            } else if (response.status === 400) {
                err400 = true;
            } else {
                // unknown = true;
            }
            return response.json();
        })
        .then(function (data) {
            // console.log('data = ', data);
            msg = data;
        })
        .catch(function (err) {
            console.error(err);
            // unknown = true;
        });

    if (ok) {
        dispatch({ type: GET_BALANCE_HISTORY_SUCCESS, payload: msg });
    } else if (err400) {
        dispatch({ type: GET_BALANCE_HISTORY_FAIL, payload: msg.errors });
    } else {
        alert("Ocurrió un error, intenta más tarde");
        dispatch({ type: GET_BALANCE_HISTORY_FAIL, payload: "Ocurrió un error, intenta más tarde" });
    }

}


export {
    getBalanceHistoryApi,
    resetBalanceHistoryApi
};
