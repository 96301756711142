import {
  PRODUCTS_LIST_RESET,
  PRODUCTS_LIST_REQUEST,
  PRODUCTS_LIST_SUCCESS,
  PRODUCTS_LIST_FAIL,

  PRODUCT_MANAGEMENT_RESET,
  PRODUCT_MANAGEMENT_SUCCESS,

  SAVE_PRODUCT_MANAGEMENT_RESET,
  SAVE_PRODUCT_MANAGEMENT_REQUEST,
  SAVE_PRODUCT_MANAGEMENT_SUCCESS,
  SAVE_PRODUCT_MANAGEMENT_FAIL,

  DELETE_PRODUCT_MANAGEMENT_RESET,
  DELETE_PRODUCT_MANAGEMENT_REQUEST,
  DELETE_PRODUCT_MANAGEMENT_SUCCESS,
  DELETE_PRODUCT_MANAGEMENT_FAIL,

  GET_CATEGORIES_RESET,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,

  /**RANDOM */
  GET_RANDOM_CONFIGURATION_RESET,
  GET_RANDOM_CONFIGURATION_REQUEST,
  GET_RANDOM_CONFIGURATION_SUCCESS,
  GET_RANDOM_CONFIGURATION_FAIL,

} from '../constants/productManagementConstants';

import {
  GROUP_ACTIONS_URL
} from '../constants/urlConstants';

import unregister from '../config/config';

const urlBase = GROUP_ACTIONS_URL;

const resetlistProducsApi = () => (dispatch) => {
  dispatch({ type: PRODUCTS_LIST_RESET });
}

const listProducsApi = (
  category = 'all',
  termn = '',
  page = 0,
  rowsPerPage = 10,
  column = "title",
  asc = true,
  iems = [{ total: 0, items: [] }]
) => async (dispatch, getState) => {


  // Valida la expiración del token



  dispatch({ type: PRODUCTS_LIST_REQUEST, payload: iems });
  const { userSignin: { userInfo } } = getState();


  let ok = false;
  let msg = null;
  let err400 = false;
  let unknown = false;

  await fetch(
    urlBase +
    'user/product/all?' +
    'page=' + page +
    '&rowsPerPage=' + rowsPerPage +
    '&column=' + column +
    '&order=' + (asc ? 'asc' : 'desc') +
    '&termn=' + termn +
    '&category=' + category +
    (userInfo.roles.student && userInfo.plans === 2 ? '&id=' + userInfo._id : '')
    , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {


      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        unknown = true;
      }


      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      unknown = true;
    });

  if (ok) {

    if (msg.items.length > 0) {

      // Calcular la enumaracion
      let initialCount = ((msg.page - 1) * msg.per_page);

      msg.items.map(function (item, idx) {
        initialCount++;
        let obj = msg.categories.find(o => o.id === msg.items[idx].category);
        msg.items[idx].consec = initialCount;
        msg.items[idx].categoryTitle = obj ? obj.name : 'No definido';
      })

    }

    dispatch({ type: PRODUCTS_LIST_SUCCESS, payload: msg });

  } else if (err400) {
    dispatch({ type: PRODUCTS_LIST_FAIL, payload: msg.errors });
  } else {
    dispatch({ type: PRODUCTS_LIST_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

};

const resetroductManagement = () => (dispatch) => {
  dispatch({ type: PRODUCT_MANAGEMENT_RESET });
}

const setProductManagement = (data) => (dispatch) => {
  dispatch({ type: PRODUCT_MANAGEMENT_SUCCESS, payload: data });
}

const reseSaveProductManagement = (data) => (dispatch) => {
  dispatch({ type: SAVE_PRODUCT_MANAGEMENT_RESET, payload: data });
}


const saveProductManagementApi = (product) => async (dispatch, getState) => {

  dispatch({ type: SAVE_PRODUCT_MANAGEMENT_REQUEST });
  const { userSignin: { userInfo } } = getState();

  let ok = false;
  let msg = null;
  let err400 = false;
  // let unknown = false;

  if (userInfo.roles.student && userInfo.plans === 2) {
    product.uid = userInfo._id;
  }

  if (userInfo && userInfo._id) {
    product.resp = userInfo._id;
  }

  await fetch(
    urlBase +
    'user/product'
    , {
      method: 'POST',
      body: JSON.stringify(product),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {
      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        // unknown = true;
      }
      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

  if (ok) {
    dispatch({ type: SAVE_PRODUCT_MANAGEMENT_SUCCESS, payload: msg });
  } else if (err400) {
    dispatch({ type: SAVE_PRODUCT_MANAGEMENT_FAIL, payload: msg.errors });
  } else {
    alert("Ocurrió un error, intenta más tarde");
    dispatch({ type: SAVE_PRODUCT_MANAGEMENT_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

}


const updateProductManagementApi = (product) => async (dispatch, getState) => {

  dispatch({ type: SAVE_PRODUCT_MANAGEMENT_REQUEST });
  const { userSignin: { userInfo } } = getState();

  let ok = false;
  let msg = null;
  let err400 = false;
  // let unknown = false;

  await fetch(
    urlBase +
    'user/product'
    , {
      method: 'PUT',
      body: JSON.stringify(product),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {
      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        // unknown = true;
      }
      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

  if (ok) {
    dispatch({ type: SAVE_PRODUCT_MANAGEMENT_SUCCESS, payload: msg });
  } else if (err400) {
    dispatch({ type: SAVE_PRODUCT_MANAGEMENT_FAIL, payload: msg.errors });
  } else {
    alert("Ocurrió un error, intenta más tarde");
    dispatch({ type: SAVE_PRODUCT_MANAGEMENT_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

}


const resetDeleteProductApi = () => (dispatch) => {
  dispatch({ type: DELETE_PRODUCT_MANAGEMENT_RESET });
}


const deleteProductApi = (product) => async (dispatch, getState) => {

  dispatch({ type: DELETE_PRODUCT_MANAGEMENT_REQUEST });
  const { userSignin: { userInfo } } = getState();

  let ok = false;
  let msg = null;
  let err400 = false;
  // let unknown = false;

  await fetch(
    urlBase +
    'user/product'
    , {
      method: 'DELETE',
      body: JSON.stringify(product),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {
      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        // unknown = true;
      }
      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

  if (ok) {
    dispatch({ type: DELETE_PRODUCT_MANAGEMENT_SUCCESS, payload: msg });
  } else if (err400) {
    dispatch({ type: DELETE_PRODUCT_MANAGEMENT_FAIL, payload: msg.errors });
  } else {
    alert("Ocurrió un error, intenta más tarde");
    dispatch({ type: DELETE_PRODUCT_MANAGEMENT_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

}



const deleteAllProductApi = (product) => async (dispatch, getState) => {

  dispatch({ type: DELETE_PRODUCT_MANAGEMENT_REQUEST });
  const { userSignin: { userInfo } } = getState();

  let ok = false;
  let msg = null;
  let err400 = false;
  // let unknown = false;

  await fetch(
    urlBase +
    'user/products'
    , {
      method: 'DELETE',
      body: JSON.stringify(product),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {
      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        // unknown = true;
      }
      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

  if (ok) {
    dispatch({ type: DELETE_PRODUCT_MANAGEMENT_SUCCESS, payload: msg });
  } else if (err400) {
    dispatch({ type: DELETE_PRODUCT_MANAGEMENT_FAIL, payload: msg.errors });
  } else {
    alert("Ocurrió un error, intenta más tarde");
    dispatch({ type: DELETE_PRODUCT_MANAGEMENT_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

}

const getCategoriesApi = (
  category = '',
  searchKeyword = '',
  sortOrder = ''
) => async (dispatch, getState) => {

  // ::::::::::::::: BLOCK FINAL ::::::::::::::

  dispatch({ type: GET_CATEGORIES_REQUEST });
  const { userSignin: { userInfo } } = getState();

  let ok = false;
  let msg = null;
  let err400 = false;
  // let unknown = false;

  await fetch(
    urlBase +
    'user/categories?category=' +
    category +
    '&searchKeyword=' +
    searchKeyword +
    '&sortOrder=' +
    sortOrder +
    (userInfo.roles.student && userInfo.plans === 2 ? '&id=' + userInfo._id : '')
    , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {
      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        // unknown = true;
      }
      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      // unknown = true;
    });

  if (ok) {
    dispatch({ type: GET_CATEGORIES_SUCCESS, payload: msg });
  } else if (err400) {
    dispatch({ type: GET_CATEGORIES_FAIL, payload: msg.errors });
  } else {
    dispatch({ type: GET_CATEGORIES_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

};

const resetCategoriesApi = () => (dispatch) => {
  dispatch({ type: GET_CATEGORIES_RESET });
}


// Tienda
const listStoreProducsApi = (
  category = 'all',
  termn = '',
  page = 0,
  rowsPerPage = 10,
  column = "title",
  asc = true,
  iems = [{ total: 0, items: [] }]
) => async (dispatch, getState) => {


  // Valida la expiración del token



  dispatch({ type: PRODUCTS_LIST_REQUEST, payload: iems });
  const { userSignin: { userInfo } } = getState();


  let ok = false;
  let msg = null;
  let err400 = false;
  let unknown = false;

  await fetch(
    urlBase +
    'user/product/all?' +
    'page=' + page +
    '&rowsPerPage=' + rowsPerPage +
    '&column=' + column +
    '&order=' + (asc ? 'asc' : 'desc') +
    '&termn=' + termn +
    '&category=' + category
    , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {


      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        unknown = true;
      }


      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      unknown = true;
    });

  if (ok) {

    if (msg.items.length > 0) {

      // Calcular la enumaracion
      let initialCount = ((msg.page - 1) * msg.per_page);

      msg.items.map(function (item, idx) {
        initialCount++;
        let obj = msg.categories.find(o => o.id === msg.items[idx].category);
        msg.items[idx].consec = initialCount;
        msg.items[idx].categoryTitle = obj ? obj.name : 'No definido';
      })

    }

    dispatch({ type: PRODUCTS_LIST_SUCCESS, payload: msg });

  } else if (err400) {
    dispatch({ type: PRODUCTS_LIST_FAIL, payload: msg.errors });
  } else {
    dispatch({ type: PRODUCTS_LIST_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

};


// Random Configuration
const getRandomConfiguration = (

) => async (dispatch, getState) => {

  // Valida la expiración del token

  dispatch({ type: GET_RANDOM_CONFIGURATION_REQUEST, payload: [] });
  const { userSignin: { userInfo } } = getState();

  let ok = false;
  let msg = null;
  let err400 = false;
  let unknown = false;

  await fetch(
    urlBase +
    'user/product/getrandomconfiguration'
    , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {

      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        unknown = true;
      }

      return response.json();

    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      unknown = true;
    });

  if (ok) {

    // if (msg.items.length > 0) {

    //   // Calcular la enumaracion
    //   let initialCount = ((msg.page - 1) * msg.per_page);

    //   msg.items.map(function (item, idx) {
    //     initialCount++;
    //     let obj = msg.categories.find(o => o.id === msg.items[idx].category);
    //     msg.items[idx].consec = initialCount;
    //     msg.items[idx].categoryTitle = obj ? obj.name : 'No definido';
    //   })

    // }

    dispatch({ type: GET_RANDOM_CONFIGURATION_SUCCESS, payload: msg });

  } else if (err400) {
    dispatch({ type: GET_RANDOM_CONFIGURATION_FAIL, payload: msg.errors });
  } else {
    dispatch({ type: GET_RANDOM_CONFIGURATION_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

};


// Tienda RANDOM
const listStoreProducsRandomApi = (
  category = 'all',
  termn = '',
  items = []
) => async (dispatch, getState) => {

  // Valida la expiración del token

  dispatch({ type: PRODUCTS_LIST_REQUEST, payload: [] });
  const { userSignin: { userInfo } } = getState();

  let ok = false;
  let msg = null;
  let err400 = false;
  let unknown = false;

  await fetch(
    urlBase +
    'user/product/random?' +
    '&termn=' + termn +
    '&category=' + category
    , {
      method: 'POST',
      body: JSON.stringify({ items: items }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {


      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        unknown = true;
      }


      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      unknown = true;
    });

  if (ok) {

    if (msg.items.length > 0) {

      // Calcular la enumaracion
      let initialCount = ((msg.page - 1) * msg.per_page);

      msg.items.map(function (item, idx) {
        initialCount++;
        let obj = msg.categories.find(o => o.id === msg.items[idx].category);
        msg.items[idx].consec = initialCount;
        msg.items[idx].categoryTitle = obj ? obj.name : 'No definido';
      })

    }

    dispatch({ type: PRODUCTS_LIST_SUCCESS, payload: msg });

  } else if (err400) {
    dispatch({ type: PRODUCTS_LIST_FAIL, payload: msg.errors });
  } else {
    dispatch({ type: PRODUCTS_LIST_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

};


// Listado de productos de alumnos premium 

const listPremiumProducsApi = (
  // estado = 'all',
  category = 'all',
  termn = '',
  page = 0,
  rowsPerPage = 10,
  column = "title",
  asc = true,
  iems = [{ total: 0, items: [] }],
  estado = 'no'
) => async (dispatch, getState) => {


  // Valida la expiración del token

  dispatch({ type: PRODUCTS_LIST_REQUEST, payload: iems });
  const { userSignin: { userInfo } } = getState();


  let ok = false;
  let msg = null;
  let err400 = false;
  let unknown = false;

  await fetch(
    urlBase +
    'user/all/premium?' +
    'page=' + page +
    '&rowsPerPage=' + rowsPerPage +
    '&column=' + column +
    '&order=' + (asc ? 'asc' : 'desc') +
    '&termn=' + termn +
    // '&estado=' + estado +
    '&category=' + category +
    (userInfo.roles.student && userInfo.plans === 2 ? '&id=' + userInfo._id : '') +
    '&estado=' + estado  
    , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userInfo.token
      }
    })
    .then(function (response) {


      if (response.ok) {
        ok = true;
      } else if (response.status === 400) {
        err400 = true;
      } else {
        unknown = true;
      }


      return response.json();
    })
    .then(function (data) {
      // console.log('data = ', data);
      msg = data;
    })
    .catch(function (err) {
      console.error(err);
      unknown = true;
    });

  if (ok) {

    if (msg.items.length > 0) {

      // Calcular la enumaracion
      let initialCount = ((msg.page - 1) * msg.per_page);

      msg.items.map(function (item, idx) {
        initialCount++;
        let obj = msg.categories.find(o => o.id === msg.items[idx].category);
        msg.items[idx].consec = initialCount;
        msg.items[idx].categoryTitle = obj ? obj.name : 'No definido';
      })

    }

    dispatch({ type: PRODUCTS_LIST_SUCCESS, payload: msg });

  } else if (err400) {
    dispatch({ type: PRODUCTS_LIST_FAIL, payload: msg.errors });
  } else {
    dispatch({ type: PRODUCTS_LIST_FAIL, payload: "Ocurrió un error, intenta más tarde" });
  }

};


const resetlistPremiumProducsApi = () => (dispatch) => {
  dispatch({ type: PRODUCTS_LIST_RESET });
}

export {
  resetlistProducsApi,
  listProducsApi,
  resetroductManagement,
  setProductManagement,
  reseSaveProductManagement,
  saveProductManagementApi,
  updateProductManagementApi,
  resetDeleteProductApi,
  deleteProductApi,
  getCategoriesApi,
  resetCategoriesApi,
  deleteAllProductApi,
  listStoreProducsApi,
  listStoreProducsRandomApi,
  getRandomConfiguration,
  listPremiumProducsApi,
  resetlistPremiumProducsApi
};
