import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import {
    getJustificationsApi,
    saveJustification,
    deleteJustification
} from '../actions/buyCreditActions';


// MATERIAL UI
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiPhoneNumber from 'material-ui-phone-number';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


function JustificationManagerScreen(props) {

    const [state] = useState({
        vertical: 'bottom',
        horizontal: 'center',
    });

    const { vertical, horizontal } = state;

    const justificationsList = useSelector(state => state.justificationsList);
    const { loading: loadingJustifications, justifications, error: errorJustifications, success: successJustifications } = justificationsList;

    const justificationSave = useSelector(state => state.justificationSave);
    const { loading: loadingSave, justification, error: errorSave, success: successSave } = justificationSave;


    const [showBackdrop, setShowBackdrop] = useState(false);
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState('');


    const [description, setDescription] = useState('');
    const [id, setId] = useState('');

    const dispatch = useDispatch();

    const [type, setType] = useState(0);
    const [openFormModal, setOpenFormModal] = useState(false);

    const [updateMode, setUpdateMode] = useState(false);
    const [typeGroupOperation, setTypeGroupOperation] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    // Configuración de estilos
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            marginLeft: theme.spacing(2)
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'right',
            // color: theme.palette.text.secondary,
        },
        list: {
            width: '100%',
            // maxWidth: 360,
            // backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 400,
        },
        itemList: {
            padding: theme.spacing(2),
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        noSelected: {
            color: 'red',
        },
        selected: {
            color: 'green'
        },
        margin: {
            margin: theme.spacing(2),
        },
        cancel: {
            margin: theme.spacing(2),
            textAlign: 'left',
        },

    }));

    const classes = useStyles();

    useEffect(() => {
        dispatch(getJustificationsApi());

        return () => {

        };

    }, []);


    useEffect(() => {
        if (successSave) {
            dispatch(getJustificationsApi());
        }
        return () => {
        };
    }, [justificationSave]);


    const handleClickOpenModal = () => {
        setType(0);
        passUserDataToProps();
        setUpdateMode(false);
        setOpenFormModal(true);
    };

    const passUserDataToProps = (data) => {
        setTitleError('');

        if (data) {
            setId(data.id);
            setTitle(data.title);
        } else {
            setId('');
            setTitle('');
        }
    };

    // E S T U D I A N T E S
    // EDITAR
    const handleEditStudent = (justificationId, type) => () => {
        setType(type);
        setId(justificationId);
        const nuevaEdicion = justifications.find(o => o.id === justificationId);
        passUserDataToProps(nuevaEdicion);
        setTitle(nuevaEdicion.title);
        setDescription(nuevaEdicion.description);
        setUpdateMode(true);
        setOpenFormModal(true);
    };

    const handleCloseModal = () => {
        setOpenFormModal(false);
    };


    // Cerrar el snackbar
    const CloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    // VALIDACIONES
    const checTitle = (v) => {
        if (!v || v.trim().length === 0) {
            setTitleError('Este campo no debe estar vacío');
        } else {
            setTitleError("");
        }
        setTitle(v)
    }

    // OPERACIONES CON ESTUDIANTES
    const submitStudentHandler = (e) => {
        setOpenFormModal(false);
        e.preventDefault();

        if (type === 1) {

            if (id && id.trim().length > 0) {
                dispatch(
                    deleteJustification({
                        id
                    })
                );
            }

        } else {
            if (title && title.trim().length > 0) {
                dispatch(
                    saveJustification({
                        id, title
                    })
                );
            }
        }

    };



    return (<>
        <div className={classes.root} >
            <h1> Administración de justificaciones </h1>


            <Grid container spacing={2} >
                <Grid item sm={12}
                    md={8} >

                    <Paper className={classes.paper} >

                        {
                            loadingJustifications ? (<div > Obteniendo listado... </div>
                            ) : errorJustifications ? (<div > Ocurrió un error </div>
                            ) : (<>
                                <Button variant="outlined"
                                    size="small"
                                    onClick={handleClickOpenModal}
                                    startIcon={< AddIcon />}
                                    className={classes.margin} >
                                    Agregar justificación </Button> <Divider />
                                <List className={classes.list} > {
                                    justifications.map((value, index) => {
                                        const labelId = `checkbox-list-label-${value.id}`;

                                        return (

                                            <ListItem key={value.id}
                                                role={undefined}
                                                dense button className={classes.itemList} >

                                                <ListItemText>{value.count}.- {value.title} </ListItemText>

                                                <ListItemSecondaryAction >
                                                    <IconButton edge="end"
                                                        onClick={handleEditStudent(value.id, 0)}
                                                        aria-label="comments" >
                                                        <CreateIcon />
                                                    </IconButton> <IconButton edge="end"
                                                        onClick={handleEditStudent(value.id, 1)}
                                                        aria-label="comments" >
                                                        <DeleteIcon />
                                                    </IconButton> </ListItemSecondaryAction> </ListItem>
                                        );
                                    })
                                } </List> </>
                            )
                        } </Paper> </Grid> </Grid>



            <Snackbar anchorOrigin={
                { vertical, horizontal }}
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={CloseSnackbar}
                // message="Se han realizado los cambios solicitados"
                key={vertical + horizontal} >

                <Alert onClose={CloseSnackbar}
                    severity="success" >
                    Se han realizado los cambios solicitados </Alert> </Snackbar>

            <Backdrop className={classes.backdrop}
                open={loadingJustifications || showBackdrop || loadingSave} >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog disableBackdropClick={true}
                open={openFormModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title" > {type === 1 ? 'Borrar ' + title : updateMode ? 'Actualizar ' + title : 'Crear'} </DialogTitle> <DialogContent >
                    <form className={classes.form}
                        onSubmit={submitStudentHandler}
                        noValidate >

                        {
                            type != 1 ? (<
                                TextField autoFocus={type != 1}
                                // inputRef={textInput}
                                value={title}
                                // error={nameError.length > 0}
                                variant="outlined"
                                margin="normal"
                                required fullWidth id="title"
                                label="Título"
                                name="title"
                                autoComplete="title"
                                readonly={type === 1}
                                onChange={
                                    (e) => checTitle(e.target.value)}
                                helperText={titleError && titleError}
                                size="small" /
                            >
                            ) : (<> </>)}

                        <div className={classes.wrapper} >
                            <Button onClick={handleCloseModal}
                                variant="outlined"
                                color="primary"
                                className={classes.cancel} >
                                Cancelar </Button> <Button type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit} > {type === 1 ? 'Confirmar' : updateMode ? 'Actualizar' : 'Guardar'} </Button> </div>
                    </form> </DialogContent> </Dialog>

        </div>

    </>
    );
}



function Alert(props) {
    return <MuiAlert elevation={6}
        variant="filled" {...props}
    />;
}


export default JustificationManagerScreen;