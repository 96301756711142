import React, { useEffect, useState } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BannerSchools from '../components/BannerSchools';
import BannerPartners from '../components/BannerPartners';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import MuiPhoneNumber from 'material-ui-phone-number';

import { formatPhoneNumber } from 'react-phone-number-input'


import {
    getDataContactApi,
    resetPostCommentsApi,
    postCommentsApi
} from '../actions/dataContactActions';
import { Description } from '@material-ui/icons';

function ContactUsScreen(props) {

    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;

    const getDataContact = useSelector(state => state.getDataContact);
    const { loading: loadingGetData, contact, error } = getDataContact;


    const postComments = useSelector(state => state.postComments);
    const { loading: loadingPost, success: successPost, error: errorPost } = postComments;

    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const [comments, setComments] = useState('');
    const [commentsError, setCommentsError] = useState('');
    const [userPlan, setUserPlan] = useState("1");
    const [sendButtonDisabled, setSendButtonDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);


    const [showBackdrop, setShowBackdrop] = useState(false);


    useEffect(() => {
        dispatch(getDataContactApi());
        return () => {
            //
            dispatch(resetPostCommentsApi());
        };
    }, []);


    useEffect(() => {
        if (loadingGetData) {
            setShowBackdrop(true);
        } else {
            setShowBackdrop(false);
        }
        return () => {
            //
        };
    }, [getDataContact]);

    useEffect(() => {
        if (loadingPost) {
            setShowBackdrop(true);
        } else {
            setShowBackdrop(false);
        }

        if (successPost) {
            setShowModal(true);
            setUserPlan("1");
            setComments("");
            setEmail("");
            setPhone("");
        }

        if (errorPost) {
            errorPost.forEach(
                function myFunction(item, index) {
                    switch (item.param) {
                        case 'comments':
                            setCommentsError(item.msg);
                            break;
                        case 'email':
                            setEmailError(item.msg);
                            break;
                        default:
                        // code block
                    }
                });
        }

        return () => {
            //
        };
    }, [postComments]);


    const useStyles = makeStyles((theme) => ({
        paper: {
            marginTop: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(0),
        },
        submit: {
            // margin: theme.spacing(3, 0, 2),
            marginBottom: theme.spacing(2)
        },
        wrapper: {
            margin: theme.spacing(0),
            // padding: theme.spacing(0),
            position: 'relative',
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        }
    }));

    const classes = useStyles();

    const checkComments = (v) => {
        if (!v || v.trim().length == 0) {
            setCommentsError('El nombre no debe estar vacío');
        } else {
            setCommentsError("");
        }
        setComments(v)
    }


    const checkEmail = (v) => {
        if (!v || v.trim().length == 0) {
            setEmailError('El nombre no debe estar vacío');
        } else {
            setEmailError("");
        }
        setEmail(v)
    }

    const checkPhone = (v) => {
        // if (!v || v.trim().length == 0) {
        //     setEmailError('El nombre no debe estar vacío');
        // } else {
        //     setEmailError("");
        // }
        setPhone(v)
    }


    const handleChange = (event) => {
        setUserPlan(event.target.value);
    };


    const submitHandler = (e) => {
        e.preventDefault();

        if (phone.length === 0 || phone.length === 13) {
            dispatch(postCommentsApi({ type: userPlan, comments: comments, email: email, phone: phone, contact: contact.email }));
        } else {
            setPhoneError("Error");
        }
    }


    const handleCloseModal = () => {
        setShowModal(false);
    };


    const handleOnChange = (e) => {
        setPhoneError('');
        setPhone(e);
    }


    return (
        <>

            {!userInfo && (
                // <BannerSchools />
                <div id="cft"  >
                    <img style={{ width: 'calc(100%)' }} className="bottom" src="/images/Banner2.jpg" />
                    <img style={{ width: 'calc(100%)' }} className="top" src="/images/Banner1.jpg" />
                </div>
            )}


            <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                    <Typography component="h1" variant="h6">
                        {contact.name}
                    </Typography>
                    <Typography component="h1" variant="h6">
                        {contact.email}
                    </Typography>
                    <Typography component="h1" variant="h6">
                        {formatPhoneNumber(contact.phone)}
                    </Typography>
                    <br></br>
                    <form className={classes.form} noValidate onSubmit={submitHandler}>

                        <FormControl component="fieldset">
                            <RadioGroup aria-label="gender" name="gender1" value={userPlan} onChange={handleChange} >
                                <FormControlLabel size="small" value="1" control={<Radio size="small" />} label="Solicitud de información del simulador" />
                                <FormControlLabel value="2" control={<Radio size="small" />} label="Queja" />
                                <FormControlLabel value="3" control={<Radio size="small"/>} label="Sugerencia" />
                                <FormControlLabel value="4" control={<Radio size="small"/>} label="Reportar algún error" />
                                <FormControlLabel value="5" control={<Radio size="small"/>} label="Consulta sobre el uso del Simulador" />
                            </RadioGroup>
                        </FormControl>


                        <TextField
                            size="small"
                            value={email}
                            error={emailError.length > 0}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="email"
                            label="Correo electrónico"
                            type="email"
                            id="email"
                            autoComplete="email"
                            onChange={(e) => checkEmail(e.target.value)}
                            helperText={emailError && emailError}
                        />

                        {/* <TextField
                            value={phone}
                            error={phoneError.length > 0}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="phone"
                            label="Teléfono"
                            type="text"
                            id="phone"
                            autoComplete="phone"
                            onChange={(e) => checkPhone(e.target.value)}
                            helperText={phoneError && phoneError}
                        /> */}

                        <MuiPhoneNumber
                            size="small"
                            defaultCountry={'mx'}
                            fullWidth
                            variant="outlined"
                            value={phone}
                            regions={['central-america', 'south-america']}
                            helperText={phoneError && "Número inválido"}
                            onChange={handleOnChange} />

                        <TextField
                            size="small"
                            value={comments}
                            error={commentsError.length > 0}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="comments"
                            label="Comentarios"
                            type="text"
                            id="comments"
                            autoComplete="comments"
                            onChange={(e) => checkComments(e.target.value)}
                            helperText={commentsError && commentsError}
                            multiline
                            rows={3}
                        />

                        <div className={classes.wrapper}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={!comments || commentsError.length > 0 || !email || emailError.length > 0}
                            >
                                Enviar
                            </Button>
                        </div>
                        <Grid >
                            {/* <Grid item xs>
                            </Grid> */}
                            <Button
                                component={LinkRouter} to={'/'}
                                fullWidth
                                variant="contained"
                                className={classes.submit}
                            >
                                Regresar
                            </Button>
                            {/* <br></br>
                            <br></br> */}
                        </Grid>
                    </form>
                </div>

            </Container>

            {/* <BannerPartners /> */}
            <div id="cfb"  >
                <img style={{ width: 'calc(100%)' }} className="topb" src="/images/Banner1.jpg" />
                <img style={{ width: 'calc(100%)' }} className="bottomb" src="/images/Banner2.jpg" />

            </div>

            <Backdrop className={classes.backdrop} open={showBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog
                open={showModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Enviado</DialogTitle>
                <DialogContent>
                    <h3>El mensage ha sido enviado exitosamente</h3>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary" autoFocus>
                        Cerrar
                    </Button>
                </DialogActions>

            </Dialog>

        </>
    );
}
export default ContactUsScreen;
