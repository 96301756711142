import {
    SAVE_TEACHER_RESET,
    SAVE_TEACHER_REQUEST,
    SAVE_TEACHER_SUCCESS,
    SAVE_TEACHER_FAIL,
} from '../constants/teacherGroupConstants';

import {
    GROUP_ACTIONS_URL
} from '../constants/urlConstants';

const urlBase = GROUP_ACTIONS_URL;


const saveTeacher = (student) => async (dispatch, getState) => {

    try {
        dispatch({ type: SAVE_TEACHER_REQUEST });
        const {
            userSignin: { userInfo },
        } = getState();

        let ok = false;
        let msg = null;
        let err400 = false;
        // let unknown = false;

        if (!student.id) {

            await fetch(
                urlBase +
                'user/teacher'
                , {
                    method: 'POST',
                    body: JSON.stringify({
                        name: student.name,
                        email: student.email,
                        nickname: student.nickname,
                        grade: student.grade,
                    }),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + userInfo.token
                    }
                })
                .then(function (response) {
                    if (response.ok) {
                        ok = true;
                    } else if (response.status === 400) {
                        err400 = true;
                    } else {
                        // unknown = true;
                    }
                    return response.json();
                })
                .then(function (data) {
                    // console.log('data => ', data);
                    msg = data;
                })
                .catch(function (err) {
                    console.error(err);
                    // unknown = true;
                });

        } else {

            await fetch(
                urlBase +
                'user/teacher'
                , {
                    method: 'PUT',
                    body: JSON.stringify(student),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + userInfo.token
                    }
                })
                .then(function (response) {
                    if (response.ok) {
                        ok = true;
                    } else if (response.status === 400) {
                        err400 = true;
                    } else {
                        // unknown = true;
                    }
                    return response.json();
                })
                .then(function (data) {
                    // console.log('data = ', data);
                    msg = data;
                })
                .catch(function (err) {
                    console.error(err);
                    // unknown = true;
                });

        }

        if (ok) {
            dispatch({ type: SAVE_TEACHER_SUCCESS, payload: msg });

            if (!student.id) {
                // sendEmail(msg);
            }

        } else if (err400) {
            dispatch({ type: SAVE_TEACHER_FAIL, payload: msg.errors });
        } else {
            alert("Error desconocido, intenta más tarde");
            dispatch({ type: SAVE_TEACHER_FAIL, payload: [{ param: 'default', msg: "Ocurrió un error, intenta más tarde" }] });
        }

    } catch (error) {
        dispatch({ type: SAVE_TEACHER_FAIL, payload: [{ msg: error.message }] });
    }

};


const deleteTeacher = (student) => async (dispatch, getState) => {

    try {
        dispatch({ type: SAVE_TEACHER_REQUEST });
        const {
            userSignin: { userInfo },
        } = getState();

        let ok = false;
        let msg = null;
        let err400 = false;
        // let unknown = false;

        await fetch(
            urlBase +
            'user/teacher'
            , {
                method: 'DELETE',
                body: JSON.stringify(student),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userInfo.token
                }
            })
            .then(function (response) {
                if (response.ok) {
                    ok = true;
                } else if (response.status === 400) {
                    err400 = true;
                } else {
                    // unknown = true;
                }
                return response.json();
            })
            .then(function (data) {
                // console.log('data = ', data);
                msg = data;
            })
            .catch(function (err) {
                console.error(err);
                // unknown = true;
            });

        if (ok) {
            dispatch({ type: SAVE_TEACHER_SUCCESS, payload: msg });
        } else if (err400) {
            dispatch({ type: SAVE_TEACHER_FAIL, payload: msg.errors });
        } else {
            alert("Error desconocido, intenta más tarde");
            dispatch({ type: SAVE_TEACHER_FAIL, payload: [{ param: 'default', msg: "Ocurrió un error, intenta más tarde" }] });
        }

    } catch (error) {
        dispatch({ type: SAVE_TEACHER_FAIL, payload: [{ msg: error.message }] });
    }

};

const resetTeacherSave = () => (dispatch) => {
    dispatch({ type: SAVE_TEACHER_RESET });
}

function sendEmail(user) {

    fetch(
        'https://mailingapp.azurewebsites.net/simulador/comments/nuevo'
        , {
            method: 'POST',
            body: JSON.stringify({
                name: user.name,
                email: user.email,
                user: user.nickname,
                password: user.password
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then(function (response) {
            return response.json();
        })
        .then(function (data) {
            // console.log('email = ', data);
        })
        .catch(function (err) {
            console.error(err);
            // unknown = true;
        });

}


export {
    saveTeacher,
    deleteTeacher,
    resetTeacherSave
};
