import {
    GET_GROUP_PURCHASES_RESET,
    GET_GROUP_PURCHASES_REQUEST,
    GET_GROUP_PURCHASES_SUCCESS,
    GET_GROUP_PURCHASES_FAIL,

    GET_STUDENT_GROUP_PURCHASES_RESET,
    GET_STUDENT_GROUP_PURCHASES_REQUEST,
    GET_STUDENT_GROUP_PURCHASES_SUCCESS,
    GET_STUDENT_GROUP_PURCHASES_FAIL,

} from "../constants/groupPurchasesConstants";

import {
    GROUP_ACTIONS_URL
} from '../constants/urlConstants';

const urlBase = GROUP_ACTIONS_URL;

const resetGetShippingApi = () => (dispatch) => {
    dispatch({ type: GET_GROUP_PURCHASES_RESET });
}

const getShippingApi = () => async (dispatch, getState) => {

    dispatch({ type: GET_GROUP_PURCHASES_REQUEST });
    const { userSignin: { userInfo } } = getState();

    let ok = false;
    let msg = null;
    let err400 = false;
    // let unknown = false;

    await fetch(
        urlBase +
        'user/shipping'
        , {
            method: 'POST',
            body: JSON.stringify({
                id: userInfo._id
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(function (response) {
            if (response.ok) {
                ok = true;
            } else if (response.status === 400) {
                err400 = true;
            } else {
                // unknown = true;
            }
            return response.json();
        })
        .then(function (data) {
            // console.log('data = ', data);
            msg = data;
        })
        .catch(function (err) {
            console.error(err);
            // unknown = true;
        });

    if (ok) {
        dispatch({ type: GET_GROUP_PURCHASES_SUCCESS, payload: msg });
    } else if (err400) {
        dispatch({ type: GET_GROUP_PURCHASES_FAIL, payload: msg.errors });
    } else {
        alert("Ocurrió un error, intenta más tarde");
        dispatch({ type: GET_GROUP_PURCHASES_FAIL, payload: "Ocurrió un error, intenta más tarde" });
    }

}

export {
    resetGetShippingApi,
    getShippingApi
}