import {
    GET_GROUP_PURCHASES_RESET,
    GET_GROUP_PURCHASES_REQUEST,
    GET_GROUP_PURCHASES_SUCCESS,
    GET_GROUP_PURCHASES_FAIL,

    GET_STUDENT_GROUP_PURCHASES_RESET,
    GET_STUDENT_GROUP_PURCHASES_REQUEST,
    GET_STUDENT_GROUP_PURCHASES_SUCCESS,
    GET_STUDENT_GROUP_PURCHASES_FAIL,

    GET_STUDENT_WALLET_BALANCE_RESET,
    GET_STUDENT_WALLET_BALANCE_REQUEST,
    GET_STUDENT_WALLET_BALANCE_SUCCESS,
    GET_STUDENT_WALLET_BALANCE_FAIL,

    GET_ASSIGNMENT_BY_AREA_RESET,
    GET_ASSIGNMENT_BY_AREA_REQUEST,
    GET_ASSIGNMENT_BY_AREA_SUCCESS,
    GET_ASSIGNMENT_BY_AREA_FAIL,

} from "../constants/groupPurchasesConstants";

function getGroupPurchasesReducer(state = { groups: [] }, action) {
    switch (action.type) {
        case GET_GROUP_PURCHASES_RESET:
            return { loading: false, success: false };
        case GET_GROUP_PURCHASES_REQUEST:
            return { loading: true, groups: [], success: false };
        case GET_GROUP_PURCHASES_SUCCESS:
            return { loading: false, groups: action.payload, success: true };
        case GET_GROUP_PURCHASES_FAIL:
            return { loading: false, error: action.payload, groups: [], success: false };
        default:
            return state;
    }
}

function getStudentsGroupPurchasesReducer(state = { data: [{ total: 0, items: [] }] }, action) {
    switch (action.type) {
        case GET_STUDENT_GROUP_PURCHASES_RESET:
            return {
                loading: false,
                data: [{ total: 0, items: [] }],
                success: false
            };
        case GET_STUDENT_GROUP_PURCHASES_REQUEST:
            return { loading: true, data: action.payload };
        case GET_STUDENT_GROUP_PURCHASES_SUCCESS:
            return { loading: false, data: action.payload, success: true };
        case GET_STUDENT_GROUP_PURCHASES_FAIL:
            return { loading: false, error: action.payload, data: [{ total: 0, items: [] }] };
        default:
            return state;
    }
}

function getStudentWalletBalanceReducer(state = { data: [{ total: 0, items: [] }] }, action) {
    switch (action.type) {
        case GET_STUDENT_WALLET_BALANCE_RESET:
            return {
                loading: false,
                data: [{ total: 0, items: [] }],
                success: false
            };
        case GET_STUDENT_WALLET_BALANCE_REQUEST:
            return { loading: true, data: action.payload };
        case GET_STUDENT_WALLET_BALANCE_SUCCESS:
            return { loading: false, data: action.payload, success: true };
        case GET_STUDENT_WALLET_BALANCE_FAIL:
            return { loading: false, error: action.payload, data: [{ total: 0, items: [] }] };
        default:
            return state;
    }
}

function getAssignmentsByAreaReducer(state = { data: [{ specific: [], general: [] }] }, action) {
    switch (action.type) {
        case GET_ASSIGNMENT_BY_AREA_RESET:
            return {
                loading: false,
                data: [{ specific: [], general: [] }],
                success: false
            };
        case GET_ASSIGNMENT_BY_AREA_REQUEST:
            return { loading: true, data: action.payload };
        case GET_ASSIGNMENT_BY_AREA_SUCCESS:
            return { loading: false, data: action.payload, success: true };
        case GET_ASSIGNMENT_BY_AREA_FAIL:
            return { loading: false, error: action.payload, data: [{ specific: [], general: [] }] };
        default:
            return state;
    }
}

export {
    getGroupPurchasesReducer,
    getStudentsGroupPurchasesReducer,
    getStudentWalletBalanceReducer,
    getAssignmentsByAreaReducer
};